/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { batteryPercentageFilters, statusFilters } from "../Data";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import Button from "../../../Core/Components/CustomButton";
import FilterItem from "../../../Core/Components/FilterItem";
import { getBatteryV3 } from "../../../Redux/APIs/batteriesAPI";
import MoreBatteryFilters from "./MoreBatteryFilters";
import useDebounce from "../../../Core/hooks/useDebounce";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import { client } from "../../../Utils/axiosClient";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const BatteryListHeader = ({ showKpi, setShowKpi, currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = usePermission([PERMISSIONS.BATTERY_ADD]);

  const [drivers, setDrivers] = useState({
    result: [],
    totalRecords: 0,
  });
  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [filters, setFilters] = useState({
    battery: [],
    status: [],
    driver: [],
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
  });

  const driverFilters = drivers.result.map((item) => {
    return {
      id: item.id,
      value: `${item.firstName} ${item.lastName}`,
    };
  });

  const debounceSearch = useDebounce(search, 500);

  const onSelect = (key, value) => {
    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const applyFilters = useCallback(async () => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.driver.length) filterObj.drivers = filters.driver;
    if (filters.battery.length) filterObj.batteryPercentage = filters.battery;
    if (filters.status.length) filterObj.status = filters.status;
    if (filters.dateRangeStart && filters.dateRangeEnd) {
      filterObj.dateRangeStart = filters.dateRangeStart;
      filterObj.dateRangeEnd = filters.dateRangeEnd;
    }
    if (filters.addedBy.length) {
      filterObj.addedBy = filters.addedBy.map((user) => user.id);
    }

    dispatch(getBatteryV3(filterObj));
  }, [dispatch, debounceSearch, filters, currentPage]);

  const getDrivers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/drivers/get-drivers`,
        {
          params: {
            limit: 100,
          },
        },
      );

      setDrivers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDrivers();
  }, [getDrivers]);

  useEffect(() => {
    applyFilters();
  }, [debounceSearch, filters, applyFilters]);

  return (
    <div className="bg-background-white px-6 py-2.5 border-t-[0.5px] border-light-grey flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search Batteries"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />
        <FilterItem
          label="Battery %"
          identifier="battery"
          contentData={batteryPercentageFilters}
          onSelect={onSelect}
          selectedValues={filters.battery}
        />

        <FilterItem
          label="Driver"
          identifier="driver"
          contentData={driverFilters}
          onSelect={onSelect}
          selectedValues={filters.driver}
        />

        <FilterItem
          label="Status"
          identifier="status"
          customValue
          contentData={statusFilters}
          onSelect={onSelect}
          selectedValues={filters.status}
        />

        <div className="bg-light-grey w-[1px] h-5 mx-1" />

        <MoreBatteryFilters filters={filters} setFilters={setFilters} />
      </div>

      <div className="flex items-center gap-x-3">
        {hasPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/add-battery")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Add Battery
              </p>
            </div>
          </button>
        )}

        <Button
          onClick={() => setShowKpi(!showKpi)}
          padding="arrows"
          size="arrows"
          colors="grey"
        >
          <ChevronIcon
            className={`w-4 h-4 stroke-new-black ${
              showKpi ? "rotate-90" : "-rotate-90"
            }`}
          />
        </Button>
      </div>
    </div>
  );
};

export default BatteryListHeader;
