import React from "react";
import UnauthorizedAccess from "./UnauthorizedAccess";
import usePermission from "../hooks/usePermission";

const ProtectedRoute = ({ component, allowedPermissions = [] }) => {
  const hasPermission = usePermission(allowedPermissions);

  if (!hasPermission) {
    return <UnauthorizedAccess />;
  }

  return component;
};

export default ProtectedRoute;
