import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";

const useTabData = () => {
  const tabs = [
    {
      id: 1,
      text: "general",
      path: "/settings",
      hasPermission: true,
    },
    {
      id: 2,
      text: "system users",
      path: "/settings/system-users",
      hasPermission: usePermission([
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 3,
      text: "roles & permissions",
      path: "/settings/roles-and-permissions",
      hasPermission: usePermission([
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
      ]),
    },
  ];

  return tabs;
};

export default useTabData;
