import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { CustomTextArea } from "../../../Core/Components/CustomInput";
import { SERVICE_ASSET_TYPE, SEVERITY, severityOptions } from "../Data";
import { addServiceV3, updateServiceV3 } from "../../../Redux/APIs/servicesAPI";
import { updateServices } from "../../../Redux/Slices/services.slice";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { LOCATION_TYPE } from "../../Location/Data";

const AddToService = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedService, updatingService } = useSelector(
    (state) => state.services,
  );
  const { currentTrike } = useSelector((state) => state.trikes);
  const { currentBattery } = useSelector((state) => state.batteries);
  const { allLocations } = useSelector((state) => state.locations);

  const locationListForTrikes = allLocations
    ?.filter((item) => item.type === LOCATION_TYPE.PARKING_LOT)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, clearErrors } = useForm({
    defaultValues: {
      type: type === "trikes" ? "trike" : "battery",
      id,
      severityText: updatingService
        ? SEVERITY[selectedService.severity - 1]
        : "",
      severityId: updatingService ? selectedService.severity : 0,
      description: updatingService ? selectedService.description : "",
      locationName: updatingService ? selectedService?.locationName : "",
      loactionId: updatingService ? selectedService?.locationId : "",
    },
  });

  const rules = {
    required: "Required.",
  };

  const severityTextError = formState?.errors?.severityText?.message;
  const locationNameError = formState?.errors?.locationName?.message;

  const onCancel = () => {
    navigate(-1);
  };
  const onAdd = async (e) => {
    const payload = {
      severity: e.severityId,
      description: e.description,
      locationId: e.locationId,
      locationName: e.locationName,
    };

    if (type === "trikes") {
      payload.assetId = currentTrike.id;
      payload.assetType = SERVICE_ASSET_TYPE.TRIKE;
      payload.assetRegistrationNo = currentTrike.registrationNo;

      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Trike Added to Service",
              description: `Trike ${currentTrike.registrationNo} was added to service successfully.`,
            },
          },
        }),
      );
    } else {
      payload.assetId = currentBattery.id;
      payload.assetType = SERVICE_ASSET_TYPE.BATTERY;
      payload.assetRegistrationNo = currentBattery.registrationNo;

      dispatch(
        updateBatteries({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Battery Added to Service",
              description: `Battery ${currentBattery.registrationNo} was added to service successfully.`,
            },
          },
        }),
      );
    }

    if (updatingService) {
      payload.serviceId = selectedService.id;
      await dispatch(updateServiceV3(payload));
    } else {
      await dispatch(addServiceV3(payload));
    }
    dispatch(
      updateServices({
        key: "updatingService",
        value: false,
      }),
    );

    navigate(-1);
  };

  return (
    <CustomLayout pageName={type} nestedName={`${id}`} hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {updatingService
              ? "Edit service entry"
              : "Create a new service entry"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {updatingService ? "Edit Service" : "Add To Service"}
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full bg-background-white p-6">
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Severity
        </p>
        <Controller
          name="severityText"
          control={control}
          rules={rules}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              placeholder="Select severity of the issue"
              formState={formState}
              options={severityOptions}
              onSelect={(data) => {
                setValue("severityText", data.value);
                setValue("severityId", data.id);
                clearErrors("severityText");
              }}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
            severityTextError ? "text-alert-red visible" : "invisible"
          }`}
        >
          {severityTextError}
        </p>

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Description
        </p>
        <Controller
          name="description"
          control={control}
          rules={rules}
          render={({ field }) => (
            <CustomTextArea
              {...field}
              formState={formState}
              className="w-[367px] bg-white rounded-md"
              placeholder="Enter a description of the issue..."
            />
          )}
        />

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Location
        </p>

        <Controller
          name="locationName"
          control={control}
          rules={rules}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Location"
              placeholder="Enter Location"
              className="max-h-[300px] overflow-y-scroll"
              formState={formState}
              options={locationListForTrikes}
              onSelect={(data) => {
                setValue("locationName", data.value);
                setValue("locationId", data.id);
                clearErrors("locationName");
              }}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
            locationNameError ? "text-alert-red visible" : "invisible"
          }`}
        >
          {locationNameError}
        </p>
      </div>
    </CustomLayout>
  );
};

export default AddToService;
