/* eslint-disable no-plusplus */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Popover } from "antd";
import dayjs from "dayjs";
import Button from "../../../Core/Components/CustomButton";
import CustomSearchAndSelect from "../../../Core/Components/CustomSearchAndSelect";
import CloseIcon from "../../../Common/Svgs/CloseIcon";

const MoreBatteryFilters = ({ filters, setFilters }) => {
  const { allSystemUsers } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);
  const [finalAddedByList, setFinalAddedByList] = useState([]);
  const [moreFilters, setMoreFilters] = useState({
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
  });

  const addedByList = allSystemUsers?.map((user) => {
    return { id: user.id, name: `${user.firstName} ${user.lastName}` };
  });

  const filterCount = useMemo(() => {
    let count = 0;
    if (moreFilters.dateRangeStart && moreFilters.dateRangeEnd) count++;
    if (moreFilters.addedBy?.length > 0) count++;
    return count;
  }, [moreFilters]);

  const hasFieldValues = () => {
    return (
      moreFilters?.addedBy?.length > 0 ||
      (moreFilters?.dateRangeStart !== "" && moreFilters?.dateRangeEnd !== "")
    );
  };

  const handleDateRangeChange = (e) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      setMoreFilters({
        ...moreFilters,
        dateRangeStart: e[0].format("YYYY-MM-DD"),
        dateRangeEnd: e[1].format("YYYY-MM-DD"),
      });
    }
  };

  const handleClear = () => {
    setFinalAddedByList([]);
    setMoreFilters({
      dateRangeStart: "",
      dateRangeEnd: "",
      addedBy: [],
    });

    setFilters({
      ...filters,
      dateRangeStart: "",
      dateRangeEnd: "",
      addedBy: [],
    });
  };

  const handleApply = () => {
    setFilters({
      ...filters,
      ...moreFilters,
    });
  };

  return (
    <Popover
      trigger="click"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div className="overflow-y-auto bg-white w-78 rounded-md p-3 shadow-moreFilters">
          <div className="flex items-center justify-between">
            <div className="text-base font-medium text-new-black font-poppins">
              More filters
            </div>
            <CloseIcon
              className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="mt-6">
            <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mb-2">
              Date Range
            </div>
            <DatePicker.RangePicker
              value={[
                moreFilters.dateRangeStart !== "" &&
                  dayjs(moreFilters.dateRangeStart),
                moreFilters.dateRangeEnd !== "" &&
                  dayjs(moreFilters.dateRangeEnd),
              ]}
              format="DD MMM YYYY"
              separator={<div className="text-dark-grey-text"> - </div>}
              onCalendarChange={(e) => handleDateRangeChange(e)}
              allowClear={false}
              className="h-[39px]"
            />

            <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
              Added By
            </div>
            <CustomSearchAndSelect
              placeholder="Type or Select User"
              inputList={addedByList}
              finalList={finalAddedByList}
              setFinalList={setFinalAddedByList}
              onChange={(e) => setMoreFilters({ ...moreFilters, addedBy: e })}
            />
          </div>

          <div className="flex mt-6 gap-x-3">
            <Button
              size="primary"
              width="save"
              colors="grey"
              modifier={hasFieldValues() ? null : "blur"}
              onClick={() => {
                setOpen(false);
                handleClear();
              }}
            >
              Clear All
            </Button>
            <Button
              size="primary"
              width="save"
              type="submit"
              modifier={hasFieldValues() ? null : "blur"}
              onClick={() => {
                setOpen(false);
                handleApply();
              }}
            >
              Apply Filters
            </Button>
          </div>
        </div>
      }
    >
      {filterCount > 0 ? (
        <div className="flex items-center gap-x-3">
          <button
            type="button"
            className="px-3 py-[11px] bg-green rounded-md flex items-center gap-x-3"
          >
            <p className="font-poppins text-xs text-background-white">
              Filters
            </p>
            <div className="w-4.5 h-4.5 rounded-full flex items-center justify-center bg-background-white">
              <p className="text-10 font-aileron text-black font-semibold">
                {filterCount}
              </p>
            </div>
          </button>
          <button
            type="button"
            className="px-3 py-[11px] rounded-md hover:bg-background"
            onClick={() => {
              handleClear();
            }}
          >
            <p className="font-poppins text-xs text-black font-medium">
              Clear All
            </p>
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="flex items-center gap-x-3 px-3 py-[11px] hover:bg-background rounded-md"
        >
          <img src="/assets/icons/sort.svg" alt="Filters" />
          <p className="font-poppins text-xs text-dark-gray">More Filters</p>
        </button>
      )}
    </Popover>
  );
};

export default MoreBatteryFilters;
