const { useSelector } = require("react-redux");

const usePermission = (allowedPermissions = []) => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  if (userDetails?.permissions?.length) {
    const hasPermission = allowedPermissions?.some((item) =>
      userDetails.permissions.includes(item),
    );

    return hasPermission;
  }

  return false;
};

export default usePermission;
