import { createSlice } from '@reduxjs/toolkit';
import { getInventory, getInventoryFromODK } from '../APIs/inventoryAPI';

const initialState = {
  loading: false,
  error: {},
  status: 'idle',
  loggedIn: false,
  inventory: [],
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // get inventory details from DB

    builder.addCase(getInventory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.status = 'fulfilled';
      state.inventory = action.payload;
    });
    builder.addCase(getInventory.rejected, (state, action) => {
      state.loading = false;
      state.status = 'rejected';
      state.inventory = [];
      state.error = action.error;
    });

    // get inventory details from ODK

    builder.addCase(getInventoryFromODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInventoryFromODK.fulfilled, (state) => {
      state.loading = false;
      state.status = 'fulfilled';

      // state.inventory = action.payload;
    });
    builder.addCase(getInventoryFromODK.rejected, (state, action) => {
      state.loading = false;
      state.status = 'rejected';
      // state.inventory = [];
      state.error = action.error;
    });
  },
});

export default inventorySlice.reducer;
