import React from 'react';
import CustomTooltip from './CustomTooltip';
import useSystemUser from '../hooks/useSystemUser';
import getDateAndTime from '../../Utils/getDateAndTime';

const SubmittedDetails = ({ record }) => {
  const submittedBy = useSystemUser(record.submittedBy);

  return (
    <CustomTooltip
      tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
      placement="topLeft"
    >
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {submittedBy}
        </div>
        <div className="text-10 font-light font-aileron leading-3.75 text-dark-gray">
          {getDateAndTime(record.submittedAt)}
        </div>
      </div>
    </CustomTooltip>
  );
};

export default SubmittedDetails;
