/* eslint-disable import/no-named-as-default */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import trikesSlice from "./Slices/trikes.slice";
import batteriesSlice from "./Slices/batteries.slice";
import loggedInUserSlice from "./Slices/loggedInUser.slice";
import usersSlice from "./Slices/users.slice";
import servicesSlice from "./Slices/services.slice";
import locationSlice from "./Slices/locations.slice";
import tripsSlice from "./Slices/trips.slice";
import inventorySlice from "./Slices/inventory.slice";
import homeSlice from "./Slices/home.slice";
import qrcodeSlice from "./Slices/qrcode.slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "loggedInUser",
    "trikes",
    "batteries",
    "users",
    "services",
    "locations",
    "trips",
    "qrCode",
  ],
};

const reducer = combineReducers({
  loggedInUser: loggedInUserSlice,
  trikes: trikesSlice,
  batteries: batteriesSlice,
  users: usersSlice,
  services: servicesSlice,
  locations: locationSlice,
  trips: tripsSlice,
  inventory: inventorySlice,
  home: homeSlice,
  qrCode: qrcodeSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export const persistor = persistStore(store);
