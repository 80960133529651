/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  addKnownIssues,
  addTrikeV3,
  createChecklist,
  deleteTrikeV3,
  editTrikeV3,
  getAllTrikesV3,
  getChecklist,
  getHistoricalForTrike,
  getTrikeByIdV3,
  getTrikeDetails,
  getTrikeDetailsById,
  getTrikes,
  getTrikesKPI,
  getTrikesV3,
  getTripDetailsByTrikeId,
  resolveKnownIssue,
  updateChecklist,
  updateGeofenceV3,
  updateKnownIssues,
  updateLocationSwitchV3,
} from "../APIs/trikesAPI";
import getStatusDetails from "../../Utils/getStatusDetails";
import { getDifferenceOfTime } from "../../Utils/getDateAndTime";
import { getBatteryPacks } from "../APIs/batteriesAPI";
import getUniqueList from "../../Utils/getUniqueList";
import distanceCalculator from "../../Utils/distanceCalculator";

const initialState = {
  selectedColumns: [
    "TRIKES_ID_NUMBER",
    "TRIKES_DRIVER_NAME",
    "TRIKES_BATTERY_PACK",
    "TRIKES_CURRENT_SPEED",
    "TRIKES_STATUS",
    // "TRIKES_ON_OFF",
    "TRIKES_CURRENT_PAYLOAD",
    // "TRIKES_TRACKER",
  ],
  loading: false,

  driversList: [],
  currentTrike: [],
  createGeofence: false,
  tripDetailsForCurrentTrike: [],
  mobiliseModal: false,
  geoFenceModal: false,
  fullScreenMap: false,
  saveGeoFenceLatLngModal: {
    visible: true,
    text: "Create",
  },
  isWithinGeofence: false,

  geoFenceLatLngs: {
    trikeId: "",
    latLngs: [],
  },

  // phase 3
  selectedRowIds: [],
  trikeListV3: {
    result: [],
    totalRecords: 0,
  },
  kpiData: {
    total: 0,
    trikesDispatch: 0,
    trikesWithDrivers: 0,
    trikesInService: 0,
  },
  showToastMessage: {
    visible: false,
    data: {},
  },
  allTrikes: [],
  checklist: [],
  checklistModal: {
    visible: false,
    checklist: [],
    trike: "",
  },
  mobiliseModalV3: {
    visible: false,
    trike: {},
  },
  deleteTrikeModal: {
    visible: false,
    id: 0,
    registrationNo: "",
  },

  trikeTrackerModal: {
    visible: false,
    trikeId: 0,
    trikeRegistrationNo: "",
    currentSource: 1,
    tripId: 0,
  },

  threeDotsPopoverId: 0,
};

export const trikesSlice = createSlice({
  name: "trikes",
  initialState,
  reducers: {
    updateTrikes: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateMultipleTrikes: (state, action) => {
      action.payload.map((item) => {
        state[item.key] = item.value;
      });
    },
    resetTrikes: (state, action) => {
      action.payload.map((item) => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(getTrikes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTrikes.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });

    builder.addCase(getTrikes.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    builder.addCase(getTrikeDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTrikeDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      // console.log(action.payload, 'TRIKE Details');

      const responseData = action.payload.map((item) => ({
        IDNumber: item.id_number || "--",
        driverName: item.driver_name || "--",
        battInPercentage: item.battery_pack || -1,
        currentSpeed: item.current_speed || null,
        status: getStatusDetails(item.status_id),
        statusId: item.status_id,
        isMobilized: item.is_mobilised === true ? 2 : 1,
        currentPayload: item.current_payload
          ? `${item.current_payload} Kg`
          : "--",
        totalPayload: item.total_payload ? `${item.total_payload} Kg` : "--",
        distance: item.total_distance || "--",
        mileage: item.mileage === null ? "--" : `${item.mileage} Km` || "--",
        chassisNo: item.chassis_no === null ? "--" : item.chassis_no || "--",
        modelNo: item.model_no === null ? "--" : item.model_no || "--",
        averageSpeed: item.average_speed || null,
        usage: item.total_usage || "--",
        submittedBy: item.submitted_by || "--",
        submittedAt: item.submitted_at || "--",
        geofence: item.geofence,
        checklist: item.checklist ? item?.checklist : null,
        lastLocation: item.last_location || null,
        sensorId: item.sensor_id,
        oldLocation: item.old_location || null,
        lastOnline: item.last_online || null,
      }));

      const uniqueDriverList = [];
      const driversListForUsersModule = [];

      state.trikeDetails = responseData;

      const driverList = getUniqueList(state.trikeDetails, "driverName");

      driverList.map((item) => {
        uniqueDriverList.push({
          id: item.IDNumber,
          value: item.driverName,
        });
      });

      driverList.map((driver, index) => {
        driversListForUsersModule.push({
          id: index + 1,
          name: driver.driverName,
          userCategory: "Driver",
          licenseId: null,
          trikeId: driver.IDNumber,
          type: null,
          noOfTrips: null,
          submittedBy: null,
          submittedAt: null,
        });
      });

      state.driverNameFilters = uniqueDriverList;
      state.driversList = driversListForUsersModule;
    });
    builder.addCase(getTrikeDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    builder.addCase(getTrikeDetailsById.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTrikeDetailsById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      // console.log(action.payload, 'check payload for current trike');

      const responseData = action.payload.map((item) => ({
        IDNumber: item.id_number || "--",
        driverName: item.driver_name || "--",
        battInPercentage: item.battery_pack || -1,
        currentSpeed: item.current_speed || null,
        status: getStatusDetails(item.status_id),
        checklist: item.checklist ? item?.checklist : null,
        statusId: item.status_id,
        sensorId: item.sensor_id,
        isMobilized: item.is_mobilised === true ? 2 : 1,
        currentPayload: item.current_payload
          ? `${item.current_payload} Kg`
          : "--",
        totalPayload: item.total_payload ? `${item.total_payload} Kg` : "--",
        distance: item.total_distance || "--",
        mileage: item.mileage === null ? "--" : `${item.mileage} Km` || "--",
        averageSpeed: item.average_speed || null,
        usage: item.total_usage || "--",
        submittedBy: item.submitted_by || "--",
        submittedAt: item.submitted_at || "--",
        geofence: item.geofence,
        associatedBatteries: [
          item.associated_batt_1,
          item.associated_batt_2,
          item.associated_batt_3,
        ],
        lastLocation: item.last_location || null,
        oldLocation: item.old_location || null,
        lastOnline: item.last_online || null,
      }));

      state.currentTrike = responseData[0];
    });
    builder.addCase(getTrikeDetailsById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // Trip details for trike

    builder.addCase(getTripDetailsByTrikeId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTripDetailsByTrikeId.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const responseData = action.payload.map((item, index) => ({
        id: index + 1,
        startTime: item.startTime,
        endTime: item.endTime,
        payload: item.payload,
        odometerStart: item.odometerStart || "--",
        odometerEnd: item.odometerEnd || "--",
        duration: getDifferenceOfTime(item.startTime, item.endTime),
      }));

      state.tripDetailsForCurrentTrike = responseData;
    });
    builder.addCase(getTripDetailsByTrikeId.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
    builder.addCase(getBatteryPacks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatteryPacks.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.trikeDetails.map((trike) => {
        action.payload?.map((battery) => {
          if (battery.sensorId === trike.sensorId) {
            trike.batteryPackPercentage =
              (Number(battery?.battery_1?.soc) +
                Number(battery?.battery_2?.soc) +
                Number(battery?.battery_3?.soc)) /
              3;
            trike.currentSpeed = battery?.global_values?.speed;
            trike.distance = distanceCalculator(
              {
                lat: battery?.global_values?.latitude,
                lng: battery?.global_values?.longitude,
              },
              trike.oldLocation,
            );
          }
        });
      });
      action.payload?.map((battery) => {
        if (battery.sensorId === state.currentTrike.sensorId) {
          state.currentTrike.distance = distanceCalculator(
            {
              lat: battery?.global_values?.latitude,
              lng: battery?.global_values?.longitude,
            },
            state.currentTrike.oldLocation,
          );
        }
      });
    });
    builder.addCase(getBatteryPacks.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
    builder.addCase(getHistoricalForTrike.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHistoricalForTrike.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      if (action.payload.length > 0) {
        const totalSpeed = action.payload.reduce(
          (sum, item) => sum + parseFloat(item.speed),
          0,
        );
        const averageSpeed = Math.round(
          totalSpeed / action.payload.length,
        ).toFixed(2);

        state.trikeDetails.map((trike) => {
          action.payload?.map((history) => {
            if (history.sensor_id === trike.sensorId) {
              trike.averageSpeed = averageSpeed;
            }
          });
        });
        action.payload?.map((history) => {
          if (history.sensor_id === state.currentTrike.sensorId) {
            state.currentTrike.averageSpeed = averageSpeed;
          }
        });
      }
    });
    builder.addCase(getHistoricalForTrike.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // phase 3
    // get trikes
    builder.addCase(getTrikesV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrikesV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.trikeListV3 = action.payload;
      // console.log(action.payload, 'check trikes');
    });
    builder.addCase(getTrikesV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add trike
    builder.addCase(addTrikeV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTrikeV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addTrikeV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit trike
    builder.addCase(editTrikeV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editTrikeV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editTrikeV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete trike
    builder.addCase(deleteTrikeV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTrikeV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteTrikeV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // add known issues
    builder.addCase(addKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addKnownIssues.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update known issues
    builder.addCase(updateKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateKnownIssues.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // resolve known issues
    builder.addCase(resolveKnownIssue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resolveKnownIssue.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(resolveKnownIssue.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get all trikes
    builder.addCase(getAllTrikesV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTrikesV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      // console.log(action.payload, 'check all trikes');
      state.allTrikes = action.payload.data;
    });
    builder.addCase(getAllTrikesV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get kpi data
    builder.addCase(getTrikesKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrikesKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpi;
    });
    builder.addCase(getTrikesKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get trike by id

    builder.addCase(getTrikeByIdV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrikeByIdV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.currentTrike = action.payload.data;
    });
    builder.addCase(getTrikeByIdV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // create checklist
    builder.addCase(createChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(createChecklist.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get checklist
    builder.addCase(getChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChecklist.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.checklist = action.payload.checklist;
    });
    builder.addCase(getChecklist.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update checklist
    builder.addCase(updateChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateChecklist.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update geofence
    builder.addCase(updateGeofenceV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGeofenceV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateGeofenceV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // switch location fetch source
    builder.addCase(updateLocationSwitchV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLocationSwitchV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateLocationSwitchV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const { updateTrikes, updateMultipleTrikes, resetTrikes } =
  trikesSlice.actions;

export default trikesSlice.reducer;
