import React from 'react';

const TrikeIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        // {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14 5v7c0 1.1-.9 2-2 2H1v-4"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M21 18l-1-6h-3m2 8H9c0-1.1-.9-2-2-2s-2 .9-2 2H1v-6h11c1.1 0 2-.9 2-2V5h-3 9"
        />
        <path
          stroke="currentColor"
          // fill={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7 22a2 2 0 100-4 2 2 0 000 4zM21 22a2 2 0 100-4 2 2 0 000 4z"
        />
      </svg>
    </span>
  );
};

export const ActiveTrikeIcon = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14 5v7c0 1.1-.9 2-2 2H1v-4"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M21 18l-1-6h-3m2 8H9c0-1.1-.9-2-2-2s-2 .9-2 2H1v-6h11c1.1 0 2-.9 2-2V5h-3 9"
        />
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7 22a2 2 0 100-4 2 2 0 000 4zM21 22a2 2 0 100-4 2 2 0 000 4z"
        />
      </svg>
    </span>
  );
};

export default TrikeIcon;
