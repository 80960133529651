/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Col, Divider, Row } from "antd";
import { trikeMapMarkerDetails } from "../../Modules/Home/Data/HomeData";
import Button from "./CustomButton";
import CustomMapMarker from "./CustomMapMarker";
import ZoomButton, { RelocateButton } from "./MapMarkerButtons";
import VerifyIcon from "../../Common/Svgs/VerifyIcon";
import CustomTooltip from "./CustomTooltip";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import getColorAndIcon from "../utils/getColorAndIcon";
import { LOCATION_TYPE } from "../../Modules/Location/Data";
import { TRIKE_STATUS } from "../../Modules/Trikes/Data";
import { MobiliseModalCTA } from "./Modal/MobiliseModalV3";

const TrikeMapMarkerDetails = ({ assetId = " KTWC_408R", trikeDetail }) => {
  const navigate = useNavigate();

  const { trikeBatteries } = useSelector((state) => state.locations);

  return (
    <div className="p-4 w-[336px] bg-white rounded-lg">
      <div className="flex justify-between pb-4 border-b border-light-grey">
        <div>
          <div className="text-xs font-medium font-poppins leading-4.5 text-black">
            {assetId}
          </div>
          <div className="flex items-center pt-1.5">
            <div className="font-light font-poppins text-10 leading-3.75 text-dark-grey-text capitalize">
              {trikeDetail?.status == TRIKE_STATUS.ON_TRIP ||
              trikeDetail?.status == TRIKE_STATUS.OUT_OF_GEOFENCE
                ? "Online"
                : "Offline"}
            </div>
            <Divider type="vertical" className="bg-light-grey mt-[3px]" />
            <div className="font-light font-poppins text-10 leading-3.75 text-dark-grey-text">
              {trikeDetail.lastOnline === null
                ? "-"
                : dayjs(trikeDetail.lastOnline).format("hh:mm a")}
            </div>
          </div>
        </div>

        <div>
          <MobiliseModalCTA trike={trikeDetail} />
        </div>
      </div>
      {trikeMapMarkerDetails(trikeDetail, trikeBatteries?.[0]?.[0]).map(
        (item) => (
          <div className="flex items-center justify-between mt-4" key={item.id}>
            <div className="flex items-center gap-x-2">
              <CustomTooltip tooltipTitle={item.tooltipText}>
                <img
                  src={`/assets/icons/home/map-markers/${item.icon}`}
                  alt=""
                />
              </CustomTooltip>
              <div className="text-10 capitalize font-medium leading-3.75 font-poppins text-black">
                {item.title}
              </div>
            </div>
            <div className="text-10 font-light leading-3.75 font-poppins text-dark-grey-text">
              {item.details}
            </div>
          </div>
        ),
      )}

      <div className="flex justify-end mt-4">
        <Button
          onClick={() => navigate(`/trikes/${trikeDetail.registrationNo}`)}
        >
          View Details
        </Button>
      </div>
    </div>
  );
};

export const OtherMapMarkerDetails = ({
  city = "Kisii",
  country = "Kenya",
  item,
  // locationCount,
}) => {
  // const getLocationCount = (location) => {
  //   let count;

  //   if (location === 'Boka Eats') {
  //     count = locationCount['Agr E Hub'];
  //   } else {
  //     count = locationCount[location] || '--';
  //   }
  //   return count;
  // };

  const locationLat = item?.lat || item?.coordinates?.lat;
  const locationLng = item?.long || item?.coordinates?.lng;

  const renderLocation = () => {
    switch (item.type) {
      case "1":
        return "Agr-e-hub";
      case "2":
        return "Swap Station";
      case "4":
        return "Farm";
      default:
        return "Swap Station";
    }
  };

  return (
    <div className="p-6 w-[336px] bg-white rounded-lg">
      <div className="flex justify-between pb-4 border-b border-light-grey">
        <div>
          <div className="text-xs font-medium font-poppins leading-4.5 text-new-black">
            {item?.name}
          </div>
          <div className="font-light font-poppins text-10 leading-3.75 text-dark-grey-text mt-1.5">
            {/* {item.location_type === 2 ? 'Swap Station' : 'Assembly Station'} */}
            {renderLocation()}
          </div>
        </div>
        {/* {item?.location_type === 2 ? (
          <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
            <span className="font-medium">{getLocationCount(item?.name)} </span>
            Batteries Available
          </div>
        ) : null} */}
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center gap-x-2">
          <img src="/assets/icons/home/map-markers/location.svg" alt="" />
          <div className="font-poppins text-10 leading-3.75 font-medium text-new-black">
            {city},{" "}
            <span className="font-light text-dark-grey-text">{country}</span>
          </div>
        </div>
        <div className="text-10 font-light leading-3.75 font-poppins text-dark-grey-text">
          {parseFloat(locationLat).toFixed(6)},
          {parseFloat(locationLng).toFixed(6)}
        </div>
      </div>
    </div>
  );
};

export const MapDetailDrawer = ({ initialZoomLevel, mapRef, center }) => {
  const { allLocations } = useSelector((state) => state.locations);
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`absolute transition-all left-0 right-0 z-20 w-full ${
        open ? "h-[202px]" : "h-20"
      } rounded-md bottom-10`}
    >
      <Row gutter={15}>
        <Col span={22}>
          <div
            className={`ml-6.5 transition-all bg-white rounded-md ${
              open ? " h-[230px]" : "h-27"
            }`}
          >
            <div className="grid grid-cols-2 px-6 py-2">
              <div className="text-base font-medium text-black font-poppins">
                Locations
              </div>
              <div className="flex flex-col">
                <div className="relative flex items-center justify-start w-full right-4">
                  <Button
                    onClick={() => setOpen(!open)}
                    padding="arrows"
                    size="arrows"
                    colors="grey"
                  >
                    <ChevronIcon
                      className={`w-4 h-4 transition-all ${
                        open ? "rotate-90" : "-rotate-90"
                      } stroke-new-black`}
                    />
                  </Button>
                </div>
                <div
                  className={`absolute w-[1px] mt-9 bg-light-grey ${
                    open ? "h-[170px]" : "h-14"
                  }`}
                />
              </div>
            </div>
            <div
              className={`grid grid-cols-2 overflow-hidden transition-all  ${
                open ? "h-44" : ""
              }`}
            >
              <div
                className="h-14"
                // className={`${
                //   open
                //     ? 'border-r border-light-grey h-36'
                //     : 'border-r border-light-grey h-14'
                // }`}
              >
                {allLocations &&
                  allLocations
                    ?.filter((item) => item.type === LOCATION_TYPE.SWAP_STATION)
                    ?.map((location, idx) => (
                      <div key={idx} className="h-full mt-2">
                        <div className="flex items-center justify-start px-6 gap-x-3">
                          <CustomMapMarker
                            icon={getColorAndIcon(2).icon}
                            colors={getColorAndIcon(2).colors}
                          />
                          <div>
                            <div className="text-base font-medium text-black font-poppins">
                              {location?.name}
                            </div>
                            <div className="flex items-center justify-start mt-1 gap-x-2">
                              <div className="text-xs font-normal font-aileron text-dark-gray">
                                Swap Station
                              </div>
                              {/* <Divider type="vertical" className="m-0" />
                              <div className="text-xs font-light capitalize font-aileron text-dark-gray">
                                <span className="font-semibold">
                                  {getLocationCount(location?.name)}
                                </span>{' '}
                                batteries available
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              <div className="mt-1 h-14">
                {allLocations &&
                  allLocations
                    ?.filter(
                      (item) => item.type === LOCATION_TYPE.ASSEMBLY_STATION,
                    )
                    ?.map((location, idx) => (
                      <div key={idx} className="h-full">
                        <div className="flex items-center justify-start px-6 gap-x-3">
                          <CustomMapMarker
                            icon={getColorAndIcon(1).icon}
                            colors={getColorAndIcon(1).colors}
                          />
                          <div>
                            <div className="text-base font-medium text-black font-poppins">
                              {location?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </Col>
        <Col span={2}>
          <div className="flex flex-col justify-end h-full gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomLevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const TrikeDetailsDrawer = ({
  mapRef,
  initialZoomLevel,
  center,
  setViewDetailsDrawer,
}) => {
  const { currentTrike } = useSelector((state) => state.trikes);

  const onTripsClick = () => {
    setViewDetailsDrawer(true);
  };

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16}>
        <Col span={21}>
          <div className="p-3 ml-6 bg-white rounded-md h-27">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <div className="text-base font-medium capitalize font-poppins text-new-black">
                  {currentTrike.driverFirstName
                    ? `${currentTrike.driverFirstName} ${currentTrike.driverLastName}`
                    : "--"}
                </div>
                <VerifyIcon />
              </div>
              {currentTrike?.currentDriverId ? (
                <button
                  type="button"
                  className="flex items-center px-2 py-1 gap-x-2"
                  onClick={onTripsClick}
                >
                  <p className="text-xs font-medium font-poppins text-primary">
                    Trips
                  </p>
                  <ChevronIcon className="w-4 h-4 stroke-primary" />
                </button>
              ) : null}
            </div>

            <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
              Driver
            </div>

            <div className="flex gap-x-[67px] mt-3">
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Distance
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike.distance ? currentTrike?.distance : "--"} Km
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Current Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike?.currentSpeed || "--"} Km/H
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Avg. Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike?.averageSpeed || "--"} Km/H
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const BatteryDetailsDrawer = ({ mapRef, initialZoomLevel, center }) => {
  const { currentBattery } = useSelector((state) => state.batteries);

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16} className="flex items-end">
        <Col span={21}>
          <div className="h-16 p-3 ml-6 bg-white rounded-md">
            <div className="text-base font-medium font-poppins text-new-black">
              {currentBattery?.trikeRegistrationNo || "--"}
            </div>
            <div className="flex items-center">
              <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text capitalize">
                {currentBattery.driverFirstName
                  ? `${currentBattery.driverFirstName} ${currentBattery.driverLastName}`
                  : "Moses Sambaza"}
              </div>
              <Divider type="vertical" className="bg-light-grey h-2.5 mt-0.5" />
              <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                20 Min Ago
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const TripDetailsDrawer = ({ mapRef, initialZoomLevel, center }) => {
  const { currentTrip } = useSelector((state) => state.trips);
  const { currentTrike } = useSelector((state) => state.trikes);

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16}>
        <Col span={21}>
          <div className="p-3 ml-6 bg-white rounded-md h-27">
            <div className="flex items-center gap-x-2">
              <div className="text-base font-medium text-black capitalize font-poppins">
                {currentTrip.id}
              </div>
            </div>
            <div className="flex gap-x-[67px] mt-3">
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Distance
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrip.distance
                    ? `${parseFloat(currentTrip.distance).toFixed(2)} Km`
                    : "--"}
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Current Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrike?.currentSpeed || "--"} Km/H
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Avg. Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrike?.averageSpeed || "--"} Km/H
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TrikeMapMarkerDetails;
