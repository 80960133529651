/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import CustomDetailsLayout from "../../../Core/Layout/CustomDetailsLayout";
import DeleteTripModal from "./DeleteTripModal";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import { getTrikeByIdV3 } from "../../../Redux/APIs/trikesAPI";
import FullScreenMapForTrips from "../FullScreenMapForTrips";
import { TRIP_STATUS } from "../Data/TripConstants";
import TripSummaryStops from "../phase3/TripSummaryStops";
import TripKpiAndInformation from "../phase3/TripKpiAndInformation";
import StopSummary from "../phase3/StopSummary";
import FarmerSummary from "../phase3/FarmerSummary";
import ChecklistModal from "../../../Core/Components/Modal/ChecklistModal";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import snapToRoad, { fastestRoute } from "./snapToRoad";
import {
  getOverallSummary,
  getTripDetailsKPI,
} from "../../../Redux/APIs/tripsAPI";
import TrikeTrackerModal from "../../Trikes/phase3/TrikeTrackerModal";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";

const TripDetails = () => {
  const { id, stopId, farmerId } = useParams();
  const dispatch = useDispatch();

  const { currentTrip, deleteTripModal, fullScreenMapForTrips } = useSelector(
    (state) => state.trips,
  );

  const { checklistModal, trikeTrackerModal } = useSelector(
    (state) => state.trikes,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { allLocations } = useSelector((state) => state.locations);

  const [error, setError] = useState(false);
  const [fastestRouteError, setFastestRouteError] = useState(false);

  const localCurrentTrip =
    currentTrip?.stopsId[0] ===
    currentTrip?.stopsId[currentTrip?.stopsId?.length - 1]
      ? currentTrip?.stops?.slice(0, -1)
      : currentTrip?.stops;

  const stopCoordinates = localCurrentTrip?.map((stop) => {
    const currentLocation = allLocations?.find(
      (location) => location.name === stop.location,
    );

    if (currentLocation) {
      return [+currentLocation.lat, +currentLocation.long];
    }

    return [0, 0];
  });

  const snapToRoadAPICall = useCallback(async () => {
    const snapCoordinates = await snapToRoad(
      allLocations,
      currentTrip.stops,
      setError,
    );

    if (error) {
      dispatch(
        updateTrips({
          key: "fastestRouteCoordinates",
          value: stopCoordinates,
        }),
      );
    } else {
      dispatch(
        updateTrips({
          key: "snapToRoadCoordinates",
          value: snapCoordinates,
        }),
      );

      const fastestRouteCoordinates = await fastestRoute(
        snapCoordinates,
        setFastestRouteError,
      );

      if (fastestRouteError) {
        dispatch(
          updateTrips({
            key: "fastestRouteCoordinates",
            value: stopCoordinates,
          }),
        );
      } else {
        dispatch(
          updateTrips({
            key: "fastestRouteCoordinates",
            value: fastestRouteCoordinates,
          }),
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      currentTrip &&
      currentTrip.trikeId !== 0 &&
      currentTrip.trikeId !== ""
    ) {
      dispatch(getTrikeByIdV3({ id: currentTrip.trikeId }));
    }

    if (currentTrip && currentTrip.statusId === TRIP_STATUS.COMPLETED) {
      dispatch(getOverallSummary({ tripId: currentTrip.id }));
    } else {
      if (currentTrip) {
        dispatch(
          getTripDetailsKPI({
            tripId: currentTrip.id,
          }),
        );
      }
    }

    snapToRoadAPICall();
  }, [dispatch, currentTrip, snapToRoadAPICall]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTrip.statusId === TRIP_STATUS.ONGOING) {
        dispatch(getTrikeByIdV3({ id: currentTrip.trikeId }));
      }
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  // Responsible for the body which will be rendered on the screen
  const getKPIBody = () => {
    // if the trip is upcoming or ongoing, trip summary won't render
    // if the trip is completed, nested route is created.
    // Nested route - overall, stop, farmer summary
    if (
      currentTrip.statusId === TRIP_STATUS.ONGOING ||
      currentTrip.statusId === TRIP_STATUS.UPCOMING
    ) {
      return <TripKpiAndInformation />;
    }
    // farmer summmary page
    if (farmerId) return <FarmerSummary />;

    // stop summary page
    if (stopId) return <StopSummary />;

    // overall summary page
    return <TripSummaryStops />;
  };

  return (
    <CustomLayout pageName="Trips" nestedName={`${id}`}>
      <CustomDetailsLayout
        assetType="trips"
        KPIBody={<div>{getKPIBody()}</div>}
      />
      <DeleteTripModal
        visible={deleteTripModal.visible}
        id={deleteTripModal.id}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "deleteTripModal",
              value: { ...deleteTripModal, visible: false },
            }),
          )
        }
      />

      <ChecklistModal
        visible={checklistModal.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "checklistModal",
              value: { visible: false, checklist: [] },
            }),
          )
        }
      />

      <TrikeTrackerModal
        visible={trikeTrackerModal.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "trikeTrackerModal",
              value: {
                visible: false,
                trikeId: 0,
                trikeRegistrationNo: "",
                currentSource: 1,
              },
            }),
          )
        }
        page="trip-details"
        trikeCurrentPage={1}
      />

      {fullScreenMapForTrips.visible && (
        <FullScreenMapForTrips
          visible={fullScreenMapForTrips.visible}
          onCancel={() =>
            dispatch(
              updateTrips({
                key: "fullScreenMapForTrips",
                value: { ...fullScreenMapForTrips, visible: false },
              }),
            )
          }
          polygonCoordinates={fullScreenMapForTrips.polygonCoordinates}
        />
      )}
    </CustomLayout>
  );
};
export default TripDetails;
