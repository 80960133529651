/* eslint-disable no-use-before-define */
import React from "react";
import dayjs from "dayjs";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import EditIcon from "../../../Common/Svgs/EditIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import ListDropdown from "../phase3/ListDropdown";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import DriverSuspendIcon from "../../../Common/Svgs/DriverSuspendIcon";
import DriverResumeIcon from "../../../Common/Svgs/DriverResumeIcon";

export const usersColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "id",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div>
        <p className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
          {record.firstName} {record.lastName}
        </p>

        {record.isSuspended ? (
          <p className="text-alert-red text-10 font-aileron font-normal">
            Suspended
          </p>
        ) : null}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        License ID
      </div>
    ),
    dataIndex: "licenseId",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        License Validity
      </div>
    ),
    dataIndex: "licenseValidity",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        {text ? dayjs(text).format("DD-MM-YYYY") : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Current Trike
      </div>
    ),
    dataIndex: "currentTrike",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text hover:underline hover:text-new-black">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        No. of Trips
      </div>
    ),
    dataIndex: "trips",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    render: (text, record) => <SubmittedDetails record={record} />,
  },
  {
    dataIndex: "id",
    width: "100px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown detail={record} type="driver" />
      </button>
    ),
  },
];
export const farmersColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Farmers & Offtakers
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 capitalize">
        {record.firstName} {record.lastName}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Unique ID
      </div>
    ),
    dataIndex: "uniqueId",
    render: (text) => (
      <div className="text-xs font-medium font-aileron leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Contact
      </div>
    ),
    dataIndex: "contactNo",
    render: (text) => (
      <div className="text-xs font-medium font-aileron leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Pick up location
      </div>
    ),
    dataIndex: "locationName",
    render: (text) => (
      <div className="text-xs font-medium font-aileron leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Weight Collected
      </div>
    ),
    dataIndex: "totalWeightCollected",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        {text ? `${text} Kg` : "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Weight Delivered
      </div>
    ),
    dataIndex: "totalWeightDelivered",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        {text ? `${text} Kg` : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    render: (text, record) => <SubmittedDetails record={record} />,
  },
  {
    dataIndex: "id",
    width: "100px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown detail={record} type="farmer" />
      </button>
    ),
  },
];

export const usersDropdownBtnArr = [
  {
    title: "User Category",
    renderBody: "User Category",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "category",
  },

  {
    title: "Current Trike",
    renderBody: "Current Trike",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "trike",
  },
];

export const userDetailsColumn = [
  {
    title: () => (
      <div className="font-normal font-poppins text-10 leading-3.75 text-dark-grey-text">
        Date
      </div>
    ),
    dataIndex: "startDate",
    sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-grey-text">
        {dayjs(text).format("DD-MM-YYYY")}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-poppins text-10 leading-3.75 text-dark-grey-text">
        Trike
      </div>
    ),
    dataIndex: "trikeRegistrationNo",
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-grey-text">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-poppins text-10 leading-3.75 text-dark-grey-text">
        Avg. Speed
      </div>
    ),
    dataIndex: "averageSpeed",
    sorter: (a, b) => a.averageSpeed - b.averageSpeed,
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-grey-text">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-poppins text-10 leading-3.75 text-dark-grey-text">
        Distance
      </div>
    ),
    dataIndex: "distance",
    sorter: (a, b) => a.distance - b.distance,
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-grey-text">
        {`${parseFloat(text).toFixed(2)} Km` || "--"}
      </div>
    ),
  },
];

export const threeDotsForDrivers = [
  {
    id: 1,
    value: "View Trip Details",
    icon: <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: "Edit Details",
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 4,
    value: "Suspend",
    icon: (
      <DriverSuspendIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 5,
    value: "Resume",
    icon: (
      <DriverResumeIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 3,
    value: "Delete User",
    icon: <TrashIcon className="text-alert-red" />,
  },
];

export const threeDotsForFarmers = [
  {
    id: 1,
    value: "Edit Details",
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: "View QR Code",
    icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 3,
    value: "Delete User",
    icon: <TrashIcon className="text-alert-red" />,
  },
];
