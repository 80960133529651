/* eslint-disable no-console */
import { Checkbox, Popover } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import { client } from "../../../Utils/axiosClient";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { updateUsers } from "../../../Redux/Slices/users.slice";

const SearchAndSelectFarmers = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const { selectedFarmersForExport } = useSelector((state) => state.users);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [farmers, setFarmers] = useState([]);

  // const hasValues = selectedValues.length > 0;

  const getFarmers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/filter-data`,
      );

      if (response?.data?.farmers.length) {
        setFarmers([...response.data.farmers]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getFilteredFarmers = () => {
    if (search) {
      return farmers?.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return farmers;
  };

  const onSelect = (id) => {
    if (selectedFarmersForExport?.includes(id)) {
      const values = selectedFarmersForExport?.filter((item) => item !== id);

      setFilters({ ...filters, farmerSelected: values });

      dispatch(
        updateUsers({
          key: "selectedFarmersForExport",
          value: values,
        }),
      );
    } else {
      setFilters({
        ...filters,
        farmerSelected: [...filters.farmerSelected, id],
      });

      dispatch(
        updateUsers({
          key: "selectedFarmersForExport",
          value: [...selectedFarmersForExport, id],
        }),
      );
    }
  };

  const onRemove = () => {
    setOpen(false);

    setFilters({ ...filters, farmerSelected: [] });

    dispatch(
      updateUsers({
        key: "selectedFarmersForExport",
        value: [],
      }),
    );
  };

  useEffect(() => {
    getFarmers();
  }, [getFarmers]);

  return (
    <div className="flex items-center gap-x-3">
      <Popover
        trigger="click"
        open={open}
        arrow={false}
        onOpenChange={(state) => setOpen(state)}
        content={
          <div className="h-[400px] bg-white w-78 rounded-md p-3 overflow-y-scroll shadow-moreFilters">
            <div className="px-1 mb-2">
              <CustomInput
                intent="searchHome"
                placeholder="Search Farmers"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            {getFilteredFarmers()?.map((farmer) => {
              const selected = selectedFarmersForExport.includes(farmer.id);

              return (
                <button
                  type="button"
                  key={farmer.id}
                  className="py-2 flex items-center gap-x-3"
                  onClick={() => onSelect(farmer.id)}
                >
                  <Checkbox checked={selected} />
                  <p
                    className={`font-aileron font-semibold text-xs capitalize ${
                      selected ? "text-black" : "text-dark-gray"
                    }`}
                  >
                    {farmer?.name}
                  </p>
                </button>
              );
            })}
          </div>
        }
      >
        <button
          type="button"
          className="px-3 py-[11px] flex items-center gap-x-3 rounded-md bg-background-white text-dark-gray hover:bg-background"
        >
          <p className="font-poppins font-medium text-xs">
            Search and Select Farmers
          </p>

          <ChevronIcon
            className={`w-4 h-4 stroke-dark-gray ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        </button>
      </Popover>

      {selectedFarmersForExport.length !== 0 && (
        <button
          type="button"
          className="px-3 py-[11px] flex items-center gap-x-3 rounded-md bg-primary text-white mr-2"
          onClick={onRemove}
        >
          <p className="font-poppins font-medium text-xs">
            {selectedFarmersForExport.length} farmers selected
          </p>

          <CloseIcon className="w-4 h-4 stroke-white" />
        </button>
      )}
    </div>
  );
};

export default SearchAndSelectFarmers;
