/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import dayjs from 'dayjs';
import BatteryPercentage from '../../../Core/Components/BatteryPercentage';
import Button from '../../../Core/Components/CustomButton';
import CustomTooltip from '../../../Core/Components/CustomTooltip';
import VoltageIcon from '../../../Common/Svgs/VoltageIcon';
import CurrentIcon from '../../../Common/Svgs/CurrentIcon';

const average = (...props) => {
  const numbers = props.map((value) => Number(value));
  const sum = numbers.reduce((total, value) => total + value, 0);
  const averageValue = sum / props.length;
  return averageValue.toFixed(2);
};

const difference = (prop1, prop2) => {
  return (Number(prop1) - Number(prop2)).toFixed(2);
};

export const KPIData = (currentBattery) => {
  return [
    {
      id: 1,
      title: 'Sum Volt',
      image: <VoltageIcon className="text-kpi-icon" />,
      value: '80 Km/H',
      background: 'bg-kpi-background',
      count: (
        <div>
          <CustomTooltip trigger="hover" tooltipTitle="Sum Voltage">
            <div className="mt-3">
              {average(currentBattery.maxVoltage, currentBattery.minVoltage)} V
            </div>
          </CustomTooltip>
        </div>
      ),
    },
    {
      id: 2,
      title: 'Current',
      image: <CurrentIcon className="text-kpi-icon" />,
      value: '70 Km/H',
      background: 'bg-kpi-background',
      count: (
        <div>
          <CustomTooltip trigger="hover" tooltipTitle="Current">
            <div className="mt-3">{currentBattery.current} V</div>
          </CustomTooltip>
        </div>
      ),
    },
  ];
};

export const voltageData = (currentBattery) => {
  return [
    {
      id: 1,
      title: 'Max. Voltage',
      value: `${currentBattery.maxVoltage} V`,
    },
    {
      id: 2,
      title: 'Min. Voltage',
      value: `${currentBattery.minVoltage} V`,
    },
    {
      id: 3,
      title: 'Avg. Voltage',
      value: `${average(
        currentBattery.maxVoltage,
        currentBattery.minVoltage,
      )} V`,
    },
    {
      id: 4,
      title: 'Diff. in Voltage',
      value: `${difference(
        currentBattery.maxVoltage,
        currentBattery.minVoltage,
      )} V`,
    },
  ];
};

export const batteryData = (currentBattery) => {
  return [
    {
      id: 1,
      title: 'Power',
      value: `${
        average(currentBattery.maxVoltage, currentBattery.minVoltage) *
          currentBattery.current || 0
      } kW`,
    },
    {
      id: 2,
      title: 'Charge Cycles',
      value: '0',
    },
    {
      id: 3,
      title: 'Temperature',
      value: `${currentBattery.temperature} °C`,
    },
    {
      id: 4,
      title: 'Battery Strings',
      value: 20,
    },
  ];
};

export const batteryDetailsColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date & Time
      </div>
    ),
    dataIndex: 'entry_timestamp',
    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {dayjs(text).format('HH:mm:ss')}
        </div>
        <div className="text-10 font-normal font-aileron leading-3.75 text-dark-gray">
          {dayjs(text).format('DD-MM-YYYY')}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Swap Station
      </div>
    ),
    dataIndex: 'swap_station',
    // sorter: (a, b) => a.swap_station - b.swap_station,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Surveyor
      </div>
    ),
    dataIndex: 'surveyor',
    // sorter: (a, b) => a.surveyor - b.surveyor,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Energy %
      </div>
    ),
    dataIndex: 'battery_percentage',
    // sorter: (a, b) => a.battery_percentage - b.battery_percentage,
    render: (text) => (
      <BatteryPercentage percentage={text} chargingStatus={false} />
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Trike
      </div>
    ),
    dataIndex: 'trike_id',
    // sorter: (a, b) => a.trike_id - b.trike_id,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Driver
      </div>
    ),
    dataIndex: 'driver_name',
    // sorter: (a, b) => a.driver_name - b.driver_name,
    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 uppercase text-dark-gray">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: 'status',
    // sorter: (a, b) => a.status - b.status,
    render: (text) => (
      <CustomTooltip tooltipTitle={text === 1 ? 'Checked In' : 'Checked Out'}>
        <Button
          colors={text === 1 ? 'checkIn' : 'checkOut'}
          size="checkIn"
          padding="checkIn"
        >
          <img
            src={`/assets/icons/battery/${
              text === 1 ? 'check-in' : 'check-out'
            }.svg`}
            alt="status"
          />
        </Button>
      </CustomTooltip>
    ),
  },
];

// export const batteryDetailsData = [];

export const batteryDetailsData = [
  {
    id: 1,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 43,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-in',
    status: 1,
  },
  {
    id: 2,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 63,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-out',
    status: 2,
  },
  {
    id: 3,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 13,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-in',
    status: 1,
  },
  {
    id: 4,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 43,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-out',
    status: 2,
  },
  {
    id: 5,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 90,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-in',
    status: 1,
  },
  {
    id: 6,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 10,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-out',
    status: 2,
  },
  {
    id: 7,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 20,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-in',
    status: 1,
  },
  {
    id: 8,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 38,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-out',
    status: 2,
  },
  {
    id: 9,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 43,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-in',
    status: 1,
  },
  {
    id: 10,
    time: '06:20:35',
    date: '10-05-2023',
    swapStation: 'Chipinge',
    surveyor: 'Phyllis',
    batteryPercentage: 92,
    trike: 'KTWC_602R',
    driver: 'Viola Toperesu',
    driverType: 'Transport Logistics',
    checkInOutStatus: 'check-out',
    status: 2,
  },
];

export const pl = [];
