import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomRoundedImage from '../../../Core/Components/CustomRoundedImage';
import TripsIcon from '../../../Common/Svgs/TripsIcon';
import TrikeIcon from '../../../Common/Svgs/TrikeIcon';
import UserIcon from '../../../Common/Svgs/UserIcon';
import WatchIcon from '../../../Common/Svgs/WatchIcon';
import AlertIcon from '../../../Common/Svgs/AlertIcon';
import renderErrorStateForStepHeader from './renderErrorStateForStepHeader';
import getTripDays from '../../../Utils/getTripDays';

const StepItem = ({
  isCompleted = false,
  stepNumber = 1,
  icon,
  title,
  subtitle,
  label,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <CustomRoundedImage
        size="w-7.5 h-7.5"
        background={isCompleted ? 'bg-primary' : 'bg-light-grey'}
        image={
          isCompleted ? (
            icon
          ) : (
            <div className="font-semibold font-aileron text-10 text-dark-gray">
              {stepNumber}
            </div>
          )
        }
      />

      {isCompleted ? (
        <div className="flex flex-col">
          <p className="font-semibold capitalize font-aileron text-10 text-primary">
            {title}
          </p>
          <p className="font-normal font-aileron text-10 text-dark-gray">
            {subtitle}
          </p>
        </div>
      ) : (
        <div className="font-semibold font-aileron text-10 text-dark-gray">
          {label}
        </div>
      )}
    </div>
  );
};

const StepProgressLine = ({ isCompleted = false }) => {
  return (
    <div
      className={`w-[113px] h-[1px] mx-1 ${
        isCompleted ? 'bg-primary' : 'bg-light-grey'
      }`}
    />
  );
};

const StepProgress = ({ currentStep }) => {
  const { stops, tripDetails, optimisedRouteTime, calculatedRouteTime } =
    useSelector((state) => state.trips);

  return (
    <div className="flex items-center">
      <StepItem
        isCompleted={currentStep >= 2}
        stepNumber={1}
        icon={<TripsIcon size={14} className="text-yellow" />}
        title={`${stops.length} (${optimisedRouteTime || calculatedRouteTime})`}
        subtitle="Stops selected"
        label="Select Stops"
      />
      <StepProgressLine isCompleted={currentStep >= 2} />
      <StepItem
        isCompleted={currentStep >= 3}
        stepNumber={2}
        icon={<WatchIcon className="text-yellow" />}
        title={getTripDays(
          tripDetails.startDate,
          tripDetails.endDate,
          tripDetails.daysOfOccurence,
        )}
        subtitle="Days selected"
        label="Schedule Trip"
      />
      <StepProgressLine isCompleted={currentStep >= 3} />
      <StepItem
        isCompleted={currentStep >= 4}
        stepNumber={3}
        icon={<UserIcon className="w-3.5 h-3.5 stroke-yellow fill-none" />}
        title={tripDetails.driverName || null}
        subtitle="Driver selected"
        label="Select Driver"
      />
      <StepProgressLine isCompleted={currentStep >= 4} />
      <StepItem
        isCompleted={currentStep >= 4}
        stepNumber={4}
        icon={<TrikeIcon size={14} className="text-yellow" />}
        title={tripDetails.trikeRegistrationNo || null}
        subtitle={tripDetails.trikeId ? 'Trike selected' : ''}
        label="Select Trike"
      />
    </div>
  );
};

export const StepDescription = ({ step }) => {
  const { validationForSteps } = useSelector((state) => state.trips);

  const [errorStates, setErrorStates] = useState({
    showErrorIcon: false,
    backgroundColorForStep: false,
    textColorForTitle: false,
    textColorForDescription: false,
  });

  useEffect(() => {
    renderErrorStateForStepHeader(step, validationForSteps, setErrorStates);
  }, [step, validationForSteps]);

  return (
    <div className="flex items-center gap-x-2">
      <CustomRoundedImage
        size="w-7.5 h-7.5"
        background={
          errorStates.backgroundColorForStep ? 'bg-alert-red' : 'bg-light-grey'
        }
        image={
          errorStates.showErrorIcon ? (
            <AlertIcon className="text-white" />
          ) : (
            <div className="font-semibold font-aileron text-10 text-dark-gray">
              {step.id}
            </div>
          )
        }
      />
      <div>
        <p
          className={`text-base font-medium font-poppins ${
            errorStates.textColorForTitle ? 'text-alert-red' : 'text-black'
          }`}
        >
          {step.title}
        </p>
        <p
          className={`mt-1 font-normal font-aileron text-10 ${
            errorStates.textColorForDescription
              ? 'text-alert-red'
              : 'text-dark-gray'
          }`}
        >
          {step.description}
        </p>
      </div>
    </div>
  );
};

export default StepProgress;
