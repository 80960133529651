import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { SERVICE_ASSET_TYPE, SEVERITY } from "../Data";
import { renderSeverityTextColor } from "../Data/ServiceListData";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { CustomTextArea } from "../../../Core/Components/CustomInput";
import { completeServiceV3 } from "../../../Redux/APIs/servicesAPI";
import useSystemUser from "../../../Core/hooks/useSystemUser";
import { updateServices } from "../../../Redux/Slices/services.slice";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const CompleteService = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedService } = useSelector((state) => state.services);

  const hasCompleteServicePermission = usePermission([
    PERMISSIONS.SERVICE_COMPLETE,
  ]);

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      type,
      id,
      reportDescription: "",
    },
  });

  const rules = {
    required: "Required.",
  };

  const onCancel = () => {
    navigate("/service");
  };
  const onAdd = async (e) => {
    if (hasCompleteServicePermission && !selectedService.isCompleted) {
      const asset =
        selectedService.assetType === SERVICE_ASSET_TYPE.TRIKE
          ? "Trike"
          : "Battery";

      const payload = {
        serviceId: selectedService.id,
        reportDescription: e.reportDescription,
      };

      await dispatch(completeServiceV3(payload));

      dispatch(
        updateServices({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: `${asset} - Service Completed`,
              description: `Servicing of ${asset} ${selectedService.assetRegistrationNo} was completed successfully.`,
            },
          },
        }),
      );
    }

    navigate("/service");
  };

  return (
    <CustomLayout pageName="Service" nestedName={`${id}`}>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Update the service entry
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            {/* do not show complete service CTA if the selected service is already
            completed */}
            {hasCompleteServicePermission && !selectedService?.isCompleted && (
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                onClick={handleSubmit(onAdd)}
              >
                Complete Service
              </Button>
            )}
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full bg-background-white p-6">
        <div className="flex items-center justify-between w-[367px]">
          <p className="font-poppins text-xs font-medium flex-1">
            Issue Details
          </p>

          <div className="flex items-center gap-x-2 font-aileron text-xs font-normal text-dark-gray">
            <p className="capitalize">
              {useSystemUser(selectedService.submittedBy)}
            </p>
            <div className="w-[1px] h-4 bg-light-grey" />
            <p>{dayjs(selectedService.submittedAt).format("MMM DD, YYYY")}</p>
            <div className="w-[1px] h-4 bg-light-grey" />
            <p>
              {dayjs(selectedService.submittedAt).local().format("hh:MM a")}
            </p>
          </div>
        </div>

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Severity
        </p>

        <div
          className="px-3.5 py-2 rounded-md flex items-center justify-between bg-background border border-light-grey"
          style={{ width: "367px" }}
        >
          <p
            className={`font-aileron text-xs font-normal
          ${renderSeverityTextColor(selectedService.severity)}
          `}
          >
            {SEVERITY[selectedService.severity - 1]}
          </p>
          <ChevronIcon
            strokeWidth="1"
            className="w-5 h-5 stroke-dark-gray rotate-90"
          />
        </div>

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Description
        </p>

        <div
          className="px-3.5 py-2.5 rounded-md bg-background w-[367px] border border-light-grey"
          style={{ width: "367px", height: "80px" }}
        >
          <p className="text-dark-gray">{selectedService.description}</p>
        </div>

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Latitude, Longitude
        </p>

        <div
          className="px-3.5 py-2 rounded-md bg-background border border-light-grey"
          style={{ width: "367px" }}
        >
          <p className="font-aileron text-xs font-normal text-dark-gray">
            {selectedService.locationLat}, {selectedService.locationLong}
          </p>
        </div>

        <div className="mt-6 w-full h-[1px] bg-light-grey" />

        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
          Maintenance report
        </p>

        {selectedService.isCompleted ? (
          <div
            className="px-3.5 py-2 rounded-md bg-background border border-light-grey"
            style={{ width: "367px", height: "80px" }}
          >
            <p className="font-aileron text-xs font-normal text-dark-gray">
              {selectedService.reportDescription}
            </p>
          </div>
        ) : (
          <Controller
            name="reportDescription"
            control={control}
            rules={rules}
            render={({ field }) => (
              <CustomTextArea
                {...field}
                formState={formState}
                className={`w-[367px] rounded-md py-2.5 ${
                  hasCompleteServicePermission ? "bg-white" : "bg-background"
                }`}
                placeholder="Add a brief description of the repairs done..."
                disabled={!hasCompleteServicePermission}
              />
            )}
          />
        )}
      </div>
    </CustomLayout>
  );
};

export default CompleteService;
