/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomTableTopBar from "../../Core/Components/CustomTableTopBar";
import {
  inventoryColumns,
  serviceDropdownBtnArr,
} from "./Data/InventoryListData";
import useSearch from "../../Core/hooks/useSearch";
import ServiceIcon from "../../Common/Svgs/ServiceIcon";
import {
  getInventory,
  getInventoryFromODK,
} from "../../Redux/APIs/inventoryAPI";
import Button from "../../Core/Components/CustomButton";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

function InventoryList() {
  const dispatch = useDispatch();

  const { inventory } = useSelector((state) => state.inventory);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { search, setSearch, filteredData } = useSearch(inventory, ["name"]);

  const handleUpdateEntries = async () => {
    await dispatch(getInventoryFromODK());
    dispatch(getInventory());
  };

  useEffect(() => {
    dispatch(getInventory());
  }, [dispatch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Inventory">
      <div>
        <CustomTableTopBar
          module="inventory"
          searchFunc={setSearch}
          searchValue={search}
          searchPlaceholder="Search Inventory"
          dropdownBtnArr={serviceDropdownBtnArr}
          customFilters={<></>}
          showMoreFilters={false}
          showToggleButton={false}
          addButton={
            <Button
              padding="filter"
              size="filter"
              width="dynamic"
              onClick={handleUpdateEntries}
            >
              <div className="flex item-center gap-x-2">
                <img src="/assets/icons/refresh.svg" alt="" />
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  Update Entries
                </div>
              </div>
            </Button>
          }
        />
        <CustomTable
          scroll="calc(100vh - 211.8px)"
          columns={inventoryColumns}
          data={filteredData}
          emptyState={
            <ServiceIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
          }
          // rowClassName="cursor-pointer"
        />
      </div>
    </CustomLayout>
  );
}
export default InventoryList;
