/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client as axios } from '../../Utils/axiosClient';

export const getInventory = createAsyncThunk(
  'inventory/getInventory',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/odk-inventory`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getInventoryFromODK = createAsyncThunk(
  'inventory/getInventoryFromODK',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/get-inventory-from-odk`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
