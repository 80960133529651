/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React from "react";
import dayjs from "dayjs";
import LocationsModuleIcon from "../../../Common/Svgs/LocationsModuleIcon";
import ClockIcon from "../../../Common/Svgs/ClockIcon";
import CheckCircleIcon from "../../../Common/Svgs/CheckCircleIcon";
import getTripStatus from "../../../Utils/getTripStatus";
import EditAndDeleteDropDown from "../EditAndDeleteDropDown";
import TrikeTrackerSwitch from "../../../Core/Components/TrikeTrackerSwitch";
import { TRIP_STATUS } from "./TripConstants";
import TrackerTags from "../../Trikes/phase3/TrackerTags";
import CustomTooltip from "../../../Core/Components/CustomTooltip";

export const tripColumns = [
  {
    title: () => <div className="font-light font-poppins text-10">Trip ID</div>,
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => (
      <div>
        <div className="text-xs font-medium font-poppins leading-4.5 text-black">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => <div className="font-light font-poppins text-10">Driver</div>,
    dataIndex: "driverName",
    sorter: (a, b) => a.driverName.localeCompare(b.driverName),
    render: (text) => (
      <CustomTooltip
        tooltipTitle={text}
        textColor="text-white capitalize"
        placement="topLeft"
      >
        <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize whitespace-nowrap">
          {text.length > 20 ? `${text.substring(0, 20)}...` : text}
        </div>
      </CustomTooltip>
    ),
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10">Trike No.</div>
    ),
    dataIndex: "trikeRegistrationNo",
    render: (text, record) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize">
        {text ? text : record?.trikeId ? record?.trikeId : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10">No. Of Stops</div>
    ),
    dataIndex: "stops",
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize">
        {text?.length}
      </div>
    ),
  },
  {
    title: () => <div className="font-light font-poppins text-10">Date</div>,
    dataIndex: "startDate",
    render: (text) => (
      <div className="flex items-center cursor-pointer gap-x-1">
        <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize">
          {dayjs(text).format("ddd, MMM DD YYYY")}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10">Time slot</div>
    ),
    dataIndex: "timeSlot",
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10">Start Time</div>
    ),
    dataIndex: "startTime",
    render: (text) => (
      <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray capitalize">
        {text ? dayjs(text).format("hh:mm A") : "--"}
      </div>
    ),
  },
  {
    title: () => <div className="font-light font-poppins text-10">Status</div>,
    dataIndex: "statusId",
    render: (text) => <div>{getTripStatus(text)}</div>,
  },

  {
    title: () => <div className="font-light font-poppins text-10">Tracker</div>,
    dataIndex: "trikeLocationFetchSource",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {record.statusId === TRIP_STATUS.ONGOING ? (
          <TrikeTrackerSwitch
            trikeId={record.trikeId}
            trikeRegistrationNo={record.trikeRegistrationNo}
            locationFetchSource={text}
            tripId={record.id}
          />
        ) : record.statusId === TRIP_STATUS.COMPLETED ? (
          <TrackerTags locationSource={record.locationSource} />
        ) : (
          <p>--</p>
        )}
      </button>
    ),
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <EditAndDeleteDropDown
          showBg={false}
          tripId={record.id}
          detail={record}
        />
      </button>
    ),
  },
];

export const tripsDropdownBtnArr = [
  {
    id: 1,
    title: "Driver",
    renderBody: "Driver",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "drivers",
  },
  {
    id: 2,
    title: "Trike no.",
    renderBody: "Trike no.",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "trikeId",
  },
  {
    id: 3,
    title: "Status",
    renderBody: "TripStatus",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "status",
  },
];

export const KPIData = (kpiData) => {
  return [
    {
      id: 1,
      title: "Total upcoming trips",
      count: kpiData?.upcomingTrips || 0,
      image: <LocationsModuleIcon size={16} className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 2,
      title: "Total ongoing trips",
      count: kpiData?.ongoingTrips || 0,
      image: <ClockIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 3,
      title: "Total completed trips",
      count: kpiData?.completedTrips || 0,
      total: kpiData?.total || 0,
      image: <CheckCircleIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
  ];
};

export const statusFilters = [
  {
    id: 1,
    value: "Ongoing",
    className:
      "bg-yellow text-green px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 2,
    value: "Upcoming",
    className:
      "bg-green-10 text-green px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 3,
    value: "Completed",
    className:
      "bg-light-grey text-dark-gray px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
];
