import React from 'react';
import { TRIKE_STATUS } from '../Data';
import { AlertTriangleIcon } from '../../../Common/Svgs/AlertIcon';

const getTrikeStatus = (status) => {
  const currentStatus = parseInt(status, 10);

  switch (currentStatus) {
    case TRIKE_STATUS.DISPATCHED:
      return (
        <div className="bg-yellow-10 text-yellow-dark px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium">
          Dispatched
        </div>
      );
    case TRIKE_STATUS.PARKING_LOT:
      return (
        <div className="bg-light-blue-10 text-light-blue px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium">
          Parking Lot
        </div>
      );
    case TRIKE_STATUS.ON_TRIP:
      return (
        <div className="bg-green-10 text-green px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium">
          On Trip
        </div>
      );
    case TRIKE_STATUS.UN_CHECKED:
      return (
        <div className="bg-warning-10 text-warning px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium flex items-center gap-x-2">
          <AlertTriangleIcon className="stroke-warning" />
          <p>Un-Checked</p>
        </div>
      );
    case TRIKE_STATUS.OUT_OF_GEOFENCE:
      return (
        <div className="bg-alert-10 text-alert-red px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium flex items-center gap-x-2">
          <AlertTriangleIcon className="stroke-alert-red" />
          <p>Out Of Geofence</p>
        </div>
      );
    case TRIKE_STATUS.SERVICE:
      return (
        <div className="bg-light-grey text-dark-gray px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium">
          Service
        </div>
      );
    default:
      return (
        <div className="bg-green-10 text-green px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium">
          Check In
        </div>
      );
  }
};

export default getTrikeStatus;
