import React from 'react';

const CustomAvatar = ({ title }) => {
  const initial = title?.[0];

  return (
    <div
      className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-50"
      data-test-id="customAvatarDiv"
    >
      <p
        className="text-base font-medium uppercase font-poppins text-sidebar-active"
        data-test-id="customAvatarP"
      >
        {initial}
      </p>
    </div>
  );
};

export default CustomAvatar;
