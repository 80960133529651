/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Checkbox, Popover } from "antd";
import CustomInput from "./CustomInput";
import Button from "./CustomButton";
import CloseIcon from "../../Common/Svgs/CloseIcon";

const CustomSearchAndSelect = ({
  placeholder,
  inputList,
  finalList,
  setFinalList,
  onChange = () => {},
}) => {
  const [focus, setFocus] = useState(false);
  const [inputText, setInputText] = useState("");

  const handleClick = (item) => {
    const itemExists = finalList?.some((listItem) => listItem.id === item.id);
    if (itemExists) {
      const updatedList = finalList?.filter(
        (listItem) => listItem.id !== item.id,
      );
      setFinalList(updatedList);
      onChange(updatedList);
    } else {
      setFinalList([...finalList, item]);
      onChange([...finalList, item]);
    }
  };

  const removeItem = (item) => {
    const updatedList = finalList?.filter(
      (listItem) => listItem.id !== item.id,
    );
    onChange(updatedList);
    setFinalList(updatedList);
  };

  const filteredList = inputList.filter((item) => {
    if (inputText === "") {
      return true;
    }
    return item.name.toLowerCase().includes(inputText.toLowerCase());
  });

  return (
    <div>
      <div className="pt-1.5">
        <Popover
          open={focus}
          placement="bottom"
          trigger="click"
          arrow={false}
          onOpenChange={(val) => setFocus(val)}
          content={
            <div className="w-[288px] max-h-[192px] overflow-y-scroll bg-white rounded-md shadow-moreFilters mt-4">
              {filteredList.map((item) => {
                // check if the current item is selected
                const selected = finalList?.some(
                  (listItem) => listItem.id === item.id,
                );

                return (
                  <div
                    className="px-3 py-1.5 rounded-md hover:bg-background-grey"
                    key={item.id}
                    onClick={() => handleClick(item)}
                  >
                    <div
                      className="flex items-center cursor-pointer gap-x-3 group"
                      key={item.id}
                    >
                      <Checkbox checked={selected} />
                      <div className="font-poppins text-xs leading-4.5 font-medium text-dark-grey-text group-hover:text-new-black capitalize">
                        {item.name}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        >
          <CustomInput
            intent="primary"
            border={focus ? "success" : "primary"}
            placeholder={placeholder}
            // onFocus={() => setFocus(true)}
            placeholderText={
              finalList.length > 0 ? "filterSelected" : "filters"
            }
            suffix={
              <img
                src="/assets/icons/chevron-down.svg"
                alt="open"
                className={`w-4 h-4 cursor-pointer ${
                  focus ? "rotate-180" : ""
                }`}
              />
            }
            onChange={(e) => setInputText(e.target.value)}
          />
        </Popover>
      </div>

      {/* render the list with selected items */}
      {finalList?.length > 0 ? (
        <div className="flex flex-wrap gap-2 mt-6">
          {finalList?.map((item) => (
            <Button
              key={item.id}
              width="dynamic"
              size="maps"
              padding="mapMarkers"
              colors="primary"
              modifier="shadow"
            >
              <div className="flex items-center gap-x-2">
                <div>{item.name}</div>
                <CloseIcon
                  className="w-4 h-4 stroke-white"
                  onClick={() => removeItem(item)}
                />
              </div>
            </Button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CustomSearchAndSelect;
