/* eslint-disable import/prefer-default-export */
import React from "react";
import ListDropdown from "../phase3/ListDropdown";
import getLocationType from "../../../Utils/getLocationType";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";

export const locationColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "name",
    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Address
      </div>
    ),
    dataIndex: "address",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Latitude, Longitude
      </div>
    ),
    dataIndex: "id",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {record.lat}, {record.long}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Activity
      </div>
    ),
    dataIndex: "type",
    render: (text) => <div className="">{getLocationType(text)}</div>,
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    sorter: (a, b) => a?.submittedAt?.localeCompare(b?.submittedAt),
    dataIndex: "submittedAt",
    width: "200px",
    render: (text, record) => <SubmittedDetails record={record} />,
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (text) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown selectedLocationId={text} />
      </button>
    ),
  },
];
