import React from 'react';
import CustomTags from '../Core/Components/CustomTags';
import TrikeIcon from '../Common/Svgs/TrikeIcon';
import SwapStationIcon from '../Common/Svgs/SwapStationIcon';
import AssemblyStationIcon from '../Common/Svgs/AssemblyStationIcon';
import Button from '../Core/Components/CustomButton';
import SwitchIcon from '../Common/Svgs/switch';
import ServiceIcon from '../Common/Svgs/ServiceIcon';

const getBatteryStatus = (batteryStatus) => {
  switch (batteryStatus) {
    case 1:
      return (
        <CustomTags colors="inATrike" background="inATrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="stroke-yellow-dark fill-none" />
            <p>In A Trike</p>
          </div>
        </CustomTags>
      );
    case 2:
      return (
        <CustomTags colors="checkIn">
          <div className="flex items-center gap-x-2">
            <SwapStationIcon className="stroke-primary fill-none" />
            <p>Swap Station</p>
          </div>
        </CustomTags>
      );
    case 3:
      return (
        <CustomTags colors="assemblyStation">
          <div className="flex items-center gap-x-2">
            <AssemblyStationIcon className=" stroke-new-black fill-none" />
            <p>Assembly Station</p>
          </div>
        </CustomTags>
      );
    case 4:
      return (
        <CustomTags colors="batteryService">
          <div className="flex items-center gap-x-2">
            <ServiceIcon className=" stroke-new-black fill-none w-4 h-4" />
            <p>In Service</p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="inATrike" background="inATrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="stroke-yellow-dark fill-none" />
            <p>In A Trike</p>
          </div>
        </CustomTags>
      );
  }
};

export const getTrikeStatus = (buttonProps) => {
  return (
    <CustomTags
      colors={buttonProps?.colors}
      padding={buttonProps?.primary}
      size={buttonProps?.size}
    >
      <div className="flex items-center gap-x-2">{buttonProps?.children}</div>
    </CustomTags>
  );
};

export const getMobiliseStatus = (id) => {
  switch (id) {
    case 1:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="light-alert"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-[#C84630] fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Immobilise
            </div>
          </div>
        </Button>
      );
    case 2:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary-green fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );
    default:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary-green fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );
  }
};

export const getUserRole = (roleProps) => {
  return (
    <CustomTags
      colors={roleProps?.colors}
      padding={roleProps?.primary}
      size={roleProps?.size}
    >
      {roleProps?.children}
    </CustomTags>
  );
};

export default getBatteryStatus;
