// export const TRIKE_STATUS = {
//   DISPATCHED: 1,
//   PARKING_LOT: 2,
//   ON_TRIP: 3,
//   UN_CHECKED: 4,
//   OUT_OF_GEOFENCE: 5,
//   SERVICE: 6,
// };

const getCoordinatesStatus = (id) => {
  const localId = parseInt(id, 10);

  switch (localId) {
    case 1:
      return 'Dispatched';
    case 2:
      return 'Parking Lot';
    case 3:
      return 'On Trip';
    case 4:
      return 'Un-checked';
    case 5:
      return 'Out Of Geofence';
    case 6:
      return 'Service';
    default:
      return 'Check In';
  }
};

export default getCoordinatesStatus;
