import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useHeaderButtons from "./useHeaderButtons";
import Button from "../../../Core/Components/CustomButton";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const ButtonAndAddCTA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedTab } = useSelector((state) => state.users);

  // check permission for adding a driver
  const hasDriverAddPermission = usePermission([PERMISSIONS.DRIVER_ADD]);

  // check permission for adding a farmer
  const hasFarmerAddPermission = usePermission([PERMISSIONS.FARMER_ADD]);

  const headerButtons = useHeaderButtons().filter((item) => item.hasPermission);

  const onAdd = () => {
    if (selectedTab === 1) navigate("/add-driver");
    else navigate("/add-farmer");
  };

  const onTabClick = (item) => {
    dispatch(
      updateUsers({
        key: "selectedTab",
        value: item.id,
      }),
    );
  };

  return (
    <div className="px-6 py-3.5 flex items-center justify-between border-b bg-background-white">
      <div className="flex items-center gap-x-6">
        {headerButtons.map((item) => (
          <Button
            key={item.id}
            colors={selectedTab === item.id ? "primary" : "white"}
            width={item.width}
            padding={item.padding}
            modifier={selectedTab !== item.id && "shadow"}
            onClick={() => onTabClick(item)}
          >
            <div>{item.text}</div>
          </Button>
        ))}
      </div>

      {hasDriverAddPermission && selectedTab === 1 && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={onAdd}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Driver
            </p>
          </div>
        </button>
      )}

      {hasFarmerAddPermission && selectedTab === 2 && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={onAdd}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Farmer
            </p>
          </div>
        </button>
      )}
    </div>
  );
};

export default ButtonAndAddCTA;
