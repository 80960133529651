import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import useDebounce from "../../../Core/hooks/useDebounce";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import { severityFilters, typeFilters } from "../Data";
import MoreServiceFilters from "./MoreServiceFilters";
import { getServicesV3 } from "../../../Redux/APIs/servicesAPI";
import { LOCATION_TYPE } from "../../Location/Data";

const ServiceListHeader = ({ currentPage }) => {
  const dispatch = useDispatch();

  const { allLocations } = useSelector((state) => state.locations);

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [filters, setFilters] = useState({
    type: [],
    location: [],
    severity: [],
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
  });

  const debounceSearch = useDebounce(search, 500);

  const locationFilter = allLocations
    ?.filter((item) => item.type === LOCATION_TYPE.PARKING_LOT)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const onSelect = (key, value) => {
    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const applyFilters = useCallback(async () => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.type.length) filterObj.type = filters.type;
    if (filters.location.length) filterObj.location = filters.location;
    if (filters.severity.length) filterObj.severity = filters.severity;
    if (filters.dateRangeStart && filters.dateRangeEnd) {
      filterObj.dateRangeStart = filters.dateRangeStart;
      filterObj.dateRangeEnd = filters.dateRangeEnd;
    }
    if (filters.addedBy.length) {
      filterObj.addedBy = filters.addedBy.map((user) => user.id);
    }

    dispatch(getServicesV3(filterObj));
  }, [dispatch, debounceSearch, filters, currentPage]);

  useEffect(() => {
    applyFilters();
  }, [debounceSearch, filters, applyFilters]);

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Asset Number"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Type"
            identifier="type"
            contentData={typeFilters}
            onSelect={onSelect}
            selectedValues={filters.type}
          />

          <FilterItem
            label="Location"
            identifier="location"
            contentData={locationFilter}
            onSelect={onSelect}
            selectedValues={filters.location}
          />

          <FilterItem
            label="Severity"
            identifier="severity"
            customValue
            contentData={severityFilters}
            onSelect={onSelect}
            selectedValues={filters.severity}
          />

          <div className="bg-light-grey w-[1px] h-5 mx-1" />

          <MoreServiceFilters filters={filters} setFilters={setFilters} />
        </div>
      </div>
    </div>
  );
};

export default ServiceListHeader;
