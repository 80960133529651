/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { KPIData, tripColumns } from "./Data/TripsListData";
import CustomKPI from "../../Core/Components/CustomKpi";
import { getKpiData, getTripsV3 } from "../../Redux/APIs/tripsAPI";
import TripsIcon from "../../Common/Svgs/TripsIcon";
import { resetTrips, updateTrips } from "../../Redux/Slices/trips.slice";
import ToastMessageModal from "./ToastMessageModal";
import DeleteTripModal from "./TripDetails/DeleteTripModal";
import { DEFAULT_LIMIT } from "../../Utils/constants";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import TripListHeader from "./phase3/TripListHeader";
import EndTripByAdminModal from "./TripDetails/EndTripByAdminModal";
import { getAllTrikesV3 } from "../../Redux/APIs/trikesAPI";
import { getAllLocationsV3 } from "../../Redux/APIs/locationsAPI";
import TrikeTrackerModal from "../Trikes/phase3/TrikeTrackerModal";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

function TripList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    toastMessage,
    deleteTripModal,
    tripsListV3,
    currentPage,
    kpiData,
    endTripByAdminModal,
  } = useSelector((state) => state.trips);

  const { trikeTrackerModal, showToastMessage } = useSelector(
    (state) => state.trikes,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  // check if the user has permission to acccess the module
  const hasPermission = usePermission([
    PERMISSIONS.TRIP_VIEW,
    PERMISSIONS.TRIP_VIEW_AND_EDIT,
  ]);

  // check if the user has tracker permission
  const hasTrackerPermission = usePermission([PERMISSIONS.TRACKER_SWITCH]);

  const [showKpi, setShowKpi] = useState(false);
  const [columns, setColumns] = useState([...tripColumns]);

  const handleRowClick = async (record) => {
    const disableTripId = +process.env.REACT_APP_DISABLE_TRIP_ID;

    if (record.id > disableTripId && hasPermission) {
      dispatch(
        updateTrips({
          key: "currentTrip",
          value: tripsListV3.result?.find((item) => item.id === record.id),
        }),
      );

      navigate(`/trips/${record.id}`);
    }
  };

  useEffect(() => {
    dispatch(resetTrips(["currentStep"]));
    dispatch(resetTrips(["tripDetails"]));
    dispatch(resetTrips(["validationForSteps"]));
    dispatch(resetTrips(["stops"]));
    dispatch(resetTrips(["editTripDetails"]));
    dispatch(resetTrips(["calculateRouteAndDistanceVisible"]));
    dispatch(resetTrips(["calculatedRouteDistance"]));
    dispatch(resetTrips(["calculatedRouteTime"]));

    dispatch(
      getTripsV3({
        offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
      }),
    );
  }, [dispatch, currentPage]);

  // clear the trip toast modal
  useEffect(() => {
    const tripTimer = setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 10000);

    return () => clearTimeout(tripTimer);
  }, [dispatch, toastMessage]);

  // clear the trike toast modal
  useEffect(() => {
    const trikeTimer = setTimeout(() => {
      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 10000);

    return () => clearTimeout(trikeTimer);
  }, [dispatch, showToastMessage.visible]);

  // fetch all the one time data
  useEffect(() => {
    dispatch(getKpiData());
    dispatch(getAllTrikesV3());
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  // remove the tracker switch column if the permission does not exist
  useEffect(() => {
    if (!hasTrackerPermission) {
      setColumns((prev) =>
        prev.filter((item) => item.dataIndex !== "trikeLocationFetchSource"),
      );
    }
  }, [hasTrackerPermission]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Trips">
      <div className="relative">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-5.5">
          {KPIData(kpiData)?.map((i) => (
            <CustomKPI
              key={i?.id}
              image={i?.image}
              color="bg-primary"
              title={i?.title}
              count={i?.count}
              total={i?.total}
              icon={i?.icon}
              handleOnClick={i.onClick}
            />
          ))}
        </div>
        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[158px]" : ""
          }  transition-transform duration-500`}
        >
          <TripListHeader showKpi={showKpi} setShowKpi={setShowKpi} />
          <CustomTable
            // scroll={`${
            //   showKpi ? "calc(100vh - 200px)" : "calc(100vh - 362px)"
            // }`}
            module="trip-listing"
            isKpiVisible={!showKpi}
            scrollX="600px"
            scrollY={290}
            columns={columns}
            emptyState={<TripsIcon size={16} className="text-dark-gray" />}
            data={tripsListV3.result}
            onRowClick={handleRowClick}
            rowClassName={`${hasPermission ? "group cursor-pointer" : "group"}`}
            pagination={{
              pageSize: 10,
              total: tripsListV3.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                dispatch(
                  updateTrips({
                    key: "currentPage",
                    value: page,
                  }),
                );
              },
              itemRender: (current, type, originalElement) => {
                const lastPage = tripsListV3.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <ToastMessageModal
        visible={toastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: { ...toastMessage, visible: false },
            }),
          )
        }
      />
      <DeleteTripModal
        visible={deleteTripModal.visible}
        id={deleteTripModal.id}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "deleteTripModal",
              value: { ...deleteTripModal, visible: false },
            }),
          )
        }
      />

      <EndTripByAdminModal
        visible={endTripByAdminModal.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "endTripByAdminModal",
              value: {
                visible: false,
                tripId: 0,
              },
            }),
          )
        }
      />

      <TrikeTrackerModal
        visible={trikeTrackerModal.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "trikeTrackerModal",
              value: {
                visible: false,
                trikeId: 0,
                trikeRegistrationNo: "",
                currentSource: 1,
              },
            }),
          )
        }
        page="trip-listing"
        trikeCurrentPage={currentPage}
      />
    </CustomLayout>
  );
}
export default TripList;
