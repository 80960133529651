const getThemeColor = (theme) => {
  switch (theme) {
    case 'green-theme':
      return '#085054';
    case 'blue-theme':
      return '#135bba';
    case 'pink-theme':
      return '#dd6898';
    case 'purple-theme':
      return '#aa68d9';
    case 'brown-theme':
      return '#8e786c';
    case 'grey-theme':
      return '#878787';
    case 'black-theme':
      return '#3a3b3e';
    default:
      return '#085054';
  }
};

export default getThemeColor;
