/* eslint-disable no-console */
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { addBatteryV3, editBatteryV3 } from "../../../Redux/APIs/batteriesAPI";
import { batteryTypeOptions } from "../Data/BatteryListData";
import { LOCATION_TYPE } from "../../Location/Data";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddBattery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { currentBattery } = useSelector((state) => state.batteries);
  const { allLocations } = useSelector((state) => state.locations);

  const locationListForBatteries = allLocations
    ?.filter((item) => item.type === LOCATION_TYPE.PARKING_LOT)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, clearErrors } = useForm({
    defaultValues: {
      registrationNo: id ? currentBattery.registrationNo : "",
      tagNo: id ? currentBattery.tagNo : "",
      chargerType: id ? currentBattery.chargerType : "",
      locationName: id ? currentBattery.locationName : "",
      locationId: id ? currentBattery.locationId : "",
      type: id ? currentBattery.type : "",
    },
  });

  const [focus, setFocus] = useState({
    registrationNo: false,
    tagNo: false,
    chargerType: false,
    location: false,
  });

  const rules = {
    required: "Required.",
  };

  const batteryTypeError = formState?.errors?.type?.message;

  const onCancel = () => navigate("/batteries");

  const onAdd = async (e) => {
    const payload = e;
    if (id) {
      payload.batteryId = currentBattery.id;
      await dispatch(editBatteryV3(payload));
    } else {
      await dispatch(addBatteryV3(payload));
    }

    dispatch(
      updateBatteries({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: `1 Battery ${id ? "Edited" : "Added"}`,
            description: id
              ? "1 Battery was edited successfully."
              : "1 new battery was added successfully.",
          },
        },
      }),
    );
    navigate("/batteries");
  };

  return (
    <CustomLayout pageName="Batteries" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit battery" : "Add a new battery"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Edit" : "Add"} Battery
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full bg-background-white p-6 flex flex-col gap-y-6">
        <LabelAndInput
          label="Registration number"
          control={control}
          formState={formState}
          name="registrationNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.registrationNo}
          setFocus={(state) => setFocus({ ...focus, registrationNo: state })}
        />
        <LabelAndInput
          label="Tag number"
          control={control}
          formState={formState}
          name="tagNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.tagNo}
          setFocus={(state) => setFocus({ ...focus, tagNo: state })}
        />
        <LabelAndInput
          label="Charger type"
          control={control}
          formState={formState}
          name="chargerType"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.chargerType}
          setFocus={(state) => setFocus({ ...focus, chargerType: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Battery type
          </p>
          <Controller
            name="type"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                // enableSearch
                // searchPlaceholder="Search Location"
                placeholder="Select Type"
                formState={formState}
                options={batteryTypeOptions}
                onSelect={(data) => {
                  setValue("type", data.value);
                  clearErrors("type");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
              batteryTypeError ? "text-alert-red visible" : "invisible"
            }`}
          >
            {batteryTypeError}
          </p>
        </div>

        <div className="mt-3">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Location (optional)
          </p>
          <Controller
            name="locationName"
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Location"
                placeholder="Select Location"
                formState={formState}
                options={locationListForBatteries}
                onSelect={(data) => {
                  setValue("locationName", data.value);
                  setValue("locationId", data.id);
                }}
              />
            )}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddBattery;
