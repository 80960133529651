import { Table } from "antd";
import React from "react";

/**
 * @param  {array} data
 * @param  {array} columns
 * @param  {number} current
 * @param  {number} pageSize
 * @param  {function} setPageSize
 * @param  {function} setCurrent
 * @param  {string} customPadding
 * @param  {string} rowClassName
 * @param  {bool} loading
 */

export default function CustomTable({
  data,
  columns,
  scroll = "calc(100vh - 165px)",
  scrollX = 1000,
  rowClassName,
  loading,
  emptyState,
  emptyStateHeight = "h-[calc(100vh-165px)]",
  onRowClick = () => {},
  pagination = false,
  onTableChange = () => {},
  customTableClassName = "",
  isKpiVisible = false,
  module = "others",
}) {
  const locale = {
    emptyText: (
      <div className={`w-full overflow-hidden  ${emptyStateHeight}`}>
        <div className="flex flex-col gap-y-1.5 items-center justify-center w-full h-full">
          <div className="w-15.5 h-15.5 bg-background-grey rounded-full">
            <div className="flex items-center justify-center h-full">
              <div className="w-9.5 h-9.5 bg-light-grey rounded-full flex items-center justify-center">
                {emptyState}
              </div>
            </div>
          </div>
          <div className="font-light text-10 leading-3.75 font-poppins text-dark-grey-text">
            Nothing to show
          </div>
        </div>
      </div>
    ),
  };

  // maxHeightForScrollY = 415px
  const getScrollY = () => {
    if (isKpiVisible) {
      const y = window.innerHeight - scroll;
      return y < 415 ? `${y}px` : "415px";
    }

    return window.innerHeight;
  };

  // listings will have a custom scrollY
  const isListing =
    module === "trike-listing" ||
    module === "battery-listing" ||
    module === "trip-listing";

  return (
    <Table
      locale={locale}
      key={data?.id}
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      scroll={{
        y: isListing ? getScrollY() : scroll,
        // y: scroll,
        x: scrollX,
      }}
      showSorterTooltip={false}
      pagination={pagination}
      rowClassName={rowClassName}
      className={`table-shadow rounded-2xl ${
        customTableClassName || "max-w-screen"
      }`}
      loading={loading}
      onRow={(record) => {
        return {
          onClick: () => onRowClick(record),
        };
      }}
      onChange={onTableChange}
    />
  );
}
