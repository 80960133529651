import React from 'react';
import { cva } from 'class-variance-authority';

const tags = cva(
  ['font-poppins text-xs leading-4.5 whitespace-nowrap font-medium capitalize'],
  {
    variants: {
      padding: {
        primary: 'py-1 px-2',
        currentTrike: 'py-0.5 px-1',
        homeKpis: 'p-2',
      },
      size: {
        primary: ['h-6.5'],
        currentTrike: ['h-4.75'],
        stopOrigin: ['h-5'],
      },
      width: {
        dynamic: 'max-w-max',
        full: 'w-full',
        homeKpis: 'w-[177px]',
        qrCode: 'w-[112px]',
      },
      colors: {
        dispatched: 'text-primary-blue',
        checkIn: 'text-primary',
        checkOut: 'text-light-blue',
        warning: 'text-warning',
        alert: 'text-alert-red',
        service: 'text-dark-grey-text',
        homeService: 'text-dark-grey-text',
        trikeService: 'text-new-black',
        assemblyStation: 'text-new-black',
        fleetManager: 'text-user-pink',
        inATrike: 'text-yellow-dark',
        stopOrigin: 'text-white',
        collectionPoint: 'text-purple',
        deliveryPoint: 'text-user-pink',
        aggregationPoint: 'text-dark-gray',
        tripOngoing: 'text-kpi-icon',
        tripCompleted: 'text-dark-gray',
        parkingLot: 'text-light-blue',
        qrTrike: 'text-primary',
        qrBattery: 'text-yellow-dark',
        qrFarmer: 'text-user-pink',
        batteryService: 'text-black',
        stopSummary: 'text-white',
      },

      background: {
        dispatched: 'bg-blue-10',
        checkIn: 'bg-primary-10',
        checkOut: 'bg-light-blue-10 ',
        warning: 'bg-warning-bg ',
        alert: 'bg-alert-10 ',
        service: 'bg-background-grey',
        homeService: 'bg-medium-grey',
        trikeService: 'bg-light-grey',
        assemblyStation: 'bg-background',
        fleetManager: 'bg-light-pink ',
        inATrike: 'bg-yellow-10',
        stopOrigin: 'bg-source-and-destination-tag',
        collectionPoint: 'bg-purple-10',
        deliveryPoint: 'bg-alert-10',
        aggregationPoint: 'bg-background',
        tripOngoing: 'bg-kpi-background',
        tripCompleted: 'bg-light-grey',
        parkingLot: 'bg-background',
        qrTrike: 'bg-primary-10',
        qrBattery: 'bg-yellow-10',
        qrFarmer: 'bg-user-pink-10',
        batteryService: 'bg-light-grey',
        stopSummary: 'bg-primary',
      },
      borderRadius: {
        primary: 'rounded-md',
        currentTrike: 'rounded-4',
      },
    },
    defaultVariants: {
      padding: 'primary',
      size: 'primary',
      colors: 'dispatched',
      background: 'dispatched',
      width: 'dynamic',
      borderRadius: 'primary',
    },
  },
);

export default function CustomTags({
  children,
  padding,
  colors,
  size,
  width,
  borderRadius,
  testId = 'defaultCustomTags',
  ...restProps
}) {
  return (
    <div
      {...restProps}
      className={tags({
        padding,
        size,
        width,
        colors,
        borderRadius,
        background: colors,
      })}
      data-testid={testId}
    >
      {children}
    </div>
  );
}
