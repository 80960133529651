import React from 'react';

const QRCodeIcon = ({ size = 16, className = 'text-dark-gray' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.333 6V4.333c0-1.66 1.34-3 3-3H6M10 1.333h1.667c1.66 0 3 1.34 3 3V6M14.667 10.667v1c0 1.66-1.34 3-3 3h-1M6 14.667H4.333c-1.66 0-3-1.34-3-3V10M7 4.667V6c0 .667-.333 1-1 1H4.667c-.667 0-1-.333-1-1V4.667c0-.667.333-1 1-1H6c.667 0 1 .333 1 1zM12.333 4.667V6c0 .667-.333 1-1 1H10c-.667 0-1-.333-1-1V4.667c0-.667.333-1 1-1h1.333c.667 0 1 .333 1 1zM7 10v1.333c0 .667-.333 1-1 1H4.667c-.667 0-1-.333-1-1V10c0-.667.333-1 1-1H6c.667 0 1 .333 1 1zM12.333 10v1.333c0 .667-.333 1-1 1H10c-.667 0-1-.333-1-1V10c0-.667.333-1 1-1h1.333c.667 0 1 .333 1 1z"
        />
      </svg>
    </span>
  );
};

export const ActiveQRCodeIcon = ({
  size = 24,
  className = 'text-[#FAD152]',
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9M15 2h2.5C19.99 2 22 4.01 22 6.5V9M22 16v1.5c0 2.49-2.01 4.5-4.5 4.5H16M9 22H6.5C4.01 22 2 19.99 2 17.5V15"
        />
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.5 7v2c0 1-.5 1.5-1.5 1.5H7c-1 0-1.5-.5-1.5-1.5V7C5.5 6 6 5.5 7 5.5h2c1 0 1.5.5 1.5 1.5zM18.5 7v2c0 1-.5 1.5-1.5 1.5h-2c-1 0-1.5-.5-1.5-1.5V7c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5zM10.5 15v2c0 1-.5 1.5-1.5 1.5H7c-1 0-1.5-.5-1.5-1.5v-2c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5zM18.5 15v2c0 1-.5 1.5-1.5 1.5h-2c-1 0-1.5-.5-1.5-1.5v-2c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5z"
        />
      </svg>
    </span>
  );
};

export default QRCodeIcon;
