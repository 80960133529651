export const COLLECTION_ACTIVITY_ID = '1';
export const DELIVERY_ACTIVITY_ID = '2';
export const AGGREGATION_ACTIVITY_ID = '3';
export const HEADER_STEP_1 = {
  id: 1,
  title: 'Select Stops',
  description:
    'Select locations for setting up the route with collection, delivery, and charging points. You can also optimise this route for time and battery efficiency.',
};
export const HEADER_STEP_2 = {
  id: 2,
  title: 'Schedule Trip',
  description: 'Select the dates and set the recurrence for this trip.',
};
export const HEADER_STEP_3 = {
  id: 3,
  title: 'Select Driver',
  description: 'Select from your available drivers for this trip.',
};
export const HEADER_STEP_4 = {
  id: 4,
  title: 'Select Trike (Optional)',
  description: 'Select from your available trikes at the parking lot.',
};

export const TRIP_STATUS = {
  ONGOING: 1,
  UPCOMING: 2,
  COMPLETED: 3,
};
