/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import SettingsHeader from "../SettingsHeader";
import RoleHeader from "./RoleHeader";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import useSearch from "../../../Core/hooks/useSearch";
import ToastMessageModal from "../ToastMessageModal";
import RoleThreeDots from "./RoleThreeDots";
import DeleteUserRoleModal from "../Users/DeleteUserRoleModal";
import {
  getSystemRoles,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS, SYSTEM_USER_ROLE } from "../../Login/constants";

const RolesAndPermissions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { toastMessageModal, deleteUserRoleModal, systemRoles, userDetails } =
    useSelector((state) => state.loggedInUser);

  const hasCreatePermission = usePermission([PERMISSIONS.SETTINGS_ROLE_CREATE]);
  const hasEditPermission = usePermission([PERMISSIONS.SETTINGS_ROLE_EDIT]);
  const hasDeletePermission = usePermission([PERMISSIONS.SETTINGS_ROLE_DELETE]);

  const { search, setSearch, filteredData } = useSearch(systemRoles, ["name"]);

  const handleRoleClick = (role) => {
    dispatch(
      updateLoggedInUser({
        key: "currentRole",
        value: role,
      }),
    );

    navigate(`/settings/roles-and-permissions/edit-role/${role.id}`);
  };

  useEffect(() => {
    dispatch(getSystemRoles());
  }, [dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: { visible: false, title: "", description: "" },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessageModal.visible]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Settings">
      <div className="w-full h-full bg-background-white">
        <SettingsHeader />

        <div>
          <Row className="p-6">
            <Col span={6}>
              <div>
                <p className="text-xs font-medium text-black font-poppins">
                  Roles & Permissions
                </p>
                <p className="mt-2 text-xs font-normal text-dark-gray font-aileron">
                  Create user roles and define permissions from here.
                </p>
              </div>
            </Col>
            <Col span={18} className="">
              <div className="border border-light-grey rounded-lg">
                <RoleHeader search={search} setSearch={setSearch} />

                <div className="p-6 flex flex-wrap items-center gap-4">
                  {filteredData.map((role) => (
                    <div
                      role={
                        hasEditPermission &&
                        role.id !== SYSTEM_USER_ROLE &&
                        "button"
                      }
                      key={role.id}
                      className="p-6 rounded-lg border border-light-grey w-[227px] group"
                      onClick={() => {
                        // if permission exists then only redirect to role & permissions page to edit
                        // not available for system user role
                        if (hasEditPermission && role.id !== SYSTEM_USER_ROLE) {
                          handleRoleClick(role);
                        }
                      }}
                    >
                      <div className="w-full flex items-center justify-between">
                        <p className="font-aileron text-xs font-semibold capitalize">
                          {role.name}
                        </p>

                        {/* show the role option only if the create or delete permission exist */}
                        {/* not available for system user role */}
                        {(hasCreatePermission || hasDeletePermission) &&
                          role.id !== SYSTEM_USER_ROLE && (
                            <div
                              role="button"
                              className="invisible group-hover:visible"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <RoleThreeDots role={role} />
                            </div>
                          )}
                      </div>
                      <p className="mt-2 font-aileron text-xs font-semibold text-dark-gray">
                        {role.count > 1
                          ? `${role.count} users`
                          : `${role.count} user`}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>

          <DeleteUserRoleModal
            visible={deleteUserRoleModal?.visible}
            onCancel={() =>
              dispatch(
                updateLoggedInUser({
                  key: "deleteUserRoleModal",
                  value: {
                    visible: false,
                    role: null,
                  },
                }),
              )
            }
          />

          <ToastMessageModal
            visible={toastMessageModal?.visible}
            onCancel={() =>
              dispatch(
                updateLoggedInUser({
                  key: "toastMessageModal",
                  value: { visible: false, title: "", description: "" },
                }),
              )
            }
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default RolesAndPermissions;
