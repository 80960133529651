/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateLocations } from "../../../Redux/Slices/locations.slice";
import {
  deleteLocationV3,
  getLocationsV3,
} from "../../../Redux/APIs/locationsAPI";
import useLocationThreeDotsOptions from "./useLocationThreeDotsOptions";

const ListDropdown = ({ selectedLocationId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { locationsList, threeDotsPopoverId } = useSelector(
    (state) => state.locations,
  );

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useLocationThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const onOptionClick = async (optionId) => {
    switch (optionId) {
      case 1:
        dispatch(
          updateLocations({
            key: "currentLocation",
            value: locationsList?.result.find(
              (item) => item.id === selectedLocationId,
            ),
          }),
        );

        navigate(`/edit-location/${selectedLocationId}`);

        break;
      case 2:
        setOpen(false);
        await dispatch(deleteLocationV3({ locationId: selectedLocationId }));
        await dispatch(getLocationsV3());

        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateLocations({
        key: "threeDotsPopoverId",
        value: selectedLocationId,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!threeDotsOptions.length) {
    return <></>;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === selectedLocationId}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
            paddingTop: threeDotsOptions.length && "4px",
            paddingBottom: threeDotsOptions.length && "4px",
          }}
        >
          {threeDotsOptions.map((option, index) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                index === 1
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  index === 1
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
