import { createSlice } from '@reduxjs/toolkit';
import { getHomeKpi } from '../APIs/homeAPI';

const initialState = {
  loading: false,
  error: {},
  status: 'idle',
  loggedIn: false,
  filters: [],

  // phase 3
  qrCodeModal: {
    visible: false,
    data: '',
    header: '',
    type: '',
  },

  kpiData: {
    kpiData: {
      trikes: 0,
      batteries: 0,
      drivers: 0,
      inventory: 0,
    },
  },
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateHome: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    builder.addCase(getHomeKpi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeKpi.fulfilled, (state, action) => {
      state.loading = false;
      state.status = 'fulfilled';
      state.kpiData = action.payload.kpiData;
    });
    builder.addCase(getHomeKpi.rejected, (state, action) => {
      state.loading = false;
      state.status = 'rejected';
      state.error = action.error;
    });
  },
});

export const { updateHome } = homeSlice.actions;

export default homeSlice.reducer;
