/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";
// import { client } from '../../Utils/axiosClient';

export const getTrikes = createAsyncThunk(
  "trikes/getTrikes",

  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/get-trikes`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikeDetails = createAsyncThunk(
  "trikes/getTrikeDetails",

  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/trike-details`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const updateTrikeDetails = createAsyncThunk(
  'trikes/updateTrikeDetails',

  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/temp-trike-details`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikeDetailsById = createAsyncThunk(
  "trikes/getTrikeDetailsById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/trike-details-by-id`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripDetailsByTrikeId = createAsyncThunk(
  "trikes/getTripDetailsByTrikeId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/trip-details-by-id`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateGeofence = createAsyncThunk(
  "trikes/updateGeofence",
  async ({ latlngs, trikeId, isWithinGeofence }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/odk/update-geofence`,
        { latlngs, trikeId, isWithinGeofence },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getHistoricalForTrike = createAsyncThunk(
  "trikes/getHistoricalForTrike",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/historical`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// phase 3

export const getTrikesV3 = createAsyncThunk(
  "trikes/getTrikesV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/get-trikes`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addTrikeV3 = createAsyncThunk(
  "trikes/addTrikeV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/add-trike`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editTrikeV3 = createAsyncThunk(
  "trikes/editTrikeV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/trike/edit-trike`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteTrikeV3 = createAsyncThunk(
  "trikes/deleteTrikeV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/delete-trike`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikesKPI = createAsyncThunk(
  "trike/getTrikesKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/get-kpi-data`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addKnownIssues = createAsyncThunk(
  "trikes/addKnownIssues",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/add-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateKnownIssues = createAsyncThunk(
  "trikes/updateKnownIssues",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/update-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resolveKnownIssue = createAsyncThunk(
  "trikes/resolveKnownIssue",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/resolve-known-issue`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllTrikesV3 = createAsyncThunk(
  "trikes/getAllTrikesV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/get-all-trikes`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikeByIdV3 = createAsyncThunk(
  "trikes/getTrikeByIdV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/get-trike-by-id`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createChecklist = createAsyncThunk(
  "trikes/createChecklist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/create-checklist`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getChecklist = createAsyncThunk(
  "trikes/getChecklist",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trike/get-checklist`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateChecklist = createAsyncThunk(
  "trikes/updateChecklist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/update-checklist`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateGeofenceV3 = createAsyncThunk(
  "trikes/updateGeofenceV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/trike/update-geofence`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateLocationSwitchV3 = createAsyncThunk(
  "trikes/updateLocationSwitchV3",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trike/location-source`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
