import React, { useState } from "react";
import { Checkbox, Popover } from "antd";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";

const FilterItem = ({
  label,
  identifier,
  customValue = false,
  contentData = [],
  onSelect = () => null,
  selectedValues = [],
  className = "",
}) => {
  const [open, setOpen] = useState(false);

  const hasValues = selectedValues.length > 0;

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className={`bg-background-white py-1 px-3 rounded-md ${
            className || "max-h-[500px] overflow-y-scroll"
          }`}
          style={{ width: "225px" }}
        >
          {contentData.map((item) => {
            const selected = selectedValues.includes(item.id);
            return (
              <button
                key={item.id}
                type="button"
                className="py-2 flex items-center gap-x-3 w-full"
                onClick={() => onSelect(identifier, item.id)}
              >
                <Checkbox checked={selected} />
                {customValue ? (
                  <div className={item?.className}>{item.value}</div>
                ) : (
                  <p
                    className={`font-aileron font-semibold text-xs capitalize ${
                      selected ? "text-black" : "text-dark-gray"
                    }`}
                  >
                    {item.value}
                  </p>
                )}
              </button>
            );
          })}
        </div>
      }
    >
      <button
        type="button"
        className={`px-3 py-[11px] flex items-center gap-x-3 rounded-md ${
          hasValues
            ? "bg-primary text-white"
            : "bg-background-white text-dark-gray hover:bg-background"
        }`}
      >
        <p className="font-poppins font-medium text-xs">{label}</p>

        {hasValues && (
          <div className="bg-white w-4.5 h-4.5 rounded-full flex items-center justify-center">
            <p className="font-aileron font-semibold text-10 mt-[-2px] text-black">
              {selectedValues.length}
            </p>
          </div>
        )}
        <ChevronIcon
          className={`w-4 h-4 ${
            hasValues ? "stroke-white" : "stroke-dark-gray"
          } ${open ? "-rotate-90" : "rotate-90"}`}
        />
      </button>
    </Popover>
  );
};

export default FilterItem;
