/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTags from "../../../Core/Components/CustomTags";
import CollectionPointIcon from "../../../Common/Svgs/CollectionPointIcon";
import { HomeKPIInventoryIcon } from "../../../Common/Svgs/InventoryIcon";
import AggregationPointIcon from "../../../Common/Svgs/AggregationPointIcon";
import { TRIP_STATUS } from "../Data/TripConstants";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import WeightCollectedIcon from "../../../Common/Svgs/WeightCollectedIcon";
import WeightDeliveredIcon from "../../../Common/Svgs/WeightDeliveredIcon";
import SpoiltMilkIcon from "../../../Common/Svgs/SpoiltMilkIcon";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const WeightDetails = ({ currentStop, stopTag = "" }) => {
  const renderWeightCollected = () => {
    if (stopTag === "Origin") {
      return (
        <div className="flex-1">
          <div className="flex items-center">
            <WeightCollectedIcon />
            <p className="ml-2 flex-1 font-aileron text-xs font-normal text-grey-200">
              Weight Collected
            </p>
            <p className="pr-6 font-aileron text-xs font-semibold">
              {currentStop.length
                ? parseFloat(currentStop[0]?.weightCollected).toFixed(2)
                : 0}{" "}
              Kg
            </p>
          </div>
        </div>
      );
    }
    if (stopTag === "Destination") {
      return <></>;
    }
    return (
      <div className="flex-1">
        <div className="flex items-center">
          <WeightCollectedIcon />
          <p className="ml-2 flex-1 font-aileron text-xs font-normal text-grey-200">
            Weight Collected
          </p>
          <p className="pr-6 font-aileron text-xs font-semibold">
            {currentStop.length
              ? parseFloat(currentStop[0]?.weightCollected).toFixed(2)
              : 0}{" "}
            Kg
          </p>
        </div>

        <div className="mt-2 flex items-center">
          <SpoiltMilkIcon />
          <p className="ml-2 flex-1 font-aileron text-xs font-normal text-grey-200">
            Spoilt Milk
          </p>
          <p className="pr-6 font-aileron text-xs font-semibold">
            {currentStop.length
              ? parseFloat(currentStop[0]?.spoiltMilk).toFixed(2)
              : 0}{" "}
            Kg
          </p>
        </div>
      </div>
    );
  };

  const renderWeightDelivered = () => {
    if (stopTag === "Destination") {
      return (
        <div className="flex-1 flex items-center">
          <WeightDeliveredIcon />
          <p className="ml-2 flex-1 font-aileron text-xs font-normal text-grey-200">
            Weight Delivered
          </p>
          <p className="font-aileron text-xs font-semibold">
            {currentStop.length
              ? parseFloat(currentStop[0]?.weightDelivered).toFixed(2)
              : 0}{" "}
            Kg
          </p>
        </div>
      );
    }
    if (stopTag === "Origin") {
      return <></>;
    }

    return (
      <div className="flex-1 flex items-center border-l border-light-grey pl-6">
        <WeightDeliveredIcon />
        <p className="ml-2 flex-1 font-aileron text-xs font-normal text-grey-200">
          Weight Delivered
        </p>
        <p className="font-aileron text-xs font-semibold">
          {currentStop.length
            ? parseFloat(currentStop[0]?.weightDelivered).toFixed(2)
            : 0}{" "}
          Kg
        </p>
      </div>
    );
  };

  return (
    <div className="bg-primary-10 w-full py-4 px-2 mt-3 rounded-md flex items-start justify-between">
      {currentStop.length && renderWeightCollected()}
      {currentStop.length && renderWeightDelivered()}
    </div>
  );
};

const Stop = ({
  tripId,
  stopId,
  isSourceOrDestination,
  stopTag,
  stopName,
  stopAdress,
  tags,
  statusId,
  locationId,
  isOrigin = 0,
  isDestination = 0,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { overallSummaryForTrip } = useSelector((state) => state.trips);

  const hasStopPermission = usePermission([PERMISSIONS.TRIP_STOP_DETAILS]);

  const currentStop = overallSummaryForTrip?.stops?.filter(
    (item) =>
      item.stopId === locationId &&
      item.isOrigin === isOrigin &&
      item.isDestination === isDestination,
  );

  const getTagColor = (activity) => {
    switch (activity) {
      case "collection":
        return "collectionPoint";
      case "delivery":
        return "deliveryPoint";
      case "aggregation":
        return "aggregationPoint";
      default:
        return "collectionPoint";
    }
  };

  const getTagIcon = (activity) => {
    switch (activity) {
      case "collection":
        return <CollectionPointIcon />;
      case "delivery":
        return <HomeKPIInventoryIcon className="text-user-pink" />;
      case "aggregation":
        return <AggregationPointIcon />;
      default:
        return <CollectionPointIcon />;
    }
  };

  const getBackgroundColor = () => {
    if (
      statusId === TRIP_STATUS.COMPLETED &&
      currentStop?.length &&
      currentStop[0].noShow
    ) {
      return "bg-alert-10";
    }
    if (statusId === TRIP_STATUS.COMPLETED) {
      return "bg-white";
    }
    if (isSourceOrDestination) {
      return "bg-background";
    }
    return "bg-white";
  };

  const getFontColor = () => {
    if (statusId === TRIP_STATUS.UPCOMING) return "text-dark-gray";
    if (statusId === TRIP_STATUS.ONGOING)
      return "text-source-and-destination-tag";
    if (
      statusId === TRIP_STATUS.COMPLETED &&
      currentStop?.length &&
      currentStop[0].noShow
    ) {
      return "text-alert-red";
    }
    return "text-primary";
  };

  return (
    <div
      className={`w-full py-3 px-6 border-[0.5px] border-light-grey rounded-xl ${getBackgroundColor()}`}
      role={statusId === TRIP_STATUS.COMPLETED && hasStopPermission && "button"}
      onClick={() => {
        // should have stop permission + status = completed + not a no show + current stop should have data

        if (
          hasStopPermission &&
          statusId === TRIP_STATUS.COMPLETED &&
          currentStop?.length &&
          !currentStop[0].noShow
        ) {
          dispatch(
            updateTrips({
              key: "currentStop",
              value: {
                tripId,
                stopId: locationId,
                index: stopId,
                stopName,
                stopAdress,
                isOrigin,
                isDestination,
              },
            }),
          );

          navigate(`/trips/${tripId}/${stopId}`);
        }
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <p className={`text-xs font-semibold font-aileron ${getFontColor()}`}>
            Stop {stopId}
          </p>

          {isSourceOrDestination ? (
            <CustomTags
              colors={
                statusId === TRIP_STATUS.COMPLETED
                  ? "stopSummary"
                  : "stopOrigin"
              }
              size="stopOrigin"
            >
              <div className="flex items-center justify-center w-full h-full">
                <p className="font-semibold font-aileron text-10">{stopTag}</p>
              </div>
            </CustomTags>
          ) : statusId === TRIP_STATUS.COMPLETED &&
            currentStop?.length &&
            currentStop[0].noShow ? (
            <div className="bg-alert-red rounded-md py-1 px-2">
              <p className="font-aileron text-10 font-semibold text-background-white">
                No show
              </p>
            </div>
          ) : null}
        </div>

        {/* should have stop permission + status = completed + not a no show + current stop should have data */}
        {hasStopPermission &&
          statusId === TRIP_STATUS.COMPLETED &&
          currentStop?.length &&
          !currentStop[0].noShow && (
            <ChevronIcon className="stroke-dark-gray w-5 h-5" />
          )}
      </div>
      <div className="">
        <p className="mt-3 text-base font-medium text-black capitalize font-poppins">
          {stopName}
        </p>
        <p className="mt-1.5 font-semibold font-aileron text-xs capitalize text-dark-gray">
          {stopAdress}
        </p>
        {!(isSourceOrDestination || statusId === TRIP_STATUS.COMPLETED) && (
          <div className="flex items-center mt-3 gap-x-3">
            {tags?.map((tag) => (
              <CustomTags
                colors={getTagColor(tag.activity)}
                background={getTagColor(tag.activity)}
              >
                <div className="flex items-center justify-center w-full h-full gap-x-2">
                  {getTagIcon(tag.activity)}
                  <p className="text-xs font-semibold capitalize font-aileron">
                    {tag.activity} : {tag.cargo}
                  </p>
                </div>
              </CustomTags>
            ))}
          </div>
        )}
      </div>

      {statusId === TRIP_STATUS.COMPLETED &&
        currentStop?.length &&
        !currentStop[0].noShow && (
          <WeightDetails currentStop={currentStop} stopTag={stopTag} />
        )}
    </div>
  );
};

export default Stop;
