/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import useDebounce from "../../../Core/hooks/useDebounce";
import { client } from "../../../Utils/axiosClient";
import Button from "../../../Core/Components/CustomButton";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { getTripsV3 } from "../../../Redux/APIs/tripsAPI";
import { statusFilters } from "../Data/TripsListData";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { updateTrips } from "../../../Redux/Slices/trips.slice";

const TripListHeader = ({ showKpi, setShowKpi }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = usePermission([PERMISSIONS.TRIP_CREATE]);

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [trikesInSystem, setTrikesInSystem] = useState([]);
  const [driversInSystem, setDriversInSystem] = useState([]);

  const [filters, setFilters] = useState({
    drivers: [],
    trikes: [],
    status: [],
  });

  const debounceSearch = useDebounce(search, 500);

  const onSelect = (key, value) => {
    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const applyFilters = useCallback(async () => {
    dispatch(
      updateTrips({
        key: "currentPage",
        value: 1,
      }),
    );

    const filterObj = {
      offset: 0,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.drivers.length) filterObj.drivers = filters.drivers;
    if (filters.trikes.length) filterObj.trikes = filters.trikes;
    if (filters.status.length) filterObj.status = filters.status;

    dispatch(getTripsV3(filterObj));
  }, [dispatch, debounceSearch, filters]);

  const getFilterOptionsData = useCallback(async () => {
    try {
      const [trikeResponse, driverResponse] = await Promise.all([
        client.get(`${process.env.REACT_APP_API_URL}/trike/get-trikes`, {
          params: { limit: 100 },
        }),
        client.get(`${process.env.REACT_APP_API_URL}/drivers/get-drivers`, {
          params: { limit: 100 },
        }),
      ]);

      const trikes =
        trikeResponse.data.result?.map((trike) => ({
          id: trike.id,
          value: trike.registrationNo,
        })) || [];

      setTrikesInSystem([...trikes]);

      const drivers =
        driverResponse.data.result?.map((driver) => ({
          id: driver.id,
          value: `${driver.firstName} ${driver.lastName}`,
        })) || [];

      setDriversInSystem([...drivers]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    applyFilters();
  }, [debounceSearch, filters, applyFilters]);

  useEffect(() => {
    getFilterOptionsData();
  }, [getFilterOptionsData]);

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Trip ID, Driver"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Driver"
            identifier="drivers"
            contentData={driversInSystem}
            onSelect={onSelect}
            selectedValues={filters.drivers}
          />

          <FilterItem
            label="Trike No."
            identifier="trikes"
            contentData={trikesInSystem}
            onSelect={onSelect}
            selectedValues={filters.trikes}
          />

          <FilterItem
            label="Status"
            identifier="status"
            customValue
            contentData={statusFilters}
            onSelect={onSelect}
            selectedValues={filters.status}
          />
        </div>

        <div className="flex items-center gap-x-3">
          {hasPermission && (
            <button
              type="button"
              className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
              onClick={() => navigate("/trips/create-trip")}
            >
              <div className="flex item-center gap-x-2">
                <img src="/assets/icons/add.svg" alt="" />
                <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                  Create A Trip
                </p>
              </div>
            </button>
          )}

          <Button
            onClick={() => setShowKpi(!showKpi)}
            padding="arrows"
            size="arrows"
            colors="grey"
          >
            <ChevronIcon
              className={`w-4 h-4 stroke-new-black ${
                showKpi ? "rotate-90" : "-rotate-90"
              }`}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TripListHeader;
