/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  TRIKES_BATTERY_PACK,
  TRIKES_CURRENT_PAYLOAD,
  TRIKES_CURRENT_SPEED,
  TRIKES_DRIVER_NAME,
  TRIKES_ID_NUMBER,
  TRIKES_MODEL_NO,
  TRIKES_ON_OFF,
  TRIKES_STATUS,
} from "./TrikesColumnConstants";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import ServiceIcon from "../../../Common/Svgs/ServiceIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

export const trikesColumns = [
  TRIKES_ID_NUMBER,
  TRIKES_DRIVER_NAME,
  TRIKES_MODEL_NO,
  TRIKES_BATTERY_PACK,
  TRIKES_CURRENT_SPEED,
  TRIKES_STATUS,
  TRIKES_ON_OFF,
  TRIKES_CURRENT_PAYLOAD,
];

export const trikesData = [
  {
    id: 1,
    IDNumber: "IWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 40,
    currentSpeed: 24,
    status: {
      colors: "checkIn",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check In",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 2,
    IDNumber: "KTWC_602R",
    driverName: "Kevin Sambaza",
    battInPercentage: 97,
    currentSpeed: 24,
    status: {
      colors: "checkOut",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check Out",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 3,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 78,
    currentSpeed: 24,
    status: {
      colors: "dispatched",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Dispatched",
    },
    isMobilized: 3,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 4,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 24,
    currentSpeed: 24,
    status: {
      colors: "alert",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "out of geofence",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 5,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 50,
    currentSpeed: 24,
    status: {
      colors: "warning",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Un-checked",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 6,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 25,
    currentSpeed: 24,
    status: {
      colors: "trikeService",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "service",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 7,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 51,
    currentSpeed: 24,
    status: {
      colors: "checkIn",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check In",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 8,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 22,
    currentSpeed: 24,
    status: {
      colors: "warning",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Un-checked",
    },
    isMobilized: 3,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 9,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 66,
    currentSpeed: 24,
    status: {
      colors: "trikeService",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "service",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 10,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 92,
    currentSpeed: 24,
    status: {
      colors: "checkOut",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check out",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 11,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 81,
    currentSpeed: 24,
    status: {
      colors: "alert",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Out of geofence",
    },
    isMobilized: 3,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 12,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 11,
    currentSpeed: 24,
    status: {
      colors: "checkOut",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check Out",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 13,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 27,
    currentSpeed: 24,
    status: {
      colors: "warning",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Un-checked",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 14,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 33,
    currentSpeed: 24,
    status: {
      colors: "alert",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Out of geofence",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 15,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 74,
    currentSpeed: 24,
    status: {
      colors: "dispatched",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Dispatched",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 16,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 80,
    currentSpeed: 24,
    status: {
      colors: "dispatched",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Dispatched",
    },
    isMobilized: 3,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 17,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 4,
    currentSpeed: 24,
    status: {
      colors: "checkOut",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check out",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 18,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 52,
    currentSpeed: 24,
    status: {
      colors: "checkIn",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Check in",
    },
    isMobilized: 1,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
  {
    id: 19,
    IDNumber: "KTWC_602R",
    driverName: "Moses Sambaza",
    battInPercentage: 78,
    currentSpeed: 24,
    status: {
      colors: "dispatched",
      padding: "primary",
      size: "primary",
      width: "maxContent",
      children: "Dispatched",
    },
    isMobilized: 2,
    currentPayload: "500 Kg",
    totalPayload: "500 Kg",
    distance: "245 Km",
    mileage: "418 Km",
    averageSpeed: "15 Km/H",
  },
];

export const trikesDropdownBtnArr = [
  {
    id: 1,
    title: "Battery %",
    renderBody: "Battery %",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "percentage",
  },
  // {
  //   id: 2,
  //   title: 'Current Speed',
  //   renderBody: 'Current Speed',
  //   size: 'filter',
  //   color: 'white',
  //   padding: 'currentSpeed',
  // },
  {
    id: 3,
    title: "Status",
    renderBody: "TrikeStatus",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "status",
  },
  {
    id: 4,
    title: "On/Off",
    renderBody: "On/Off",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "toggleStatus",
  },
];

export const trikesMoreBtnArr = [
  {
    id: 1,
    title: "More Filters",
    size: "filter",
    color: "white",
    padding: "filter",
    icon: "sort.svg",
  },
];

export const KPIData = (kpiData) => {
  const navigate = useNavigate();

  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  return [
    {
      id: 1,
      title: "No. of Trikes dispatched",
      count: kpiData.trikesDispatch || 0,
      total: kpiData.total || 0,
      image: <TrikeIcon size={16} className="text-kpi-icon" />,
      background: "bg-kpi-background",
      onClick: () => {},
    },
    {
      id: 2,
      title: "No. of trikes with drivers",
      count: kpiData.trikesWithDrivers || 0,
      total: kpiData.total || 0,
      image: <UserIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      background: "bg-kpi-background",
      onClick: () => {},
    },
    {
      id: 3,
      title: "No. of trikes in Service",
      count: kpiData.trikesInService || 0,
      image: <ServiceIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      background: "bg-kpi-background",
      icon: (
        <img
          src="/assets/icons/chevron-down.svg"
          className="-rotate-90 "
          alt="maintenance"
        />
      ),
      onClick: () => {
        if (hasServicePermission) {
          navigate("/service");
        }
      },
    },
  ];
};
