/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../Core/Components/CustomTable";
import UserTableHeader from "./UserTableHeader";
import {
  getSystemRoles,
  getSystemUsers,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";
import ToastMessageModal from "../ToastMessageModal";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import systemUserColumns from "../Data/usersData";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import DeleteSystemUserModal from "./DeleteSystemUserModal";

const UserSettings = () => {
  const dispatch = useDispatch();

  const { systemUsers, toastMessageModal, deleteSystemUserModal, userDetails } =
    useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);

  // get system users
  useEffect(() => {
    dispatch(
      getSystemUsers({ offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT }),
    );
  }, [dispatch, currentPage]);

  // get system user roles
  useEffect(() => {
    dispatch(getSystemRoles());
  }, [dispatch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <div>
      <Row className="p-6">
        <Col span={6}>
          <div>
            <p className="text-xs font-medium text-black font-poppins">
              System Users
            </p>
            <p className="mt-2 text-xs font-normal text-dark-gray font-aileron">
              Create login credentials for system users of this platform
            </p>
          </div>
        </Col>
        <Col span={18} className="">
          <div>
            <UserTableHeader currentPage={currentPage} />

            <CustomTable
              scrollX={0}
              columns={systemUserColumns}
              data={systemUsers.result}
              rowClassName="group"
              pagination={{
                pageSize: 10,
                total: systemUsers.totalRecords,
                showSizeChanger: false,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                itemRender: (current, type, originalElement) => {
                  const lastPage = systemUsers.totalRecords / current;

                  if (type === "prev" && current === 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{ pointerEvents: "none" }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "prev" && current > 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next" && lastPage <= 10) {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next") {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  return originalElement;
                },
              }}
            />
          </div>
        </Col>
      </Row>

      <ToastMessageModal
        visible={toastMessageModal.visible}
        onCancel={() =>
          dispatch(
            updateLoggedInUser({
              key: "toastMessageModal",
              value: { ...toastMessageModal, visible: false },
            }),
          )
        }
      />

      <DeleteSystemUserModal
        visible={deleteSystemUserModal?.visible}
        onCancel={() =>
          dispatch(
            updateLoggedInUser({
              key: "deleteSystemUserModal",
              value: { visible: false, user: null },
            }),
          )
        }
      />
    </div>
  );
};

export default UserSettings;
