/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { client as axios } from "../../Utils/axiosClient";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import NotificationIcon, {
  NotificationIconWithRedDot,
} from "../../Common/Svgs/NotificationIcon";

dayjs.extend(relativeTime);
dayjs.extend(utc);

const NotificationPopup = () => {
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);
  const [notifcationSeen, setNotificationSeen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const getNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/notifications/`,
        {
          params: {
            receiverId: userDetails.userId,
          },
        },
      );

      setNotificationList(response.data.result);
    } catch (e) {
      // console.log(e);
    }
  };

  const updateSeenStatus = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/odk/update-notifications`,
        { receiverId: userDetails.userId },
      );

      setNotificationSeen(true);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    let notificationInterval;
    if (open) {
      getNotifications();
      updateSeenStatus();

      notificationInterval = setInterval(getNotifications(), 3 * 60 * 1000);
    }

    return () => clearInterval(notificationInterval);
  }, [open]);

  return (
    <Popover
      placement="bottom"
      open={open}
      onOpenChange={(val) => setOpen(val)}
      arrow={false}
      trigger={["click"]}
      content={
        <div className="w-[312px] h-[400px]">
          <div className="flex items-center justify-between px-6 pt-6 pb-2">
            <div className="text-base font-medium text-new-black font-poppins">
              Alerts
            </div>
            <CloseIcon
              className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
              onClick={() => setOpen(false)}
            />
          </div>

          {notificationList.length > 0 ? (
            <div className="mt-2  max-h-[300px] overflow-y-scroll">
              {notificationList.map((notification) => (
                <div
                  className="flex px-6 py-4 cursor-pointer gap-x-2 hover:bg-background-grey"
                  key={notification.id}
                  onClick={() => navigate(`/trikes/${notification.trike_id}`)}
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#C84630"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M12 21.41H5.94c-3.47 0-4.92-2.48-3.24-5.51l3.12-5.62L8.76 5c1.78-3.21 4.7-3.21 6.48 0l2.94 5.29 3.12 5.62c1.68 3.03.22 5.51-3.24 5.51H12v-.01zM12 9v5"
                      />
                      <path
                        stroke="#C84630"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11.995 17h.009"
                      />
                    </svg>
                  </div>

                  <div>
                    <p className="text-dark-grey text-[10px]">
                      {dayjs(notification.created_at)
                        .utc(true)
                        .local()
                        .fromNow()}
                    </p>
                    <p className="mt-2 text-xs font-light text-black ">
                      Trike No.{" "}
                      <span className="font-medium underline text-primary-blue">
                        {notification.trike_id}
                      </span>{" "}
                      has gone of its Geofence area.
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-[75%] px-2">
              <div className="flex flex-col items-center gap-y-2">
                <NotificationIcon className="ml-[-6px] fill-light-grey stroke-light-grey" />
                <p className="text-sm font-semibold font-aileron text-dark-gray">
                  No new notifications
                </p>
              </div>
            </div>
          )}
        </div>
      }
    >
      <button onClick={() => setOpen(true)} type="button">
        {notifcationSeen || notificationList.length === 0 ? (
          <NotificationIcon className="fill-none stroke-new-black" />
        ) : (
          <NotificationIconWithRedDot className="fill-none stroke-new-black" />
        )}
      </button>
    </Popover>
  );
};

export default NotificationPopup;
