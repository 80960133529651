import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import useDebounce from "../../../Core/hooks/useDebounce";
import { getSystemUsers } from "../../../Redux/APIs/systemUsersAPI";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const UserTableHeader = ({ currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = usePermission([
    PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
  ]);

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);

  const debounceSearch = useDebounce(search, 500);

  const handleSearch = useCallback(async () => {
    dispatch(
      getSystemUsers({
        offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
        searchQuery: debounceSearch,
      }),
    );
  }, [currentPage, debounceSearch, dispatch]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch, debounceSearch]);

  return (
    <div
      className="w-full bg-background-white px-6 py-2.5 flex rounded-t-md border-[#f0f0f0] justify-between items-center"
      style={{
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
      }}
    >
      <CustomInput
        intent="search"
        prefix={<img src="/assets/icons/search.svg" alt="" className="mr-2" />}
        value={search}
        focus={focus}
        border={focus > 0 ? "success" : "none"}
        placeholder={focus ? null : "Search Users"}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        suffix={
          search.length > 0 ? (
            <CloseIcon
              className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
              onClick={() => {
                setSearch("");
                setFocus(false);
              }}
            />
          ) : null
        }
      />

      {hasPermission && (
        <Button
          width="dynamic"
          padding="filter"
          onClick={() => navigate("/settings/system-users/create-user")}
        >
          <div className="flex items-center gap-x-2">
            <PlusIcon className="w-4 h-4 stroke-white" />
            <p>Create user</p>
          </div>
        </Button>
      )}
    </div>
  );
};

export default UserTableHeader;
