/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  createTrip,
  deleteTrip,
  editTrip,
  endTripByAdmin,
  getDistance,
  getKpiData,
  getOverallSummary,
  getTrikesAndDriversForTrip,
  getTripById,
  getTripDetailsKPI,
  getTrips,
  getTripsForDriverV3,
  getTripsForTrikeId,
  getTripsForTrikeV3,
  getTripsV3,
  optimiseRoute,
} from "../APIs/tripsAPI";

const initialState = {
  loading: false,
  error: null,
  allTrips: [
    {
      tripId: "--",
      driverName: "--",
      trikeId: "--",
      stops: [],
      startDate: null,
      endDate: null,
      occurence: false,
      daysOfOccurence: [],
      timeSlot: null,
      endTime: null,
      distance: null,
      time: null,
    },
  ],
  currentTrip: [],
  tripsForTrikeId: [
    {
      tripId: "--",
      driverName: "--",
      trikeId: "--",
      stops: [],
      startDate: null,
      endDate: null,
      occurence: false,
      daysOfOccurence: [],
      timeSlot: null,
      distance: null,
      time: null,
    },
  ],
  initialStop: {
    id: 1,
    location: "Stop",
    locationId: 1,
    activities: [],
  },

  stops: [
    {
      id: 1,
      location: "Stop",
      locationId: "1",
      activities: [],
    },
    {
      id: 2,
      location: "Stop",
      locationId: "1",
      activities: [],
    },
  ],
  currentRoute: [],
  optimisedRouteDistance: null,
  optimisedRouteTime: null,
  calculatedRouteDistance: null,
  calculatedRouteTime: null,
  calculateRouteAndDistanceVisible: false,
  currentStep: 1,
  tripDetails: {
    startDate: null,
    endDate: null,
    timeSlot: null,
    occurence: false,
    daysOfOccurence: [],
    driverid: "",
    driverName: "",
    trikeId: "",
    trikeRegistrationNo: "",
    statusId: 0,
    distance: null,
    time: null,
  },
  filtersForTrips: {
    drivers: [],
    trikeId: [],
    status: [],
  },
  validationForSteps: {
    step1: {
      stopLength: false,
      location: false,
      activity: [],
    },
    step2: {
      startDate: false,
      timeSlot: false,
      daysOfOccurence: false,
      endDate: false,
    },
    step3: false,
  },

  trikesForTrips: [],
  driversForTrips: [],
  deleteTripModal: {
    visible: false,
    id: null,
  },
  tripStep1Modal: false,
  cancelTripModal: false,
  optimisingRouteModal: false,
  isRouteOptimised: false,
  optimisationNotPossibleModal: false,
  missingActivityModal: {
    visible: false,
    missingActivities: [],
  },
  toastMessage: {
    visible: false,
    type: "",
    id: 2,
  },
  fullScreenMapForTrips: {
    visible: false,
    polygonCoordinates: [],
    pointColor: 2,
  },
  editTripDetails: {
    tripId: null,
    flag: false,
  },
  tripIdForListings: "",
  snapToRoadCoordinates: [],
  fastestRouteCoordinates: [],

  // phase 3
  kpiData: {
    upcomingTrips: 0,
    ongoingTrips: 0,
    completedTrips: 0,
    total: 0,
  },
  tripsListV3: {
    result: [],
    totalRecords: 0,
  },
  tripDetailsKPI: {
    stops: 0,
    distance: 0,
    totalCollected: 0,
    totalDelivered: 0,
  },
  currentPage: 1,
  tripsForTrike: [],
  tripsForDriver: [],
  currentStop: {
    id: 0,
    name: "",
    index: 0,
  },
  currentFarmer: {
    id: "",
    name: "",
    index: 0,
  },
  overallSummaryForTrip: {},
  stopSummaryForTrip: [],
  farmerSummaryForTrip: [],
  endTripByAdminModal: {
    visible: false,
    tripId: 0,
  },
};

export const tripsSlice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    updateTrips: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateMultipleTrips: (state, action) => {
      action.payload.map((item) => {
        state[item.key] = item.value;
      });
    },
    updateItemById: (state, action) => {
      const { arrayKey, itemId, updatedData } = action.payload;

      const updatedArray = state[arrayKey].map((item) => {
        if (item.id === itemId) {
          return { ...item, ...updatedData };
        }
        return item;
      });

      state[arrayKey] = updatedArray;
    },
    deleteActivityForStop: (state, action) => {
      const { itemId, activityId } = action.payload;

      const updatedArray = state.stops.map((item) => {
        if (item.id === itemId) {
          const updatedActivities = item.activities.filter(
            (activity) => activity.id !== activityId,
          );

          return { ...item, activities: updatedActivities };
        }
        return item;
      });

      state.stops = updatedArray;
    },
    updateActivityForStop: (state, action) => {
      const { newActivity, itemId } = action.payload;

      const updatedArray = state.stops.map((item) => {
        if (item.id === itemId) {
          return { ...item, activities: [...item.activities, newActivity] };
        }
        return item;
      });

      state.stops = updatedArray;
    },
    resetTrips: (state, action) => {
      action.payload.map((item) => {
        state[item] = initialState[item];
      });
    },
    deleteStop: (state, action) => {
      const { stopId } = action.payload;
      if (state.stops.length === 1) {
        state.stops = [state.initialStop];
      } else {
        const updatedArray = state.stops.filter((item) => item.id !== stopId);
        updatedArray.forEach((item, index) => {
          item.id = index + 1;
        });

        state.stops = updatedArray;
      }
    },
  },
  extraReducers(builder) {
    // create trip
    builder.addCase(createTrip.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTrip.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(createTrip.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get trip details
    builder.addCase(getTrips.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTrips.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const responseData = action.payload.map((item) => ({
        tripId: item.id.toString(),
        driverName: item.driver_name,
        trikeId: item.trike_id,
        stops: item.stops,
        startDate: item.start_date,
        endDate: item.end_date,
        occurence: item.occurence,
        daysOfOccurence: item.occurence_days,
        timeSlot: item.time_slot,
        statusId: item.status_id,
        startTime: item?.start_time,
        endTime: item?.end_time,
        distance: item?.distance,
        time: item?.time,
      }));

      state.allTrips = responseData;
    });
    builder.addCase(getTrips.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // delete trip
    builder.addCase(deleteTrip.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTrip.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteTrip.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit trip
    builder.addCase(editTrip.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editTrip.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editTrip.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // trips for trike id
    builder.addCase(getTripsForTrikeId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTripsForTrikeId.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      if (action.payload && action.payload.length > 0) {
        const responseData = action?.payload.map((item) => ({
          tripId: item.id.toString(),
          driverName: item.driver_name,
          trikeId: item.trike_id,
          stops: item.stops,
          startDate: item.start_date,
          endDate: item.end_date,
          occurence: item.occurence,
          daysOfOccurence: item.occurence_days,
          timeSlot: item.time_slot,
          statusId: item.status_id,
          distance: item?.distance,
          time: item?.time,
          startTime: item?.start_time,
          endTime: item?.end_time,
        }));

        state.tripsForTrikeId = responseData;
      } else {
        state.tripsForTrikeId = [];
      }
    });
    builder.addCase(getTripsForTrikeId.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // optimise route
    builder.addCase(optimiseRoute.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(optimiseRoute.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const {
        currentRoute,
        optimisedRoute,
        optimisedRouteDistance,
        totalRoutes,
      } = action.payload;

      if (totalRoutes > 0) {
        state.stops = optimisedRoute;
        state.currentRoute = currentRoute;
      }

      state.optimisedRouteDistance = optimisedRouteDistance;
      state.optimisationNotPossibleModal = totalRoutes === 0;
    });
    builder.addCase(optimiseRoute.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get trikes and drivers for trips

    builder.addCase(getTrikesAndDriversForTrip.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTrikesAndDriversForTrip.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const responseForTrikes = action.payload?.trikes?.map((trike, index) => ({
        id: index + 1,
        value: trike.trike_id,
      }));

      const responseForDrivers = action.payload?.drivers?.map(
        (driver, index) => ({
          id: index + 1,
          value: driver.driver_name,
        }),
      );

      state.trikesForTrips = responseForTrikes;
      state.driversForTrips = responseForDrivers;
    });
    builder.addCase(getTrikesAndDriversForTrip.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get distance for stops
    builder.addCase(getDistance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistance.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const { distance, time } = action.payload;

      state.calculatedRouteDistance = distance;
      state.calculatedRouteTime = time;
    });
    builder.addCase(getDistance.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // phase 3

    // get trips
    builder.addCase(getTripsV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTripsV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tripsListV3 = action.payload;
    });
    builder.addCase(getTripsV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // // get trip by id
    // builder.addCase(getTripById.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getTripById.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.status = "fulfilled";
    //   state.currentTrip = action.payload.currentTrip;
    // });
    // builder.addCase(getTripById.rejected, (state, { payload }) => {
    //   state.loading = false;
    //   state.status = "rejected";
    //   state.error = payload;
    // });

    // get trip by id
    builder.addCase(getTripById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTripById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      // state.tripDetails = {
      //   tripId: action.payload.id.toString(),
      //   driverName: action.payload.driver_name,
      //   trikeId: action.payload.trike_id,
      //   startDate: action.payload.start_date,
      //   endate: action.payload.end_date,
      //   occurence: action.payload.occurence,
      //   daysOfOccurence: action.payload.occurence_days,
      //   timeSlot: action.payload.time_slot,
      //   statusId: action.payload.status_id,
      //   distance: action.payload?.distance,
      //   time: action.payload?.time,
      // };
      state.tripDetails = {
        ...action.payload.tripDetails,
        id: action.payload.tripDetails.id.toString(),
      };
      state.stops = action.payload.stops;
    });
    builder.addCase(getTripById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get kpi data
    builder.addCase(getKpiData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKpiData.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpi;
    });
    builder.addCase(getKpiData.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get trips for trike id
    builder.addCase(getTripsForTrikeV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTripsForTrikeV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tripsForTrike = action.payload.trips;
    });
    builder.addCase(getTripsForTrikeV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get trips for driver
    builder.addCase(getTripsForDriverV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTripsForDriverV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tripsForDriver = action.payload.trips;
    });
    builder.addCase(getTripsForDriverV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get overall trip summary
    builder.addCase(getOverallSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOverallSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.overallSummaryForTrip = action.payload.tripSummary;
    });
    builder.addCase(getOverallSummary.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // end trip by admin
    builder.addCase(endTripByAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(endTripByAdmin.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(endTripByAdmin.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get trip details kpi
    builder.addCase(getTripDetailsKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTripDetailsKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      // console.log(action.payload, "check kpi payload");
      state.tripDetailsKPI = action.payload;
    });
    builder.addCase(getTripDetailsKPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const {
  updateTrips,
  updateMultipleTrips,
  resetTrips,
  updateItemById,
  updateActivityForStop,
  deleteActivityForStop,
  deleteStop,
} = tripsSlice.actions;

export default tripsSlice.reducer;
