/* eslint-disable eqeqeq */
import React, { useRef } from "react";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CustomMapMarker from "../../Core/Components/CustomMapMarker";
import TrikeMapMarkerDetails from "../../Core/Components/CustomMapMarkerDetails";
import getGeofencePoint from "../../Utils/getGeofencePoint";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import ZoomButton, {
  RelocateButton,
} from "../../Core/Components/MapMarkerButtons";

// import ModalBody from "../../Core/Components/Modal/GeoFenceModal/ModalBody";
// import GeoFenceMapArea from "../../Core/Components/Modal/GeoFenceModal/GeoFenceMapArea";

// const FullScreenMap = ({ id, visible, onCancel }) => {
//   const mapRef = React.useRef(null);

//   return (
//     <CustomModal visible={visible} onCancel={onCancel} width="1296px" centered>
//       <div className="w-full h-[756px] relative rounded-md overflow-hidden">
//         <GeoFenceMapArea mapRef={mapRef} />
//         <ModalBody onCancel={onCancel} mapRef={mapRef} id={id} />
//       </div>
//     </CustomModal>
//   );
// };

// export default FullScreenMap;

const renderMarkerIcon = (icon, topIcon, colors) => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <CustomMapMarker
        position="map"
        icon={icon}
        topIcon={topIcon}
        colors={colors}
      />,
    ),
  });
};

const lineOptions = {
  color: "#085054",
};

const FullScreenMap = ({ visible, onCancel }) => {
  const { currentTrike } = useSelector((state) => state.trikes);

  const [showMap, setShowMap] = React.useState(false);
  const mapRef = useRef(null);
  const initialZoomLevel = 12;

  const getAssetLatLngs = () => {
    if (currentTrike?.locationLat != 0 && currentTrike?.locationLong != 0) {
      const lat = parseFloat(currentTrike?.locationLat);
      const long = parseFloat(currentTrike?.locationLong);

      return [lat, long];
    }

    return [-0.6803741, 34.7569482];
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMap(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="1296px" centered>
      <div className="relative w-full h-[700px] overflow-hidden rounded-md">
        <div className="w-full h-full">
          <div className="absolute z-[100] top-6 right-6">
            <div className="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer abosolute bg-primary">
              <CloseIcon className="w-4 h-4 stroke-white" onClick={onCancel} />
            </div>
          </div>

          {showMap && (
            <MapContainer
              center={getAssetLatLngs()}
              zoom={initialZoomLevel}
              className="z-10 w-full h-full"
              zoomControl={false}
              ref={mapRef}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              <>
                {currentTrike?.sensorNo === null ? null : (
                  <Marker
                    position={getAssetLatLngs()}
                    icon={renderMarkerIcon("trike.svg")}
                  >
                    <Popup>
                      <TrikeMapMarkerDetails
                        assetId={currentTrike.registrationNo}
                        status="online"
                        lastActive="5 mins ago"
                        mobiliseStatus={currentTrike.isMoving}
                        trikeDetail={currentTrike}
                      />
                    </Popup>
                  </Marker>
                )}

                {currentTrike.geofence ? (
                  <Polygon
                    positions={currentTrike.geofence}
                    pathOptions={lineOptions}
                    weight={1.5}
                  />
                ) : null}

                {currentTrike.geofence
                  ? currentTrike?.geofence.map((item, index) => (
                      <Marker
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        position={item}
                        icon={getGeofencePoint()}
                      />
                    ))
                  : null}
              </>
            </MapContainer>
          )}
          <div className="absolute bottom-6 right-6 z-[100]">
            <div className="flex flex-col gap-y-3">
              <RelocateButton
                mapRef={mapRef}
                initialZoomlevel={initialZoomLevel}
                center={getAssetLatLngs()}
              />
              <ZoomButton mapRef={mapRef} />
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default FullScreenMap;
