import React from 'react';
import { cva } from 'class-variance-authority';

const mapMarker = cva(
  ['rounded-full flex items-center justify-center w-12 h-12 relative'],
  {
    variants: {
      colors: {
        activeTrike: 'bg-marker-green',
        lockedTrike: 'bg-dark-grey-text',
        parkingLot: 'bg-light-blue',
        assemblyStation: 'bg-new-black',
        swapStation: 'bg-yellow-dark',
        farm: 'bg-green',
      },
      position: {
        map: 'top-[-4px] left-[-19px]',
      },
    },

    defaultVariants: {
      colors: 'activeTrike',
      topIconColor: 'primary',
    },
  },
);

export default function CustomMapMarker({
  colors,
  position,
  topIcon,
  topIconColor = 'bg-white',
  icon,
}) {
  return (
    <div className={mapMarker({ colors, position })}>
      {topIcon ? (
        <div
          className={`absolute top-[-4px] right-[-8px] rounded-full flex items-center justify-center w-6 h-6 ${topIconColor}`}
        >
          <img src={`/assets/icons/home/${topIcon}`} alt="" />
        </div>
      ) : null}
      <img src={`/assets/icons/home/${icon}`} alt="" className="w-6 h-6" />
    </div>
  );
}
