/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import DriversList from "./phase3/DriversList";
import FarmersList from "./phase3/FarmersList";
import ViewDetailsDrawer from "./ViewDetailsDrawer";
import { updateUsers } from "../../Redux/Slices/users.slice";
import Header from "./phase3/Header";
import QRCodeModal from "../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import DeleteDriverModal from "./phase3/DeleteDriverModal";
import ToastMessageModal from "./phase3/ToastMessageModal";
import DeleteFarmerModal from "./phase3/DeleteFarmerModal";
import ExportFarmerModal from "./phase3/ExportFarmerModal";

function UsersList() {
  const dispatch = useDispatch();

  const {
    selectedTab,
    tripDetailsDrawer,
    deleteDriverModal,
    deleteFarmerModal,
    showToastMessage,
    exportFarmerModal,
  } = useSelector((state) => state.users);

  const { viewModal } = useSelector((state) => state.qrCode);

  const [driversCurrentPage, setDriversCurrentPage] = useState(1);
  const [farmersCurrentPage, setFarmersCurrentPage] = useState(1);

  // update selected tab to drivers whenever leaving the page
  useEffect(() => {
    return () => {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 1,
        }),
      );
    };
  }, [dispatch]);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateUsers({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  return (
    <CustomLayout pageName="Users">
      <Header
        driversCurrentPage={driversCurrentPage}
        farmersCurrentPage={farmersCurrentPage}
      />

      {selectedTab === 1 ? (
        <DriversList
          driversCurrentPage={driversCurrentPage}
          setDriversCurrentPage={setDriversCurrentPage}
        />
      ) : (
        <FarmersList
          farmersCurrentPage={farmersCurrentPage}
          setFarmersCurrentPage={setFarmersCurrentPage}
        />
      )}

      <div>
        <ViewDetailsDrawer
          visible={tripDetailsDrawer.visible}
          onCancel={() =>
            dispatch(
              updateUsers({
                key: "tripDetailsDrawer",
                value: {
                  visible: false,
                  driverName: "",
                },
              }),
            )
          }
        />
      </div>

      <DeleteDriverModal
        visible={deleteDriverModal.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "deleteDriverModal",
              value: {
                visible: false,
                id: 0,
              },
            }),
          )
        }
        driversCurrentPage={driversCurrentPage}
      />

      <DeleteFarmerModal
        visible={deleteFarmerModal?.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "deleteFarmerModal",
              value: {
                visible: false,
                id: 0,
              },
            }),
          )
        }
        farmersCurrentPage={farmersCurrentPage}
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <ExportFarmerModal
        visible={exportFarmerModal}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "exportFarmerModal",
              value: false,
            }),
          )
        }
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default UsersList;
