/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client as axios } from '../../Utils/axiosClient';

export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/locations/`,
      );
      // console.log(response.data, 'Locations Data');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikeLocations = createAsyncThunk(
  'batteries/getTrikeLocations',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/locations/trike`,
        {
          params,
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getGeoLocations = createAsyncThunk(
  'locations/getGeoLocations',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/odk-locations`,
      );
      // console.log(response.data, 'Locations Data');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLocationsFromODK = createAsyncThunk(
  'locations/getLocationsFromODK',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/get-locations-from-odk`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// phase3
export const getLocationsV3 = createAsyncThunk(
  'locations/getLocationsV3',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/locations/get-locations`,
        {
          params,
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addLocationV3 = createAsyncThunk(
  'locations/addLocationV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/locations/add-location`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editLocationV3 = createAsyncThunk(
  'locations/editLocationV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/locations/edit-location`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteLocationV3 = createAsyncThunk(
  'locations/deleteLocationV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/locations/delete-location`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllLocationsV3 = createAsyncThunk(
  'locations/getAllLocationsV3',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/locations/get-all-locations`,
        {
          params,
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
