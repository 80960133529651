import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useThreeDotsForFarmers = () => {
  const threeDotsForFarmers = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.FARMER_EDIT]),
    },
    {
      id: 2,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.FARMER_VIEW_QR]),
    },
    {
      id: 3,
      value: "Delete User",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.FARMER_DELETE]),
    },
  ];

  return threeDotsForFarmers;
};

export default useThreeDotsForFarmers;
