/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { getDriversV3, getFarmersV3 } from "../../../Redux/APIs/usersAPI";
import MoreDriverFilters from "./MoreDriverFilters";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import useDebounce from "../../../Core/hooks/useDebounce";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import MoreFarmerFilters from "./MoreFarmerFilters";
import SearchAndSelectFarmers from "./SearchAndSelectFarmers";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import ButtonAndAddCTA from "./ButtonAndAddCTA";

const Header = ({ driversCurrentPage, farmersCurrentPage }) => {
  const dispatch = useDispatch();

  const { allTrikes } = useSelector((state) => state.trikes);
  const { selectedTab } = useSelector((state) => state.users);

  // check permission for driver module
  const hasDriverPermission = usePermission([
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.DRIVER_VIEW_AND_EDIT,
  ]);

  // check permission for farmer module
  const hasFarmerExportPermission = usePermission([PERMISSIONS.FARMER_EXPORT]);

  const trikes = allTrikes?.map((trike) => ({
    id: trike.id,
    value: trike.registrationNo,
  }));

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [filters, setFilters] = useState({
    currentTrike: [],
    driverDateRangeStart: "",
    driverDateRangeEnd: "",
    farmerDateRangeStart: "",
    farmerDateRangeEnd: "",
    farmerSelected: [],
    addedBy: [],
  });

  const debounceSearch = useDebounce(search, 500);

  const handleTabSelection = useCallback(async () => {
    // if the driver permission exists, then select driver tab, else select farmer tab
    if (hasDriverPermission) {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 1,
        }),
      );
    } else {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 2,
        }),
      );
    }
  }, [hasDriverPermission]);

  const onSelect = (key, value) => {
    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  const applyFilters = useCallback(async () => {
    const filterObj = {};

    if (selectedTab === 1) {
      filterObj.offset = ((driversCurrentPage || 1) - 1) * DEFAULT_LIMIT;

      if (debounceSearch) filterObj.searchQuery = debounceSearch;

      if (filters.currentTrike.length)
        filterObj.currentTrike = filters.currentTrike;

      if (filters.addedBy.length) {
        filterObj.addedBy = filters.addedBy.map((user) => user.id);
      }
      if (
        filters.driverDateRangeStart !== "" &&
        filters.driverDateRangeEnd !== ""
      ) {
        filterObj.dateRangeStart = filters.driverDateRangeStart;
        filterObj.dateRangeEnd = filters.driverDateRangeEnd;
      }
      dispatch(getDriversV3(filterObj));
    } else {
      filterObj.offset = ((farmersCurrentPage || 1) - 1) * DEFAULT_LIMIT;

      if (debounceSearch) filterObj.searchQuery = debounceSearch;

      if (filters.farmerSelected.length) {
        filterObj.farmerSelected = filters.farmerSelected;
      }

      if (
        filters.farmerDateRangeStart !== "" &&
        filters.farmerDateRangeEnd !== ""
      ) {
        filterObj.dateRangeStart = filters.farmerDateRangeStart;
        filterObj.dateRangeEnd = filters.farmerDateRangeEnd;
      }

      dispatch(getFarmersV3(filterObj));
    }
  }, [dispatch, debounceSearch, filters]);

  useEffect(() => {
    applyFilters();
  }, [debounceSearch, applyFilters, filters]);

  useEffect(() => {
    setSearch("");
  }, [selectedTab]);

  useEffect(() => {
    handleTabSelection();
  }, []);

  return (
    <div className="bg-background-white">
      <ButtonAndAddCTA />

      <div className="px-6 py-3.5 flex items-center justify-between border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={
              focus
                ? null
                : selectedTab === 1
                ? "Search Drivers"
                : "Search Farmers"
            }
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />
          <div className="flex items-center">
            {selectedTab === 1 ? (
              <FilterItem
                label="Current Trike"
                identifier="currentTrike"
                contentData={trikes}
                onSelect={onSelect}
                selectedValues={filters.currentTrike}
              />
            ) : (
              <SearchAndSelectFarmers
                filters={filters}
                setFilters={setFilters}
              />
            )}

            <div className="bg-light-grey w-[1px] h-5 mx-1" />
          </div>

          {selectedTab === 1 ? (
            <MoreDriverFilters filters={filters} setFilters={setFilters} />
          ) : (
            <MoreFarmerFilters filters={filters} setFilters={setFilters} />
          )}
        </div>

        {hasFarmerExportPermission && selectedTab === 2 && (
          <button
            type="button"
            className="px-3 py-[11px] rounded-md bg-primary text-white"
            onClick={() => {
              dispatch(
                updateUsers({
                  key: "exportFarmerModal",
                  value: true,
                }),
              );
            }}
          >
            <p className="font-poppins font-medium text-xs">Export as CSV</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
