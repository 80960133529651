/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../../Core/Components/CustomDropdown";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import getActivityTag, { getActivityTagWithDelete } from "./getActivityTag";
import renderOriginDestination from "./renderOriginDestination";
import Button from "../../../Core/Components/CustomButton";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import {
  updateTrips,
  updateItemById,
  updateActivityForStop,
  deleteActivityForStop,
  deleteStop,
} from "../../../Redux/Slices/trips.slice";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import locationStops, {
  activities,
  cargoItems,
  originStops,
} from "./TripStep1Data";

const CustomTripComponent = ({
  data,
  totalLength,
  currentIndex,
  // locations,
}) => {
  const dispatch = useDispatch();

  const { stops, validationForSteps, toastMessage } = useSelector(
    (state) => state.trips,
  );

  const { allLocations } = useSelector((state) => state.locations);

  const [activity, setActivity] = useState({ key: 1 });
  const [cargo, setCargo] = useState({ key: 1 });

  const [locationDropdown, setLocationDropdown] = useState(false);
  const [activityDropdown, setActivityDropdown] = useState(false);
  const [cargoDropdown, setCargoDropdown] = useState(false);

  const handleLocationSelection = (e, id) => {
    const newLocation = {
      locationId: e.key,
      location: e.value,
    };

    if (id === 1 || id === totalLength) {
      dispatch(
        updateItemById({
          arrayKey: "stops",
          itemId: id,
          // updatedData: { [key]: e.value, locationId: e.key },
          updatedData: newLocation,
        }),
      );
      dispatch(
        updateTrips({
          key: "validationForSteps",
          value: {
            ...validationForSteps,
            step1: { ...validationForSteps.step1, location: false },
          },
        }),
      );
      setActivityDropdown(false);
    } else {
      const stopExists = stops.find((st) => st.location === e.value);

      if (stopExists) {
        dispatch(
          updateTrips({
            key: "toastMessage",
            value: {
              ...toastMessage,
              visible: true,
              type: "sameStop",
              id: stopExists.id,
            },
          }),
        );
        setActivityDropdown(false);
      } else {
        dispatch(
          updateItemById({
            arrayKey: "stops",
            itemId: id,
            // updatedData: { [key]: e.value, locationId: e.key },
            updatedData: newLocation,
          }),
        );
        dispatch(
          updateTrips({
            key: "validationForSteps",
            value: {
              ...validationForSteps,
              step1: { ...validationForSteps.step1, location: false },
            },
          }),
        );

        setActivityDropdown(true);
      }
    }
  };

  const handleActivityAndCargoSelection = (e, id) => {
    setCargo(e);

    const newId = stops[id - 1].activities.length + 1;

    if (activity.key !== 1) {
      const newActivity = {
        id: newId,
        activity: activity.value,
        activityId: activity.key,
        cargo: e.value,
        cargoId: e.key,
      };

      const existingStop = stops.find((st) => st.id === id);

      if (existingStop) {
        const existingActivity = existingStop.activities.find(
          (act) =>
            act.activity === newActivity.activity &&
            act.cargo === newActivity.cargo,
        );

        const sameCargoActivity = existingStop.activities.find(
          (act) => act.cargo === newActivity.cargo,
        );

        if (existingActivity) {
          // Activity already exists for the stop
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: {
                ...toastMessage,
                visible: true,
                type: "sameActivity",
                id,
              },
            }),
          );
        } else if (sameCargoActivity) {
          // Multiple activities for same exists for the stop
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: {
                ...toastMessage,
                visible: true,
                type: "sameCargoActivity",
                id,
              },
            }),
          );
        } else {
          // Add the new activity to the existing stop
          dispatch(
            updateActivityForStop({
              newActivity,
              itemId: id,
            }),
          );
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: {
                ...toastMessage,
                visible: true,
                type: "activityCreated",
                id,
              },
            }),
          );
          dispatch(
            updateTrips({
              key: "validationForSteps",
              value: {
                ...validationForSteps,
                step1: {
                  ...validationForSteps.step1,
                  activity: validationForSteps.step1.activity.filter(
                    (act) => act !== id,
                  ),
                },
              },
            }),
          );
          setActivity({ key: 1 });
          setCargo({ key: 1 });
        }
      }
    }
  };

  const handleActivityDeletion = (item, act) => {
    dispatch(
      deleteActivityForStop({
        itemId: item.id,
        activityId: act.id,
      }),
    );
    dispatch(
      updateTrips({
        key: "toastMessage",
        value: {
          ...toastMessage,
          visible: true,
          type: "activityDeleted",
          id: item.id,
        },
      }),
    );
  };

  const handleAddStop = () => {
    const updatedTrip = stops;

    const lastStopId = stops.length;
    const secondToLastIndex = lastStopId - 1;

    const newStop = {
      id: lastStopId,
      location: "Stop",
      locationId: lastStopId,
      activities: [],
    };

    const updatedStops = updatedTrip.map((stop, index) => ({
      ...stop,
      id: index === secondToLastIndex ? stop.id + 1 : stop.id,
    }));

    updatedStops.splice(secondToLastIndex, 0, newStop);

    dispatch(
      updateTrips({
        key: "stops",
        value: updatedStops,
      }),
    );
    dispatch(
      updateTrips({
        key: "validationForSteps",
        value: {
          ...validationForSteps,
          step1: { ...validationForSteps.step1, stopLength: false },
        },
      }),
    );
  };

  const renderBorderColorForLocation = () => {
    // if (data.id !== 1 && data.id !== totalLength) {
    if (locationDropdown) {
      return "border border-primary";
    }
    if (validationForSteps.step1.location && data.location === "Stop") {
      return "border border-alert-red";
    }
    // }
    return "border border-light-grey";
  };

  const renderBorderColorForActivity = () => {
    if (data.id !== 1 && data.id !== totalLength) {
      if (activityDropdown) {
        return "border border-primary";
      }
      if (
        validationForSteps.step1.activity.includes(data.id) &&
        activity.key === 1
      ) {
        return "border border-alert-red";
      }
    }
    return "border border-light-grey";
  };

  const renderBorderColorForCargo = () => {
    if (data.id !== 1 && data.id !== totalLength) {
      if (cargoDropdown) {
        return "border border-primary";
      }
      if (
        validationForSteps.step1.activity.includes(data.id) &&
        cargo.key === 1
      ) {
        return "border border-alert-red";
      }
    }
    return "border border-light-grey";
  };

  const isStopActive = locationDropdown || activityDropdown || cargoDropdown;

  return (
    <div className="w-[643px] mb-3">
      <div
        className={`relative p-3 border hover:border-light-grey rounded-xl group ${
          isStopActive ? "border-light-grey" : "border-transparent"
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start gap-x-2">
            <div>
              <img src="/assets/drag.svg" alt="" />
            </div>
            <div className="flex items-center justify-center w-4 h-4 font-semibold text-white rounded-full bg-yellow-dark font-aileron text-10">
              {data.id}
            </div>
            {renderOriginDestination(data.id, totalLength)}
          </div>
          {data.id === 1 || data.id === totalLength ? null : (
            <div
              onClick={() => {
                dispatch(
                  deleteStop({
                    stopId: data.id,
                  }),
                );
                dispatch(
                  updateTrips({
                    key: "validationForSteps",
                    value: {
                      ...validationForSteps,
                      step1: {
                        ...validationForSteps.step1,
                        location: false,
                        activity: [],
                      },
                    },
                  }),
                );
                setActivity({ key: 1 });
                setCargo({ key: 1 });
              }}
              className="flex items-center justify-start cursor-pointer gap-x-2"
            >
              <TrashIcon className="text-alert-red" />
              <div className="text-xs font-medium font-poppins text-alert-red">
                Delete
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <CustomDropdown
            trigger={["click"]}
            customHeight="max-h-[200px]"
            items={
              data.id === 1 || data.id === totalLength
                ? originStops(allLocations)
                : locationStops(allLocations)
            }
            dropDownOpen={locationDropdown}
            onOpenChange={(flag) => {
              setLocationDropdown(flag);
            }}
            handleClick={(e) => {
              handleLocationSelection(e, data.id);
            }}
            enableSearch
            searchPlaceholder="Search Location"
          >
            <div>
              <p className="text-xs font-normal capitalize font-aileron text-dark-gray">
                Location
              </p>
              <div
                className={`mt-1 w-full rounded-md py-2 px-3.5 flex items-center justify-between cursor-pointer ${renderBorderColorForLocation()} `}
              >
                <p className="text-xs font-semibold text-black capitalize font-aileron">
                  {data.location === "Stop" ? "Select Location" : data.location}
                </p>
                <ChevronIcon className="w-5 h-5 rotate-90 stroke-dark-gray" />
              </div>
            </div>
          </CustomDropdown>
          {
            // data.id === 1 || data.id === totalLength ? null :  -- removed because we can have multiple points for origin and dest instead of static value -- 11/12/2023
            validationForSteps.step1.location && data.location === "Stop" && (
              <p className="mt-1 text-xs font-normal font-aileron text-alert-red">
                Select a location to proceed
              </p>
            )
          }
          <div className="flex w-full mt-3 gap-x-3">
            <img src="/assets/icons/trips/big-indend.svg" alt="" />
            <div className="w-full">
              <CustomDropdown
                trigger={["click"]}
                items={activities}
                disabled={data.id === 1 || data.id === totalLength}
                dropDownOpen={activityDropdown}
                onOpenChange={(flag) => {
                  setActivityDropdown(flag);
                }}
                handleClick={(e) => {
                  setActivity({ key: e.key, value: e.value });
                  setCargoDropdown(true);
                }}
              >
                <div>
                  <p className="text-xs font-normal capitalize font-aileron text-dark-gray">
                    Activity
                  </p>
                  <div
                    className={`mt-1 w-full rounded-md border py-2 px-3.5 h-9.5 flex items-center justify-between cursor-pointer
                    ${renderBorderColorForActivity()}
                    ${
                      data.id === 1 || data.id === totalLength
                        ? "bg-new-gray"
                        : ""
                    }`}
                    //  ${
                    //   activityDropdown
                    //     ? 'border border-primary'
                    //     : 'border border-light-grey'
                    // }
                  >
                    {data.id === 1 || data.id === totalLength ? (
                      <p className="text-xs font-semibold capitalize text-dark-grey font-aileron">
                        None
                      </p>
                    ) : activity.key === 1 ? (
                      <p className="text-xs font-semibold text-black capitalize font-aileron">
                        Select an Activity
                      </p>
                    ) : (
                      getActivityTag(activity.key)
                    )}
                    <ChevronIcon
                      className={`w-5 h-5 rotate-90 stroke-dark-gray ${
                        data.id === 1 || data.id === totalLength
                          ? "stroke-light-grey"
                          : "stroke-dark-gray"
                      }`}
                    />
                  </div>
                </div>
              </CustomDropdown>
            </div>
            <div className="w-full">
              <CustomDropdown
                trigger={["click"]}
                items={cargoItems}
                disabled={data.id === 1 || data.id === totalLength}
                dropDownOpen={cargoDropdown}
                onOpenChange={(flag) => {
                  setCargoDropdown(flag);
                }}
                handleClick={(e) => {
                  handleActivityAndCargoSelection(e, data.id);
                }}
              >
                <div>
                  <p className="text-xs font-normal capitalize font-aileron text-dark-gray">
                    Cargo
                  </p>
                  <div
                    className={`mt-1 w-full rounded-md border py-2 px-3.5 h-9.5 flex items-center justify-between cursor-pointer
                    ${renderBorderColorForCargo()}
                    ${
                      data.id === 1 || data.id === totalLength
                        ? "bg-new-gray"
                        : ""
                    } `}
                    // ${
                    //   cargoDropdown
                    //     ? 'border border-primary'
                    //     : 'border border-light-grey'
                    // }
                  >
                    <div
                      className={`text-xs font-semibold text-black capitalize font-aileron ${
                        data.id === 1 || data.id === totalLength
                          ? "text-dark-grey"
                          : "text-black"
                      }`}
                    >
                      {data.id === 1 || data.id === totalLength ? (
                        <p>None</p>
                      ) : (
                        <p>
                          {cargo.key === 1
                            ? "Select A Cargo"
                            : `${cargo.value}`}
                        </p>
                      )}
                    </div>
                    <ChevronIcon
                      className={`w-5 h-5 rotate-90 ${
                        data.id === 1 || data.id === totalLength
                          ? "stroke-light-grey"
                          : "stroke-dark-gray"
                      }`}
                    />
                  </div>
                </div>
              </CustomDropdown>
            </div>
          </div>
          {data.id === 1 || data.id === totalLength
            ? null
            : validationForSteps.step1.activity.includes(data.id) && (
                <p className="mt-2 text-xs font-normal font-aileron text-alert-red">
                  Select an activity and a cargo combination to proceed
                </p>
              )}
          {data.activities.length > 0 && (
            <div className="flex items-start pl-8 mt-3 gap-x-3">
              <img src="/assets/icons/trips/small-indend.svg" alt="" />
              <div className="flex flex-wrap w-[573px] gap-3 h-auto">
                {data.activities.map((act) => (
                  <div
                    className="flex items-center gap-x-2"
                    onClick={() => handleActivityDeletion(data, act)}
                  >
                    {getActivityTagWithDelete(
                      act?.activityId?.toString(),
                      act.cargo,
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="flex items-center justify-center px-2 py-1 rounded-md bg-primary"
                  onClick={() => setActivityDropdown(true)}
                >
                  <PlusIcon className="w-4 h-4 stroke-white" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {currentIndex === totalLength - 2 ? (
        <div className="pb-3 mt-6 ml-3">
          <Button
            size="addStops"
            padding="addStops"
            width="dynamic"
            onClick={handleAddStop}
          >
            <div className="flex items-center gap-x-2">
              <PlusIcon className="w-4 h-4 stroke-white" />
              <p>Add Stop</p>
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default CustomTripComponent;
