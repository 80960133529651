import React from 'react';
import { useSelector } from 'react-redux';
import Layout from 'antd/es/layout/layout';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const CustomLayout = ({
  pageName,
  nestedName,
  children,
  hideFooter = false,
}) => {
  const { theme } = useSelector((state) => state.loggedInUser);

  return (
    <Layout className={` ${theme} flex-row w-screen h-screen overflow-hidden`}>
      <Sidebar pageName={pageName} />
      <Layout>
        <Header pageName={pageName} nestedName={nestedName} />
        <div className="w-full h-full">{children}</div>
        {hideFooter ? null : <Footer />}
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
