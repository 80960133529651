/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import HomeIcon, { ActiveHomeIcon } from "../../../Common/Svgs/HomeIcon";
import TrikeIcon, { ActiveTrikeIcon } from "../../../Common/Svgs/TrikeIcon";
import BatteryIcon, {
  ActiveBatteryIcon,
} from "../../../Common/Svgs/BatteryIcon";
import UserIcon, { ActiveUserIcon } from "../../../Common/Svgs/UserIcon";
import ServiceIcon, {
  ActiveServiceIcon,
} from "../../../Common/Svgs/ServiceIcon";
import getThemeColor from "../../../Utils/getThemeColor";
import TripsIcon, { ActiveTripsIcon } from "../../../Common/Svgs/TripsIcon";
import LocationsModuleIcon, {
  ActiveLocationsModuleIcon,
} from "../../../Common/Svgs/LocationsModuleIcon";
import InventoryIcon, {
  ActiveInventoryIcon,
} from "../../../Common/Svgs/InventoryIcon";
import QRCodeIcon, { ActiveQRCodeIcon } from "../../../Common/Svgs/QRCodeIcon";
import { PERMISSIONS } from "../../../Modules/Login/constants";
import usePermission from "../../hooks/usePermission";

const useSideBarData = (theme, navigate) => {
  const centerColor = getThemeColor(theme);

  // checking for permission and storing the boolean value in data itself
  const data = [
    {
      id: 1,
      icon: (
        <HomeIcon className="fill-none stroke-primary-50 group-hover:stroke-sidebar-active" />
      ),
      selectedIcon: (
        <ActiveHomeIcon
          className="text-sidebar-active"
          centerColor={centerColor}
        />
      ),
      title: "Home",
      hasPermission: true,
    },
    {
      id: 2,
      icon: (
        <TrikeIcon className="text-primary-50 group-hover:text-sidebar-active" />
      ),
      selectedIcon: <ActiveTrikeIcon className="text-sidebar-active" />,
      title: "Trikes",
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW,
        PERMISSIONS.TRIKE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 3,
      icon: (
        <BatteryIcon className="fill-none stroke-primary-50 group-hover:stroke-sidebar-active" />
      ),
      selectedIcon: (
        <ActiveBatteryIcon
          centerColor={centerColor}
          className="text-sidebar-active"
        />
      ),
      title: "Batteries",
      hasPermission: usePermission([
        PERMISSIONS.BATTERY_VIEW,
        PERMISSIONS.BATTERY_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 4,
      icon: (
        <TripsIcon className="text-primary-50 group-hover:text-sidebar-active" />
      ),
      selectedIcon: <ActiveTripsIcon className="text-sidebar-active" />,
      title: "Trips",
      hasPermission: usePermission([
        PERMISSIONS.TRIP_VIEW,
        PERMISSIONS.TRIP_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 5,
      icon: (
        <UserIcon className="fill-none stroke-primary-50 group-hover:stroke-sidebar-active" />
      ),
      selectedIcon: <ActiveUserIcon className="text-sidebar-active" />,
      title: "Users",
      hasPermission: usePermission([
        PERMISSIONS.DRIVER_VIEW,
        PERMISSIONS.DRIVER_VIEW_AND_EDIT,
        PERMISSIONS.FARMER_VIEW,
        PERMISSIONS.FARMER_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 6,
      icon: (
        <LocationsModuleIcon className="text-primary-50 group-hover:text-sidebar-active" />
      ),
      selectedIcon: (
        <ActiveLocationsModuleIcon
          className="text-sidebar-active"
          centerColor={centerColor}
        />
      ),
      title: "Locations",
      hasPermission: usePermission([
        PERMISSIONS.LOCATIONS_VIEW,
        PERMISSIONS.LOCATION_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 7,
      icon: (
        <InventoryIcon
          className="text-primary-50 group-hover:text-sidebar-active"
          centerColor={centerColor}
        />
      ),
      selectedIcon: <ActiveInventoryIcon className="text-sidebar-active" />,
      title: "Inventory",
      hasPermission: usePermission([PERMISSIONS.INVENTORY_VIEW]),
    },
    {
      id: 8,
      icon: (
        <ServiceIcon className="fill-none stroke-primary-50 group-hover:stroke-sidebar-active" />
      ),
      selectedIcon: <ActiveServiceIcon className="text-sidebar-active" />,
      title: "Service",
      hasPermission: usePermission([
        PERMISSIONS.SERVICE_VIEW,
        PERMISSIONS.SERVICE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 9,
      icon: (
        <QRCodeIcon
          size={24}
          className="text-primary-50 group-hover:text-sidebar-active fill-sidebar-active"
        />
      ),
      selectedIcon: <ActiveQRCodeIcon className="text-sidebar-active" />,
      title: "QR Codes",
      customNavigate: () => navigate("/qr-codes"),
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW_QR,
        PERMISSIONS.BATTERY_VIEW_QR,
        PERMISSIONS.FARMER_VIEW_QR,
      ]),
    },
  ];

  return data;
};

export default useSideBarData;
