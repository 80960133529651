import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { getLocationsV3 } from "../../../Redux/APIs/locationsAPI";
import useDebounce from "../../../Core/hooks/useDebounce";
import { LOCATION_TYPE } from "../Data";
import getLocationType from "../../../Utils/getLocationType";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const locationTypeFilter = [
  {
    id: 1,
    value: getLocationType(LOCATION_TYPE.COLLECTION),
  },
  {
    id: 2,
    value: getLocationType(LOCATION_TYPE.DELIVERY),
  },
  {
    id: 3,
    value: getLocationType(LOCATION_TYPE.AGGREGATION),
  },
  {
    id: 4,
    value: getLocationType(LOCATION_TYPE.ASSEMBLY_STATION),
  },
  {
    id: 5,
    value: getLocationType(LOCATION_TYPE.SWAP_STATION),
  },
  {
    id: 6,
    value: getLocationType(LOCATION_TYPE.PARKING_LOT),
  },
];

const LocationListHeader = ({ currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = usePermission([PERMISSIONS.LOCATION_ADD]);

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [typeFilter, setTypeFilter] = useState([]);

  const debounceSearch = useDebounce(search, 500);

  const onSelect = (key, value) => {
    if (typeFilter.includes(value)) {
      const newFilters = typeFilter.filter((item) => item !== value);
      setTypeFilter(newFilters);
    } else {
      setTypeFilter((prev) => [...prev, value]);
    }
  };

  const applyFilters = useCallback(async () => {
    const filters = {
      offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
    };

    if (debounceSearch) filters.searchQuery = debounceSearch;
    if (typeFilter.length) filters.type = typeFilter;

    dispatch(getLocationsV3(filters));
  }, [dispatch, debounceSearch, typeFilter, currentPage]);

  useEffect(() => {
    applyFilters();
  }, [debounceSearch, typeFilter, applyFilters]);

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Location"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <FilterItem
            label="Type"
            identifier="type"
            contentData={locationTypeFilter}
            onSelect={onSelect}
            selectedValues={typeFilter}
            className="max-h-max"
          />
        </div>

        {hasPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/add-location")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Add Location
              </p>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default LocationListHeader;
