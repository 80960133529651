import axios from 'axios';

const commonHeader = {
  'Content-Type': 'application/json',
  'Content-Disposition': '',
  Accept: '*/*',
  'Access-Control-Allow-Origin': window.location.origin,
  'Access-Control-Allow-Credentials': true,
};

const client = axios.create({});

const getError = ({ response }) => {
  const { data } = response;
  if (!data.code) {
    data.code = response.status;
  }
  data.statusText = response.statusText;
  return { ...data };
};

const handleLogOutUser = () => {
  localStorage.clear();
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
};

client.defaults.withCredentials = true;

const setupAxiosInterceptors = () => {
  // we can get the access of store here by which we can dispatch action's like clearing the user state

  client.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token');

      if (token) {
        commonHeader.Authorization = `Bearer ${token}`;
      }

      config.headers = commonHeader;

      return config;
    },
    (error) => {
      if (error.response) {
        error = getError(error);
      }

      Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      const { status } = error.response;

      if (status === 401) {
        handleLogOutUser();
      }

      return Promise.reject(error);
    },
  );
};

export { client, setupAxiosInterceptors, getError };
