import React from 'react';
import { LOCATION_TYPE } from '../Modules/Location/Data/index';
import CustomTags from '../Core/Components/CustomTags';
import CollectionPointIcon from '../Common/Svgs/CollectionPointIcon';
import { HomeKPIInventoryIcon } from '../Common/Svgs/InventoryIcon';
import AggregationPointIcon from '../Common/Svgs/AggregationPointIcon';
import AssemblyStationIcon from '../Common/Svgs/AssemblyStationIcon';
import SwapStationIcon from '../Common/Svgs/SwapStationIcon';
import { ParkingLotSecondaryIcon } from '../Common/Svgs/ParkingIcon';

const getLocationType = (type) => {
  switch (type) {
    case LOCATION_TYPE.COLLECTION:
      return (
        <CustomTags colors="collectionPoint" background="collectionPoint">
          <div className="flex items-center justify-center gap-x-2">
            <CollectionPointIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Collection
            </p>
          </div>
        </CustomTags>
      );
    case LOCATION_TYPE.DELIVERY:
      return (
        <CustomTags colors="deliveryPoint" background="deliveryPoint">
          <div className="flex items-center justify-center gap-x-2">
            <HomeKPIInventoryIcon className="text-user-pink" />
            <p className="text-xs font-medium capitalize font-poppins">
              Delivery
            </p>
          </div>
        </CustomTags>
      );
    case LOCATION_TYPE.AGGREGATION:
      return (
        <CustomTags colors="aggregationPoint" background="aggregationPoint">
          <div className="flex items-center justify-center gap-x-2">
            <AggregationPointIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Aggregation
            </p>
          </div>
        </CustomTags>
      );
    case LOCATION_TYPE.ASSEMBLY_STATION:
      return (
        <CustomTags colors="assemblyStation" background="assemblyStation">
          <div className="flex items-center justify-center gap-x-2">
            <AssemblyStationIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Assembly Station
            </p>
          </div>
        </CustomTags>
      );
    case LOCATION_TYPE.SWAP_STATION:
      return (
        <CustomTags colors="checkIn">
          <div className="flex items-center gap-x-2">
            <SwapStationIcon className="stroke-primary fill-none" />
            <p className="text-xs font-medium capitalize font-poppins">
              Swap Station
            </p>
          </div>
        </CustomTags>
      );
    case LOCATION_TYPE.PARKING_LOT:
      return (
        <CustomTags colors="parkingLot" background="parkingLot">
          <div className="flex items-center gap-x-2">
            <ParkingLotSecondaryIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Parking Lot
            </p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <div>
          <p>Collection</p>
        </div>
      );
  }
};

export default getLocationType;
