/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import CustomInput from "../../../Core/Components/CustomInput";
import CustomTags from "../../../Core/Components/CustomTags";
import useSearch from "../../../Core/hooks/useSearch";
import VerifyIcon, { UnverifiedIcon } from "../../../Common/Svgs/VerifyIcon";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { client } from "../../../Utils/axiosClient";
import { TRIP_STATUS } from "../Data/TripConstants";
import ToastMessageModal from "../ToastMessageModal";

const getAvailablityTag = (isAvaliable) => {
  switch (isAvaliable) {
    case 1:
      return (
        <CustomTags colors="checkIn" background="checkIn">
          <p className="text-xs font-medium font-poppins">Available</p>
        </CustomTags>
      );
    case 2:
      return (
        <CustomTags colors="warning" background="warning">
          <p className="text-xs font-medium font-poppins">Unvailable</p>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="checkIn" background="checkIn">
          <p className="text-xs font-medium font-poppins">Available</p>
        </CustomTags>
      );
  }
};

const isVerified = (driver) => {
  if (driver.licenseId !== null) {
    return <VerifyIcon />;
  }

  return <UnverifiedIcon />;
};

const TripStep3 = () => {
  const dispatch = useDispatch();

  const { tripDetails, validationForSteps, editTripDetails, toastMessage } =
    useSelector((state) => state.trips);

  const [focus, setFocus] = useState(false);
  const [driversInSystem, setDriversInSystem] = useState([]);

  const { filteredData, search, setSearch } = useSearch(driversInSystem, [
    "name",
  ]);

  const getDrivers = useCallback(async () => {
    try {
      dispatch(updateTrips({ key: "loading", value: true }));

      const response = await client.get(
        `
      ${process.env.REACT_APP_API_URL}/drivers/get-available-drivers`,
        {
          params: {
            isTripBeingEdited: editTripDetails.flag,
          },
        },
      );

      const drivers = response.data.result.map((driver) => ({
        id: driver.id,
        name: `${driver.firstName} ${driver.lastName}`,
      }));

      setDriversInSystem([...drivers]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateTrips({ key: "loading", value: false }));
    }
  }, [dispatch]);

  const handleSelection = (driver) => {
    if (editTripDetails.flag && tripDetails.statusId == TRIP_STATUS.ONGOING) {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: {
            ...toastMessage,
            visible: true,
            type: "editDriverError",
          },
        }),
      );
    } else {
      dispatch(
        updateTrips({
          key: "tripDetails",
          value: {
            ...tripDetails,
            driverName: driver.name,
            driverId: driver.id,
          },
        }),
      );
      dispatch(
        updateTrips({
          key: "validationForSteps",
          value: { ...validationForSteps, step3: false },
        }),
      );
    }
  };

  useEffect(() => {
    getDrivers();
  }, [getDrivers]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 10000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessage]);

  return (
    <div className="w-full">
      <CustomInput
        intent="searchHome"
        prefix={<img src="/assets/icons/search.svg" alt="" className="mr-2" />}
        value={search}
        focus={focus}
        border={search.length > 0 ? "success" : "none"}
        placeholderText="filterSelected"
        placeholder="Search Driver"
        onChange={(e) => setSearch(e.target.value)}
        suffix={
          search.length > 0 ? (
            <CloseIcon
              className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
              onClick={() => {
                setSearch("");
                setFocus(false);
              }}
            />
          ) : null
        }
      />
      <div className="h-[calc(100vh-261px)] mt-2 overflow-y-scroll">
        {filteredData.map((driver) => (
          <div
            className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey
             ${driver.name === tripDetails.driverName ? "bg-primary-20" : ""}`}
            key={driver.name}
            onClick={() => handleSelection(driver)}
          >
            <div className="flex items-center gap-x-3">
              <Radio checked={tripDetails.driverName === driver.name} />
              <p className="text-xs font-semibold text-black capitalize font-aileron">
                {driver.name}
              </p>
            </div>
            <div className="flex items-center gap-x-6">
              {getAvailablityTag(-1)}
              {isVerified(driver)}
            </div>
          </div>
        ))}
      </div>

      <ToastMessageModal
        visible={toastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: { ...toastMessage, visible: false },
            }),
          )
        }
      />
    </div>
  );
};
export default TripStep3;
