import React from 'react';
import LogoutIcon from '../../Common/Svgs/LogoutIcon';

const LogoutCTA = () => {
  return (
    <div className="flex items-center p-3 gap-x-3 w-50">
      <LogoutIcon />
      <div>
        <div className="text-xs font-medium font-aileron leading-4.5 text-dark-gray">
          Log Out
        </div>
      </div>
    </div>
  );
};

export default LogoutCTA;
