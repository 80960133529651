/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  addBatteryV3,
  deleteBatteryV3,
  editBatteryV3,
  getAllBatteriesV3,
  getBatteriesFromTrikes,
  getBatteryDetails,
  getBatteryDetailsODK,
  getBatterySwapsById,
  getBatteryV3,
  getKPI,
} from "../APIs/batteriesAPI";
import getDriverName from "../../Utils/getDriverName";
import getChargingStatus from "../../Utils/getChargingStatus";

const initialState = {
  loading: false,
  batteryDetails: {},
  batteryDetailsFromODK: [],
  currentBattery: [],
  filtersForBatteries: {
    percentage: [],
    drivers: [],
    status: [],
    addedBy: [],
    batteryAssociation: "",
    dateRangeStart: "",
    dateRangeEnd: "",
  },
  statusFilters: [
    {
      id: 1,
      value: "In A Trike",
    },
    {
      id: 2,
      value: "Swap Station",
    },
    {
      id: 3,
      value: "Assembly Station",
    },
  ],
  batterySwaps: [],
  filtersForBatteryDetails: {
    dateRangeStart: "",
    dateRangeEnd: "",
    swapStations: [],
    drivers: [],
    trikeIds: [],
  },
  batterySensorId: "",
  batteryShouldBeMapped: true,

  // phase 3
  allBatteries: [],
  kpiData: {
    batteriesInAssemblyStation: 0,
    batteriesInService: 0,
    batteriesInTrike: 0,
    total: 0,
  },
  batteryListV3: {
    result: [],
    totalRecords: 0,
  },
  selectedRowIds: [],
  deleteBatteryModal: false,
  showToastMessage: {
    visible: false,
    data: {},
  },
  threeDotsPopoverId: 0,
};

export const batteriesSlice = createSlice({
  name: "batteries",
  initialState,
  reducers: {
    updateBatteries: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateMultipleBatteries: (state, action) => {
      action.payload.map((item) => {
        state[item.key] = item.value;
      });
    },
    resetBatteries: (state, action) => {
      action.payload.map((item) => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBatteryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatteryDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.batteryDetails = action.payload;
    });
    builder.addCase(getBatteryDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.batteryDetails = [];
      state.error = action.error;
    });
    builder.addCase(getBatteriesFromTrikes.pending, (state) => {
      state.loading = true;
      state.batterySensorId = "";
    });
    builder.addCase(getBatteriesFromTrikes.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.batterySensorId = action.payload.result.sensor_id;
      state.batteryShouldBeMapped = action.payload.batteryShouldBeMapped;
    });
    builder.addCase(getBatteriesFromTrikes.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.batterySensorId = "";
      state.batteryShouldBeMapped = false;
      state.error = action.error;
    });

    builder.addCase(getBatteryDetailsODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatteryDetailsODK.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const { data = [], trikesState } = action.payload;

      const responseData = data.map((item) => {
        // const { battery_1, battery_2, battery_3 } = batteryDetailsFromTB;

        // const batteryCharges = [battery_1?.soc, battery_2?.soc, battery_3?.soc];

        // const batteryPercentage = item.sensor_id
        //   ? batteryCharges[0]
        //   : item.battery_percentage || 0;

        // console.log(item, 'item');

        return {
          batteryId: item.id_number || "--",
          battInPercentage: item.battery_percentage || -1,
          batteryStatus: item.battery_status_id || 3,
          associatedWith: item.trike_id || item.location || "--",
          driverName: getDriverName(item.trike_id, trikesState) || "--",
          chargingStatus: getChargingStatus(item.battery_status_id),
          sensorId: item.sensor_id,
          submittedBy: item.submitted_by,
          submittedAt: item.submitted_at || "--",
        };
      });
      state.batteryDetailsFromODK = responseData;
    });
    builder.addCase(getBatteryDetailsODK.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
    builder.addCase(getBatterySwapsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatterySwapsById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.batterySwaps = action.payload;
    });
    builder.addCase(getBatterySwapsById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // phase3

    // get battery
    builder.addCase(getBatteryV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatteryV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.batteryListV3 = action.payload;
    });
    builder.addCase(getBatteryV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add battery
    builder.addCase(addBatteryV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBatteryV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addBatteryV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit battery
    builder.addCase(editBatteryV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editBatteryV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editBatteryV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete battery
    builder.addCase(deleteBatteryV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBatteryV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteBatteryV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpi;
    });
    builder.addCase(getKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all batteries
    builder.addCase(getAllBatteriesV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBatteriesV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allBatteries = action.payload.batteries;
    });
    builder.addCase(getAllBatteriesV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateBatteries, updateMultipleBatteries, resetBatteries } =
  batteriesSlice.actions;

export default batteriesSlice.reducer;
