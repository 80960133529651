/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import UserIcon from "../../../Common/Svgs/UserIcon";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import { client } from "../../../Utils/axiosClient";
import { getSystemRoles } from "../../../Redux/APIs/systemUsersAPI";

const DeleteUserRoleModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { deleteUserRoleModal } = useSelector((state) => state.loggedInUser);

  const onConfirm = async () => {
    onCancel();

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );

      await client.post(
        `${process.env.REACT_APP_API_URL}/auth/delete-system-role`,
        {
          roleId: deleteUserRoleModal.role.id,
        },
      );

      await dispatch(getSystemRoles());

      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: {
            visible: true,
            title: "1 Role Deleted",
            description: `${deleteUserRoleModal.role.name} was deleted successfully.`,
          },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );
    }

    // dispatch(
    //   updateLoggedInUser({
    //     key: "toastMessageModal",
    //     value: {
    //       visible: true,
    //       title: "1 User Deleted",
    //       description: `${deleteSystemUserModal.user.firstName} ${deleteSystemUserModal.user.lastName} was deleted successfully.`,
    //     },
    //   }),
    // );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<UserIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete role
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this role from the system?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Please select a new role to the users already assigned to this role.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteUserRoleModal;
