/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Divider } from "antd";
import React from "react";
import dayjs from "dayjs";
import { MobiliseModalCTA } from "./Modal/MobiliseModalV3";
import { TRIKE_STATUS } from "../../Modules/Trikes/Data";
import { PERMISSIONS } from "../../Modules/Login/constants";
import usePermission from "../hooks/usePermission";

const CustomHomeListing = ({ trike, onClick }) => {
  const hasPermission = usePermission([PERMISSIONS.TRIKE_MOBILISE]);

  return (
    <div onClick={onClick} className="cursor-pointer">
      <div className="flex items-start justify-between px-6 py-4">
        <div className="cursor-pointer font-poppins">
          <div className="text-base font-medium text-black">
            {trike.registrationNo}
          </div>
          <div className="flex items-center gap-x-2 mt-1.5">
            <p className="text-xs leading-4.5 font-normal font-aileron text-dark-gray">
              {trike.status == TRIKE_STATUS.ON_TRIP ||
              trike.status == TRIKE_STATUS.OUT_OF_GEOFENCE
                ? "Online"
                : "Offline"}
            </p>
            <Divider
              type="vertical"
              className="bg-light-grey"
              style={{ margin: 0 }}
            />
            <p className="text-xs font-normal font-aileron leading-4.5 text-dark-gray">
              {trike.lastOnline === null
                ? "-"
                : dayjs(trike.lastOnline).format("hh:mm a")}
            </p>
          </div>
        </div>

        {hasPermission && (
          <div>
            <MobiliseModalCTA trike={trike} />
          </div>
        )}
      </div>
      <Divider className="m-0" />
    </div>
  );
};
export default CustomHomeListing;
