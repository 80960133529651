/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { tripData } from "../Data/TripDetailData";
import SpeedLimit from "./SpeedLimit";

const Label = ({ text }) => {
  return (
    <p className="font-normal text-10 font-aileron text-dark-gray">{text}</p>
  );
};

const Value = ({ text, redirect, onClick }) => {
  return (
    <div
      className={`flex items-center mt-2 gap-x-2 ${
        redirect && "cursor-pointer"
      }`}
      onClick={onClick}
    >
      <p className="text-xs font-semibold text-black capitalize font-aileron">
        {text}
      </p>
      {redirect ? <ChevronIcon className="w-4 h-4 stroke-dark-gray" /> : null}
    </div>
  );
};

const TripInformation = () => {
  const { currentTrip } = useSelector((state) => state.trips);
  const { currentTrike, checklist } = useSelector((state) => state.trikes);

  const checklistPassed =
    currentTrike && currentTrike.checklist
      ? currentTrike.checklist.filter((item) => item.passed === 1)
      : [];

  return (
    <div className="w-full h-full bg-white p-4 border-[0.5px] border-light-grey rounded-md">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2.5">
          <CustomRoundedImage
            background="bg-primary"
            image={<TripsIcon size={16} className="text-white" />}
          />

          <p className="text-base font-medium capitalize font-poppins">
            Trip Info
          </p>
        </div>
        <SpeedLimit />
      </div>

      <div className="flex flex-wrap mt-6 ml-12 gap-x-24 gap-y-6">
        {tripData(
          currentTrip,
          currentTrike,
          checklistPassed.length,
          checklist.length,
        ).map((item) => (
          <div className="w-[137px]" key={item.id}>
            <Label text={item.label} />

            <Value
              id={item.id}
              text={item.value}
              redirect={item.redirect}
              onClick={item?.onClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TripInformation;
