import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import Button from '../../Core/Components/CustomButton';
import CustomSearchAndSelect from '../../Core/Components/CustomSearchAndSelect';
import {
  resetBatteries,
  updateBatteries,
} from '../../Redux/Slices/batteries.slice';
import { batteriesDropdownBtnArr } from './Data/BatteryListData';
import CustomSelectWithCheckbox from '../../Core/Components/CustomSelectWithCheckbox';
import { getBatteryDetailsODK } from '../../Redux/APIs/batteriesAPI';
import ChevronIcon from '../../Common/Svgs/ChevronIcon';

const MoreBatteryFilters = ({ setShowFilters }) => {
  const dispatch = useDispatch();

  // const { trikeDetails } = useSelector((state) => state.trikes);
  const { batteryDetailsFromODK, filtersForBatteries } = useSelector(
    (state) => state.batteries,
  );

  const [finalList, setFinalList] = useState([]);
  // const [associatedWith, setAssociatedWith] = useState(null);
  // const [trikeId, setTrikeId] = useState(null);

  // const trikeIds = trikeDetails.map((item) => {
  //   return {
  //     label: (
  //       <div className="font-poppins text-xs leading-4.5 font-medium text-dark-grey-text hover:text-new-black">
  //         {item.IDNumber}
  //       </div>
  //     ),
  //     value: item.IDNumber,
  //   };
  // });

  const addedByListTemp = batteryDetailsFromODK.reduce(
    (previousValue, currentValue) => {
      const valueFound = previousValue?.find(
        (i) => i.submittedBy === currentValue.submittedBy,
      );

      if (valueFound) {
        return previousValue;
      }
      return [...previousValue, currentValue];
    },
    [],
  );

  const addedByList = addedByListTemp
    .filter((entry) => entry.submittedBy !== null)
    .map((item) => {
      return { id: item.batteryId, name: item.submittedBy };
    });

  const onCancel = () => {
    setShowFilters(false);
  };

  const handleDateRangeChange = (e) => {
    // console.log(e, 'check date e');
    if (e && e.length === 2 && e[0] && e[1]) {
      const startTimeCheck = e[0].format('YYYY-MM-DD');
      const endTimeCheck = e[1].format('YYYY-MM-DD');

      dispatch(
        updateBatteries({
          key: 'filtersForBatteries',
          value: {
            ...filtersForBatteries,
            dateRangeStart: startTimeCheck,
            dateRangeEnd: endTimeCheck,
          },
        }),
      );
    }
  };

  const hasFieldValues = () => {
    return (
      filtersForBatteries?.addedBy?.length > 0 ||
      filtersForBatteries?.batteryAssociation !== '' ||
      (filtersForBatteries?.dateRangeStart !== '' &&
        filtersForBatteries?.dateRangeEnd !== '')
    );
  };

  useEffect(() => {
    setFinalList(filtersForBatteries?.addedBy);
  }, [filtersForBatteries.addedBy]);

  // const handleAssociatedWith = (e) => {
  //   setAssociatedWith(e);

  //   dispatch(
  //     updateBatteries({
  //       key: 'filtersForBatteries',
  //       value: { ...filtersForBatteries, batteryAssociation: e },
  //     }),
  //   );
  // };

  const handleApplyFilters = () => {
    dispatch(
      getBatteryDetailsODK({
        addedBy: filtersForBatteries?.addedBy,
        batteryAssociation: filtersForBatteries?.batteryAssociation,
        dateRangeStart: filtersForBatteries?.dateRangeStart,
        dateRangeEnd: filtersForBatteries?.dateRangeEnd,
      }),
    );
    onCancel();
  };

  const handleClearAll = () => {
    setFinalList([]);
    // setAssociatedWith(null);
    // setTrikeId(null);
    dispatch(resetBatteries(['filtersForBatteries']));
  };

  useEffect(() => {
    dispatch(resetBatteries(['filtersForBatteries']));
  }, []);

  return (
    <div className="absolute right-0 z-10 p-3 bg-white rounded-md top-10 w-78 shadow-moreFilters">
      <div className="flex items-center justify-between">
        <div className="text-base font-medium text-new-black font-poppins">
          More filters
        </div>
        <CloseIcon
          className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
          onClick={onCancel}
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Date Range
      </div>
      <DatePicker.RangePicker
        value={[
          filtersForBatteries?.dateRangeStart !== '' &&
            dayjs(filtersForBatteries?.dateRangeStart),
          filtersForBatteries?.dateRangeEnd !== '' &&
            dayjs(filtersForBatteries?.dateRangeEnd),
        ]}
        format="DD MMM YYYY"
        separator={<div className="text-dark-grey-text"> - </div>}
        onCalendarChange={(e) => handleDateRangeChange(e)}
        allowClear={false}
        className="h-[39px] mt-2"
      />
      <div className="mt-6">
        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
          Added By
        </div>
        <CustomSearchAndSelect
          placeholder="Type or Select User"
          inputList={addedByList}
          finalList={finalList}
          setFinalList={setFinalList}
          onChange={(e) =>
            dispatch(
              updateBatteries({
                key: 'filtersForBatteries',
                value: { ...filtersForBatteries, addedBy: e },
              }),
            )
          }
        />
      </div>

      <div className="flex mt-6 gap-x-3">
        <Button
          size="primary"
          width="save"
          colors="grey"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <Button
          size="primary"
          width="save"
          type="submit"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

const BatteryFilters = () => {
  const dispatch = useDispatch();
  const { filtersForBatteries, statusFilters } = useSelector(
    (state) => state.batteries,
  );
  const { batteryPercentageFilters, driverNameFilters } = useSelector(
    (state) => state.trikes,
  );

  const uniqueDrivers = driverNameFilters.filter(
    (driver) => driver.value !== '--',
  );

  const functionSwitch = (e, filter) => {
    switch (filter) {
      case 'Battery %':
        return dispatch(
          updateBatteries({
            key: 'filtersForBatteries',
            value: { ...filtersForBatteries, percentage: e },
          }),
        );
      case 'Driver':
        return dispatch(
          updateBatteries({
            key: 'filtersForBatteries',
            value: { ...filtersForBatteries, drivers: e },
          }),
        );
      case 'Status':
        return dispatch(
          updateBatteries({
            key: 'filtersForBatteries',
            value: { ...filtersForBatteries, status: e },
          }),
        );
      default:
        return dispatch(
          updateBatteries({
            key: 'filtersForBatteries',
            value: { ...filtersForBatteries, percentage: e },
          }),
        );
    }
  };

  const filterSwitch = (filter) => {
    switch (filter) {
      case 'Battery %':
        return batteryPercentageFilters;
      case 'Driver':
        return uniqueDrivers;
      case 'BatteryStatus':
        return statusFilters;
      default:
        return batteryPercentageFilters;
    }
  };

  useEffect(() => {
    dispatch(resetBatteries(['filtersForBatteries']));
  }, []);

  useEffect(() => {
    dispatch(
      getBatteryDetailsODK({
        batteryPercentageRange: filtersForBatteries?.percentage,
        driverName: filtersForBatteries?.drivers,
        status: filtersForBatteries?.status,
      }),
    );
  }, [
    filtersForBatteries.percentage,
    filtersForBatteries.drivers,
    filtersForBatteries.status,
  ]);

  return (
    <div className="flex items-center justify-start gap-x-3">
      {batteriesDropdownBtnArr?.map((item) => (
        <CustomSelectWithCheckbox
          key={item.title}
          filterType={item.renderBody}
          selectedVal={filtersForBatteries?.[item.filters]}
          inputList={filterSwitch(item.renderBody)}
          onChange={(e) => functionSwitch(e, item.title)}
        >
          {filtersForBatteries[item.filters]?.length === 0 ? (
            <Button size={item.size} colors={item.color} padding={item.padding}>
              <div className="flex items-center justify-start gap-x-3">
                <div>{item.title}</div>
                <img src="/assets/icons/chevron-down.svg" alt="" />
              </div>
            </Button>
          ) : (
            <Button padding="filter" size="filter" width="dynamic">
              <div className="flex item-center gap-x-3">
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {item.title}
                </div>
                <div className="w-4.5 h-4.5 bg-white text-black font-aileron font-semibold text-10 leading-3.75 rounded-full flex items-center justify-center">
                  {filtersForBatteries[item.filters]?.length}
                </div>
                <div>
                  <ChevronIcon className="w-4 h-4 -rotate-90 stroke-white" />
                </div>
              </div>
            </Button>
          )}
        </CustomSelectWithCheckbox>
      ))}
    </div>
  );
};

export { MoreBatteryFilters, BatteryFilters };
