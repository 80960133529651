import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../../Core/Components/Modal/CustomModal';
import CloseIcon from '../../../Common/Svgs/CloseIcon';
import Button from '../../../Core/Components/CustomButton';
import AlertIcon from '../../../Common/Svgs/AlertIcon';
import { updateTrips } from '../../../Redux/Slices/trips.slice';
import { getDistance, optimiseRoute } from '../../../Redux/APIs/tripsAPI';

const TripStep1Modal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { stops, currentStep, missingActivityModal } = useSelector(
    (state) => state.trips,
  );

  const findMissingActivitiesForCargos = () => {
    const uniqueCargos = [
      ...new Set(
        stops.flatMap((stop) => stop.activities.map((act) => act.cargo)),
      ),
    ];

    const missingActivities = uniqueCargos.reduce((result, cargo) => {
      const collectionExists = stops.some((stop) =>
        stop.activities.some(
          (act) =>
            (act.activity === 'collection' || act.activity === 'aggregation') &&
            act.cargo === cargo,
        ),
      );

      const deliveryExists = stops.some((stop) =>
        stop.activities.some(
          (act) => act.activity === 'delivery' && act.cargo === cargo,
        ),
      );

      if (!collectionExists) {
        result.push({ cargo, activity: 'collection' });
      }

      if (!deliveryExists) {
        result.push({ cargo, activity: 'delivery' });
      }

      return result;
    }, []);

    return missingActivities;
  };

  const handleOptimiseRoute = () => {
    onCancel();
    const missingActivities = findMissingActivitiesForCargos();

    if (missingActivities.length > 0) {
      dispatch(
        updateTrips({
          key: 'missingActivityModal',
          value: { ...missingActivityModal, visible: true, missingActivities },
        }),
      );
    } else {
      dispatch(updateTrips({ key: 'optimisingRouteModal', value: true }));
      try {
        dispatch(optimiseRoute({ stops })).then(() => {
          dispatch(updateTrips({ key: 'optimisingRouteModal', value: false }));
          dispatch(updateTrips({ key: 'isRouteOptimised', value: true }));
        });
      } catch (err) {
        dispatch(updateTrips({ key: 'optimisingRouteModal', value: false }));
        console.log(err);
      }
    }
  };

  // const handleOptimiseRoute = async () => {
  //   await dispatch(optimiseRoute({ stops }));
  //   onCancel();
  // };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-warning-10">
            <AlertIcon size={24} className="text-warning" />
          </div>
          <button className="p-2" type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            Optimise route
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Optimising route will save on battery and the time taken to deliver
            the Cargo.
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to move to the next step?
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button
            colors="cancelButton"
            size="primary"
            onClick={async () => {
              await dispatch(getDistance({ stops }));
              onCancel();
              dispatch(
                updateTrips({ key: 'currentStep', value: currentStep + 1 }),
              );
            }}
          >
            Skip and Proceed
          </Button>
          <Button
            colors="primary"
            size="primary"
            onClick={() => handleOptimiseRoute()}
          >
            Optimise Route
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default TripStep1Modal;
