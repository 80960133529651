/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { LoadingOutlined } from "@ant-design/icons";
import CustomModal from "./CustomModal";
import { QR_CODE_TYPE } from "../../../Modules/QRCode/data";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import BatteryIcon from "../../../Common/Svgs/BatteryIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../CustomButton";
import CustomTooltip from "../CustomTooltip";
import { TickCircleIcon } from "../../../Common/Svgs/TickIcon";
import DuplicateIcon from "../../../Common/Svgs/DuplicateIcon";

const ASSET = ["TRIKE", "BATTERY", "FARMER"];

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { viewModal } = useSelector((state) => state.qrCode);

  return (
    <div className="h-[100vh] flex items-center justify-center" ref={ref}>
      <div>
        <div className="flex items-center justify-center gap-x-1">
          <p className="font-poppins text-base font-semibold text-center">
            {ASSET[viewModal?.data?.assetType - 1]} :
          </p>
          <p className="font-poppins text-base font-semibold text-center">
            {viewModal?.data?.assetId || "--"}
          </p>
        </div>
        <QRCode
          className="mt-5"
          value={JSON.stringify(viewModal.data)}
          size={270}
          bgColor="#fff"
          title={viewModal?.data?.assetId}
        />
      </div>
    </div>
  );
});

const QRCodeModal = ({ visible = false, onCancel, type }) => {
  const { viewModal } = useSelector((state) => state.qrCode);

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const contentToPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    // onBeforePrint: () => console.log('before printing...'),
    // onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
  });

  const renderIcon = () => {
    switch (type) {
      case QR_CODE_TYPE.TRIKE:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <TrikeIcon size={24} className="text-white" />
          </div>
        );
      case QR_CODE_TYPE.BATTERY:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <BatteryIcon size={24} className="stroke-white fill-none" />
          </div>
        );
      case QR_CODE_TYPE.FARMER:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <UserIcon size={24} className="stroke-white fill-none" />
          </div>
        );
      default:
        return null;
    }
  };

  const onConfirm = () => {
    handlePrint(null, () => contentToPrintRef.current);
  };

  const copyToClipboard = () => {
    setLoading(true);
    navigator.clipboard.writeText(JSON.stringify(viewModal.data));

    setTimeout(() => {
      setLoading(false);
      setCopied(true);
    }, 500);
  };

  useEffect(() => {
    let timeout;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="586px">
      <div
        className=""
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between pl-4 pt-4 pb-6 pr-6">
          <div className="flex items-center gap-x-3">
            {renderIcon()}
            <p className="font-poppins text-base font-medium">
              {viewModal?.data?.assetId || "--"}
            </p>
          </div>
          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="bg-light-grey h-[384px] flex items-center justify-center">
          <div style={{ display: "none" }}>
            <ComponentToPrint ref={contentToPrintRef} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <QRCode
              className=""
              value={JSON.stringify(viewModal.data)}
              size={270}
              bgColor="#DFDFDF"
              title={viewModal?.data?.assetId}
            />

            <div className="mt-4 flex items-center">
              <p className="font-poppins font-medium text-sm text-left mr-2">
                QR TEXT - {JSON.stringify(viewModal.data).substring(0, 60)}...
              </p>

              <CustomTooltip
                tooltipTitle={copied ? "Text copied" : "Copy text"}
                placement="top"
              >
                <span role="button" onClick={copyToClipboard}>
                  {loading ? (
                    <Spin
                      spinning={loading}
                      indicator={<LoadingOutlined size={24} color="#000" />}
                    />
                  ) : copied ? (
                    <TickCircleIcon size={24} />
                  ) : (
                    <DuplicateIcon
                      size={24}
                      className="text-dark-gray group-hover:text-black"
                    />
                  )}
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>

        <div className="p-6 flex items-center gap-x-3 justify-end">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Close
          </Button>

          <Button onClick={onConfirm}>Print Code</Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default QRCodeModal;
